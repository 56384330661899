import { appAplicacionMotorConfig, appCanalMotorConfig, appConfigKey, appDefaultValues } from "../../config/Config";
import MotorTipoVehiculoConstant from "../constant/MotorTipoVehiculoConstant";
import { SolicitudPersonaPorValidarType, TMnSolicitudPersonaPorValidarRequest } from "../models/MasterManager/solicitud-persona-observada-request.model";
import { GetCotizacionSimulacionRequest } from "../models/ReportManager/cotizacion-simulacion-request.model";
import { MnFormatoRegimenUpdateRequest } from "../models/RequestManager/solicitud-formato-regimen-request.model";
import { ValidacionSolicitudObservada } from "../modules/3.-solicitude/3.10.-base/interfaces/solicitudObservadaInterface";
import { ValidationMemberStepOneDataInteface } from "../modules/3.-solicitude/3.10.-base/interfaces/validationMember";
import GeneralHelper from "./GeneralHelper";

const JsonHelper = {

  crearJsonRequestSimularOfertasVehicular: (
    puntoVentaUId: number,
    vendedorUId: number,
    partnerUId: number,
    codCampania: number,
    precio: number,
    proveedorGps: number,
    monedaPrecio: number,
    segmentoUso: number,
    versionUId: number,
    tipoCambio: number,
    aplicaBonoA: any,
    estadoId: number,
    entregaInicial: number,
    productoUId: number,
    diaPago: number,
    SdtsBTComisionPrestamoCouta: any[],
    SdtsBTComisionPrestamo: any[],
    cuotas: any[],
    esCuotaDoble: boolean,
    ballon: number,
    montoOferta: number,
    tasa: number,
    tempSeguros: any[]
  ) => {

    let SdtsBTCuotaExtraordinaria: any = [];

    if (esCuotaDoble) {
      SdtsBTCuotaExtraordinaria = [
        {
          "mes": 1,
          "porcentajeCuota": 200
        },
        {
          "mes": 8,
          "porcentajeCuota": 200
        }
      ]
    }

    return {
      "sdtPartner": {
        "puntoVentaUId": puntoVentaUId,
        "vendedorUId": vendedorUId,
        "partnerUId": partnerUId
      },
      "sdtDatosVehiculo": {
        "codCampania": codCampania,
        "precio": precio,
        "proveedorGps": 0,
        "monedaPrecio": monedaPrecio,
        "datosAdicionales": {
          "SdtsBTConcepto": []
        },
        "segmentoUso": segmentoUso,
        "versionUId": versionUId,
        "tipoCambio": tipoCambio,
        "aplicaBonoA": aplicaBonoA, // PENDIENTE CAMPO FRONT
        "kitMantenimiento": 0, // SEGUNDA FASE
        "estadoId": estadoId,
        "entregaInicial": entregaInicial,
        "tipoGps": proveedorGps
      },
      "sdtDatosOferta": {
        "aplicaSobre": "A", //se espera el valor ‘A’ (Capital e Interes). Valores posibles adicionales K o I (capital o interés)
        "cantCuoGraciaPriPag": 0, // SEGUNDA FASE
        "productoUId": productoUId,
        "comisionesCuota": {
          "SdtsBTComisionPrestamo": SdtsBTComisionPrestamoCouta
        },
        "monto": montoOferta,
        "periodoCuotas": appConfigKey.keyPeriodoCuotas, // SEGUNDA FASE
        "diaPago": diaPago,
        "ballon": ballon,
        "tasa": Number(tasa),
        "periodosGracia": 0, // SEGUNDA FASE
        "cuotas": {
          "Int": cuotas // ENVIAR PLAZO DE MESES SELECCIONADO
        },
        "fechaPrimerPago": "", // SEGUNDA FASE
        "cuotasExtraordinarias": {
          "SdtsBTCuotaExtraordinaria": SdtsBTCuotaExtraordinaria // SI EL INDICADOR DE CUOTAS DOBLES ESTA ACTIVO
        },
        "seguros": {
          "SdtsBTSeguroPrestamo": tempSeguros
        },
        "comisiones": {
          "SdtsBTComisionPrestamo": SdtsBTComisionPrestamo
        }
      }
    }
  },

  crearJsonRequestObtenerDetalleOferta: (puntoVentaUId: number,
    vendedorUId: number,
    partnerUId: number,
    cantidadCuotas: number,
    destinoCredito: number) => {
    return {
      "sdtPartner": {
        "puntoVentaUId": puntoVentaUId,
        "vendedorUId": vendedorUId,
        "partnerUId": partnerUId
      },
      "cantidadCuotas": cantidadCuotas,
      "destinoCredito": destinoCredito
    }
  },

  crearJsonRequestObtenerProductos: (puntoVentaUId: number,
    vendedorUId: number,
    partnerUId: number,
    monedaPrecio: number,
    estadoId: number,
    versionUId: number,
    precio: number) => {
    return {
      "sdtPartner": {
        "puntoVentaUId": puntoVentaUId,
        "vendedorUId": vendedorUId,
        "partnerUId": partnerUId
      },
      "sdtVehiculo": {
        "monedaPrecio": monedaPrecio,
        "estadoId": estadoId,
        "datosAdicionales": {
          "sdtsBTConcepto": [
            {
              "texto": "",
              "valor": 0,
              "concepto": ""
            }
          ]
        },
        "versionUId": versionUId,
        "precio": precio
      }
    }


  },

  crearJsonRequestObtenerCampanias: (
    puntoVentaUId: number,
    vendedorUId: number,
    partnerUId: number,
    estadoVehiculo: number,
    montoVehiculoDolares: number,
    entregaInicial: number,
    fechActual: string,
    seguroObligatorio: string) => {

    return {
      "sdtPartner": {
        "puntoVentaUId": puntoVentaUId,
        "vendedorUId": vendedorUId,
        "partnerUId": partnerUId
      },
      "estadoVehiculo": estadoVehiculo,
      "capital": montoVehiculoDolares,
      "entregaInicial": entregaInicial,
      "fecha": fechActual,
      "plazo": 365,
      "seguroObligatorio": seguroObligatorio,
      "canal": 0,
      "parametrosEntrada": {
        "parametroEntrada": [
          {
            "Nombre": "",
            "Valor": ""
          }
        ]
      }
    }
  },
  /* GenerateActualizacionSolicitud - INICIO */

  crearJsonMnPersona: () => {
    return {
      "idenT_PERSONA": appDefaultValues.NumberDefault,
      "iD_TIPO_PERSONA": appDefaultValues.NumberDefault,
      "descC_TIPO_PERSONA": appDefaultValues.StringEmpty,
      "iD_TIPO_DOCUMENTO": appDefaultValues.NumberDefault,
      "descC_TIPO_DOCUMENTO": appDefaultValues.StringEmpty,
      "codL_NUMERO_DOCUMENTO": appDefaultValues.StringEmpty,
      "descL_NOMBRE_COMPLETO": appDefaultValues.StringEmpty,
      "descC_CELULAR": appDefaultValues.StringEmpty,
      "descL_EMAIL": appDefaultValues.StringEmpty,
      "fecH_NACIMIENTO_CONSTITUCION": appDefaultValues.FechaDefault,
      "iD_RANGO_VENTA_ANUAL": appDefaultValues.NumberDefault,
      "descC_RANGO_VENTA_ANUAL": appDefaultValues.StringEmpty,
      "iD_RANGO_VENTA_ANUAL_ANTERIOR": appDefaultValues.NumberDefault,
      "descC_RANGO_VENTA_ANUAL_ANTERIOR": appDefaultValues.StringEmpty,
      "descL_APELLIDO_PATERNO": appDefaultValues.StringEmpty,
      "descL_APELLIDO_MATERNO": appDefaultValues.StringEmpty,
      "descL_NOMBRES": appDefaultValues.StringEmpty,
      "iD_GENERO": appDefaultValues.StringEmpty,
      "descC_GENERO": appDefaultValues.StringEmpty,
      "iD_MAGNITUD": appDefaultValues.NumberDefault,
      "descC_MAGNITUD": appDefaultValues.StringEmpty,
      "iD_TIPO_DOCUMENTO_COMPLEMENTARIO": appDefaultValues.NumberDefault,
      "descC_TIPO_DOCUMENTO_COMPLEMENTARIO": appDefaultValues.StringEmpty,
      "codL_NUMERO_DOCUMENTO_COMPLEMENTARIO": appDefaultValues.StringEmpty,
      "iD_ACTIVIDAD_ECONOMICA": appDefaultValues.NumberDefault,
      "descC_ACTIVIDAD_ECONOMICA": appDefaultValues.StringEmpty,
      "flaG_PEP": appDefaultValues.BooleanDefault,
      "iD_CARGO_PEP": appDefaultValues.NumberDefault,//pendiente
      "descC_CARGO_PEP": appDefaultValues.StringEmpty,//pendiente
      "cargO_PEP_OTROS": appDefaultValues.StringEmpty,//pendiente
      "iD_NACIONALIDAD": appDefaultValues.NumberDefault,
      "descC_NACIONALIDAD": appDefaultValues.StringEmpty,
      "iD_NIVEL_ESTUDIOS": appDefaultValues.NumberDefault,
      "descC_NIVEL_ESTUDIOS": appDefaultValues.StringEmpty,
      "monT_NUMERO_HIJOS": appDefaultValues.NumberDefault,
      "monT_NUMERO_DEPENDIENTES": appDefaultValues.NumberDefault,
      "inD_SEPARACION_BIENES": appDefaultValues.BooleanDefault,
      "iD_PROFESION": appDefaultValues.NumberDefault,
      "descC_PROFESION": appDefaultValues.StringEmpty,
      "descL_NOMBRE_COMPLETO_CONTACTO1": appDefaultValues.StringEmpty,
      "descL_NOMBRE_COMPLETO_CONTACTO2": appDefaultValues.StringEmpty,
      "descC_NUMERO_CONTACTO1": appDefaultValues.StringEmpty,
      "descC_NUMERO_CONTACTO2": appDefaultValues.StringEmpty,
      "descM_NOMBRE_VIA": appDefaultValues.StringEmpty,
      "descC_NUMERO": appDefaultValues.StringEmpty,
      "descC_INTERIOR_DEPARTAMENTO_PISO": appDefaultValues.StringEmpty,
      "descC_MANZANA": appDefaultValues.StringEmpty,
      "descC_LOTE": appDefaultValues.StringEmpty,
      "descM_NOMBRE_AGRUPACION": appDefaultValues.StringEmpty,
      "descL_REFERENCIA": appDefaultValues.StringEmpty,
      "iD_TIPO_VIVIENDA": appDefaultValues.NumberDefault,
      "descC_TIPO_VIVIENDA": appDefaultValues.StringEmpty,
      "iD_TIEMPO_RESIDENCIA": appDefaultValues.NumberDefault,
      "descC_TIEMPO_RESIDENCIA": appDefaultValues.StringEmpty,
      "idenT_CARGO_LABORAL": appDefaultValues.NumberDefault,
      "descL_DESCRIPCION_CARGO": appDefaultValues.StringEmpty,//pendiente
      "iD_ESTADO_CIVIL": appDefaultValues.NumberDefault,
      "descC_ESTADO_CIVIL": appDefaultValues.StringEmpty,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,//pendiente
      "descC_DOMICILIO": appDefaultValues.StringEmpty,
      "iD_PAIS_RESIDENCIA": appDefaultValues.NumberDefault,//pendiente
      "descL_PAIS_RESIDENCIA": appDefaultValues.StringEmpty,//pendiente
      //nuevo
      "clientuid": appDefaultValues.NumberDefault,
      "personauid": appDefaultValues.NumberDefault,
      "fecH_INICIO_ACTIVIDAD": appDefaultValues.NullDefault,
      "iD_GIRO_NEGOCIO": appDefaultValues.NumberDefault,
      "descC_GIRO_NEGOCIO": appDefaultValues.StringEmpty

    }
  },

  crearJsonMnPersonaEmpresaAdicional: () => {
    return {
      "idenT_PERSONA": appDefaultValues.NumberDefault,
      "codL_CODIGO_SBS": appDefaultValues.StringEmpty,
      "monT_CAPITAL_SOCIAL_ACTUAL": appDefaultValues.NumberDefault,
      "monT_PATRIMONIO": appDefaultValues.NumberDefault,
      "monT_PATRIMONIO_PERSONA": appDefaultValues.NumberDefault,
      "descC_SECTOR_ECONOMICO": appDefaultValues.StringEmpty,
      "descC_TIPO_CADENA_PRODUCTIVA": appDefaultValues.StringEmpty,
      "descC_ACTIVIDAD_COMPLEMENTARIA": appDefaultValues.StringEmpty
    }
  },

  crearJsonMnIngresoPersonaInsertUpdate: () => {
    return {
      "idenT_PERSONA_INGRESOS": appDefaultValues.NumberDefault,
      "idenT_PERSONA": appDefaultValues.NumberDefault,
      "idenT_EMPRESA": appDefaultValues.NumberDefault,
      "iD_CATEGORIA_LABORAL": appDefaultValues.NumberDefault,
      "descC_CATEGORIA_LABORAL": appDefaultValues.StringEmpty,
      "inD_ANUALIZADO": appDefaultValues.BooleanDefault,
      "fecH_INGRESO_EMPRESA": appDefaultValues.FechaDefault,
      "iD_TIPO_CALCULO_NETO_BRUTO": appDefaultValues.NumberDefault,
      "descC_TIPO_CALCULO_NETO_BRUTO": appDefaultValues.StringEmpty,
      "iD_TIPO_INGRESO_FIJO_VARIABLE": appDefaultValues.NumberDefault,
      "descC_TIPO_INGRESO_FIJO_VARIABLE": appDefaultValues.StringEmpty,
      "iD_TIPO_MONEDA_INGRESO": appDefaultValues.NumberDefault,
      "descC_TIPO_MONEDA_INGRESO": appDefaultValues.StringEmpty,
      "monT_INGRESO_NETO": appDefaultValues.NumberDefault,
      "monT_INGRESO_BRUTO": appDefaultValues.NumberDefault,
      "inD_CONTRATO_INDETERMINADO": appDefaultValues.BooleanDefault,
      "descL_DESCRIPCION_INGRESO": appDefaultValues.StringEmpty,
      "inD_INGRESO_PRINCIPAL": appDefaultValues.BooleanDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "inD_TRABAJO_ANTERIOR": appDefaultValues.BooleanDefault,
      "fecH_FIN_TRABAJO_ANTERIOR": appDefaultValues.FechaDefault,
      "iD_REGIMEN": appDefaultValues.NumberDefault,
      "descC_REGIMEN": appDefaultValues.StringEmpty,
      "iD_ACTIVIDAD_ECONOMICA": appDefaultValues.NumberDefault,
      "descC_ACTIVIDAD_ECONOMICA": appDefaultValues.StringEmpty
    }
  },

  crearJsonMnEmpresaEmpleadorInsertUpdate: () => {
    return {
      "idenT_EMPRESA": appDefaultValues.NumberDefault,
      "descL_RAZON_SOCIAL_EMPRESA": appDefaultValues.StringEmpty,
      "iD_TIPO_DOC": appDefaultValues.NumberDefault,
      "descC_TIPO_DOC": appDefaultValues.StringEmpty,
      "descL_NRO_DOC": appDefaultValues.StringEmpty,
      "iD_ACTIVIDAD_ECONOMICA": appDefaultValues.NumberDefault,
      "descC_ACTIVIDAD_ECONOMICA": appDefaultValues.StringEmpty,
      "descM_NOMBRE_VIA": appDefaultValues.StringEmpty,
      "descC_NUMERO": appDefaultValues.StringEmpty,
      "descC_INTERIOR_DEPARTAMENTO_PISO": appDefaultValues.StringEmpty,
      "descC_MANZANA": appDefaultValues.StringEmpty,
      "descC_LOTE": appDefaultValues.StringEmpty,
      "descM_NOMBRE_AGRUPACION": appDefaultValues.StringEmpty,
      "idenT_PAIS": appDefaultValues.NumberDefault,
      "idenT_UBIGEO": appDefaultValues.NumberDefault,
      "descL_REFERENCIA": appDefaultValues.StringEmpty,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "descC_TELEFONO": appDefaultValues.StringEmpty,
      "descC_ANEXOS": appDefaultValues.StringEmpty,
      "descC_CORREO": appDefaultValues.StringEmpty
    }
  },

  crearJsonMnSolicitudGastosInsert: () => {
    return {
      "idenT_SOLICITUD_GASTOS": appDefaultValues.NumberDefault,
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "iD_TIPO_GASTO": appDefaultValues.NumberDefault,
      "descC_TIPO_GASTO": appDefaultValues.StringEmpty,
      "iD_TIPO_MONEDA_MONT_GASTO": appDefaultValues.NumberDefault,
      "descC_TIPO_MONEDA_MONT_GASTO": appDefaultValues.StringEmpty,
      "monT_GASTO": appDefaultValues.NumberDefault,
      "idenT_PROVEEDOR": appDefaultValues.NumberDefault,
      "descC_PROVEEDOR": appDefaultValues.StringEmpty,
      "inD_SELECCION": appDefaultValues.BooleanDefault,
      "monT_GASTO_ORIGEN": appDefaultValues.NumberDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault
    }

  },

  crearJsonMnSolicitudInsertUpdateResquest: () => {

    return {
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "idenT_PERSONA_TITULAR": appDefaultValues.NumberDefault,
      "idenT_PERSONA_CONYUGUE": appDefaultValues.NumberDefault,
      "descL_OBSERVACION": appDefaultValues.StringEmpty,
      "iD_ESTADO_VEHICULO_FINANCIAR": appDefaultValues.NumberDefault,
      "descC_ESTADO_VEHICULO_FINANCIAR": appDefaultValues.StringEmpty,
      "idenT_CONCESIONARIO": appDefaultValues.NumberDefault,
      "descC_CONCESIONARIO": appDefaultValues.StringEmpty,
      "idenT_SUCURSAL": appDefaultValues.NumberDefault,
      "descC_SUCURSAL": appDefaultValues.StringEmpty,
      "idenT_VENDEDOR": appDefaultValues.NumberDefault,
      "descC_VENDEDOR": appDefaultValues.StringEmpty,
      "descL_EMAIL_VENDEDOR": appDefaultValues.StringEmpty,
      "descL_EMAIL_ANALISTA": appDefaultValues.StringEmpty,
      "monT_TIPO_CAMBIO": appDefaultValues.NumberDefault,
      "iD_TIPO_MONEDA_FINANCIAMIENTO": appDefaultValues.NumberDefault,
      "descC_TIPO_MONEDA_FINANCIAMIENTO": appDefaultValues.StringEmpty,
      "iD_TIPO_MONEDA_PRECIO_VEHICULO": appDefaultValues.NumberDefault,
      "descC_TIPO_MONEDA_PRECIO_VEHICULO": appDefaultValues.StringEmpty,
      "monT_PRECIO_VEHICULO": appDefaultValues.NumberDefault,
      "iD_TIPO_MONEDA_CUOTA_INICIAL": appDefaultValues.NumberDefault,
      "descC_TIPO_MONEDA_CUOTA_INICIAL": appDefaultValues.StringEmpty,
      "monT_CUOTA_INICIAL": appDefaultValues.NumberDefault,
      "iD_USO_VEHICULO": appDefaultValues.NumberDefault,
      "descC_USO_VEHICULO": appDefaultValues.StringEmpty,
      "idenT_MARCA": appDefaultValues.NumberDefault,
      "descC_MARCA": appDefaultValues.StringEmpty,
      "idenT_MODELO": appDefaultValues.NumberDefault,
      "descC_MODELO": appDefaultValues.StringEmpty,
      "idenT_VERSION": appDefaultValues.NumberDefault,
      "descC_VERSION": appDefaultValues.StringEmpty,
      "idenT_PROVEEDOR_GPS": appDefaultValues.NumberDefault,
      "descC_PROVEEDOR_GPS": appDefaultValues.StringEmpty,
      "idenT_CAMPANA": appDefaultValues.NumberDefault,
      "descC_CAMPANA": appDefaultValues.StringEmpty,
      "monT_CALC_GASTOS": appDefaultValues.NumberDefault,
      "monT_CALC_FINANCIAMIENTO": appDefaultValues.NumberDefault,
      "iD_ESTADO_CAMPANA": appDefaultValues.NumberDefault,
      "descC_ESTADO_CAMPANA": appDefaultValues.StringEmpty,
      "idenT_PRODUCTO": appDefaultValues.NumberDefault,
      "descC_PRODUCTO": appDefaultValues.StringEmpty,
      "idenT_SEGURO_VEHICULAR": appDefaultValues.NumberDefault,
      "descC_SEGURO_VEHICULAR": appDefaultValues.StringEmpty,
      "monT_PORCENTAJE_SEGURO_VEHICULAR": appDefaultValues.NumberDefault,
      "inD_PROCESO_DERECHO_ENDOSO": appDefaultValues.BooleanDefault,
      "monT_TEA": appDefaultValues.NumberDefault,
      "indeT_PLAZO": appDefaultValues.NumberDefault,
      "descC_DIA_PAGO": appDefaultValues.StringEmpty,
      "inD_CUOTAS_DOBLES": appDefaultValues.BooleanDefault,
      "fecH_PRIMER_VENCIMIENTO": appDefaultValues.FechaDefault,
      "iD_COBERTURA_SEGURO_DESGRAVAMENT": appDefaultValues.NumberDefault,
      "descC_COBERTURA_SEGURO_DESGRAVAMENT": appDefaultValues.StringEmpty,
      "iD_ESTADO_SOLICITUD": appDefaultValues.NumberDefault,
      "descC_ID_ESTADO_SOLICITUD": appDefaultValues.StringEmpty,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "idenT_OPERACION": appDefaultValues.NumberDefault,
      "idenT_PERSONA_COMPRA_PARA": appDefaultValues.NumberDefault,
      "idenT_PERSONA_COMPRA_PARA_CONYUGUE": appDefaultValues.NumberDefault,
      "idenT_CAMPANIA_CI_PRECIO_TEA": appDefaultValues.NumberDefault,
      "idenT_ANIO_VEHICULO": appDefaultValues.NumberDefault,
      "idenT_DESTINO_CREDITO": appDefaultValues.NumberDefault,
      "descC_DESTINO_CREDITO": appDefaultValues.StringEmpty,
      "tcea": appDefaultValues.NumberDefault,
      "cuotA_BALLON": appDefaultValues.NumberDefault,
      "derechO_ENDOSO": appDefaultValues.BooleanDefault,
      "inD_BONO": appDefaultValues.BooleanDefault,
      "iD_ESTADO_RECONFIRMACION": appDefaultValues.NumberDefault,
      "descL_COMENTARIO_RECONFIRMACION": appDefaultValues.StringEmpty,
    }
  },

  crearJsonMnSolicitudCronograma: () => {
    return {
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "canT_NUMERO_CUOTA": appDefaultValues.NumberDefault,
      "fecH_INICIO": appDefaultValues.FechaDefault,
      "fecH_VENCIMIENTO": appDefaultValues.FechaDefault,
      "idenT_CANT_DIAS": appDefaultValues.NumberDefault,
      "monT_SALDO_CAPITAL_INICIAL": appDefaultValues.NumberDefault,
      "monT_SALDO_TOTAL_INICIAL": appDefaultValues.NumberDefault,
      "monT_TOTAL_AMORTIZACION": appDefaultValues.NumberDefault,
      "monT_AMORTIZACION": appDefaultValues.NumberDefault,
      "monT_SEGURO": appDefaultValues.NumberDefault,
      "monT_INTERES_CALCULADO": appDefaultValues.NumberDefault,
      "monT_INTERES": appDefaultValues.NumberDefault,
      "monT_PORTES": appDefaultValues.NumberDefault,
      "monT_SEGURO_DESGRAVAMEN": appDefaultValues.NumberDefault,
      "monT_TOTAL_CUOTA": appDefaultValues.NumberDefault,
      "monT_INTERES_DEVENGO": appDefaultValues.NumberDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault
    }
  },

  crearJsonRequestValidarListasInhabilitados: () => {
    return {
      "pais": appDefaultValues.NumberDefault,
      "tipoDocumento": appDefaultValues.NumberDefault,
      "numeroDocumento": appDefaultValues.StringEmpty,
      "tipoPersona": appDefaultValues.StringEmpty,
      "primerNombre": appDefaultValues.StringEmpty,
      "segundoNombre": appDefaultValues.StringEmpty,
      "primerApellido": appDefaultValues.StringEmpty,
      "segundoApellido": appDefaultValues.StringEmpty,
      "razonSocial": appDefaultValues.StringEmpty,
    }

  },

  crearJsonRequestGesintelObtenerAMLResult: () => {
    return {
      "rut": appDefaultValues.StringEmpty,
      "name": appDefaultValues.StringEmpty,
      "typeSearch": appDefaultValues.StringEmpty,
    }
  },

  crearJsonMnSolicitudPrimerPasoRequest: () => {
    return {
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "idenT_PERSONA_TITULAR": appDefaultValues.NumberDefault,
      "idenT_PERSONA_CONYUGUE": appDefaultValues.NumberDefault,
      "descL_OBSERVACION": appDefaultValues.StringEmpty,
      "iD_ESTADO_SOLICITUD": appDefaultValues.NumberDefault,
      "descC_ID_ESTADO_SOLICITUD": appDefaultValues.StringEmpty,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "idenT_PERSONA_COMPRA_PARA": appDefaultValues.NumberDefault,
      "idenT_PERSONA_COMPRA_PARA_CONYUGUE": appDefaultValues.NumberDefault,
      "idenT_USUARIO_PLAFT": appDefaultValues.NumberDefault,
      "idenT_SITUACION_PLAFT": appDefaultValues.NumberDefault,
      "idenT_USUARIO_RIESGOS": appDefaultValues.NumberDefault,
      "idenT_SITUACION_RIESGOS": appDefaultValues.NumberDefault
    }
  },

  crearJsonMnObservacionPersonaRequest: () => {
    return {
      "idenT_SOLICITUD_PERSONA_OBSERVADA": appDefaultValues.NumberDefault,
      "idenT_PERSONA": appDefaultValues.NumberDefault,
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "idenT_TIPO_RELACION": appDefaultValues.NumberDefault,
      "obS_PLAFT": appDefaultValues.BooleanDefault,
      "obS_RIESGOS": appDefaultValues.BooleanDefault,
      "listA_NEGRA": appDefaultValues.StringEmpty,
      "gesintel": appDefaultValues.StringEmpty,
      "listA_NEGRA_DELTA": appDefaultValues.NullDefault,
      "gesinteL_DELTA": appDefaultValues.NullDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "nrO_PASADA": appDefaultValues.NumberDefault
    }
  },

  crearJsonMnObservacionAsociadoRequest: () => {
    return {
      "idenT_SOLICITUD_ASOCIADO_OBSERVADA": appDefaultValues.NumberDefault,
      "idenT_SOLICITUD_ASOCIADO": appDefaultValues.NumberDefault,
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "iD_TIPO_DOCUMENTO": appDefaultValues.NumberDefault,
      "codL_NUMERO_DOCUMENTO": appDefaultValues.StringEmpty,
      "idenT_TIPO_RELACION": appDefaultValues.NumberDefault,
      "obS_PLAFT": appDefaultValues.BooleanDefault,
      "obS_RIESGOS": appDefaultValues.BooleanDefault,
      "listA_NEGRA": appDefaultValues.StringEmpty,
      "gesintel": appDefaultValues.StringEmpty,
      "listA_NEGRA_DELTA": appDefaultValues.NullDefault,
      "gesinteL_DELTA": appDefaultValues.NullDefault,
      "inD_ESTADO": appDefaultValues.BooleanDefault,
      "idenT_USUARIO": appDefaultValues.NumberDefault,
      "nrO_PASADA": appDefaultValues.NumberDefault
    }
  },

  crearJsonRequestGenerateObservacionSolicitud: (
    solicitudData: any,
    executiveData: any,
    partnerData: any,
    newTitularData: any,
    newConyugeData: any,
    newIngresosTitularData: any,
    newIngresosConyugeData: any,
    newPersonaCompraParaData: any,
    newPersonaCompraParaConyugeData: any,
    newFiadorData: any,
    newObservacionData: any,
    entityBlackListGesintel: ValidacionSolicitudObservada,
    blackListGesintel: any,
    listasTemp: any,
    identUsuario: number,
    nroPasada: number,
    identEtapa: number,
  ) => {

    let mnSolicitudPrimerPasoRequest: any = null;
    let mnPersonaTitularRequest: any = [];
    let mnObservacionPersonaRequest = null;
    let mnPersonaRequest: any = null;
    let mnObservacionPersonaConyugueRequest: any = null;
    let mnPersonaConyugueRequest: any = null;
    let ingresosDetallesTitular: any = [];
    let ingresosDetallesConyugue: any = [];
    let mnIngresoPersonaInsertUpdateRequest: any = null;
    let mnEmpresaEmpleadorInsertUpdateRequest: any = null;
    let mnIngresoPersonaInsertUpdateRequest_conyuge: any = null;
    let mnEmpresaEmpleadorInsertUpdateRequest_conyuge: any = null;
    let mnObservacionPersonaRequest_Fiador: any = null;
    let mnPersonaRequest_Fiador: any = null;
    let mnObservacionPersonaConyugueRequest_Fiador: any = null;
    let mnPersonaConyugueRequest_Fiador: any = null;
    let mnPersonasFiadoresRequest: any = [];
    let mnPersonaCompraParaRequest: any = [];
    let mnPersonasObservadas: any = [];
    let mnAsociadosObservados: any = [];
    let mnPersonaRequest_CompraPara: any = null;
    let mnPersonaConyugueRequest_CompraPara: any = null;
    let mnObservacionPersonaRequest_CompraPara: any = null;
    let mnObservacionPersonaConyugueRequest_CompraPara: any = null;

    mnSolicitudPrimerPasoRequest = JsonHelper.crearJsonMnSolicitudPrimerPasoRequest();
    mnSolicitudPrimerPasoRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
    mnSolicitudPrimerPasoRequest.idenT_PERSONA_TITULAR = solicitudData.idenT_PERSONA_TITULAR;
    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
      mnSolicitudPrimerPasoRequest.idenT_PERSONA_CONYUGUE = solicitudData.idenT_PERSONA_CONYUGUE;
    }
    mnSolicitudPrimerPasoRequest.descL_OBSERVACION = newObservacionData.Observaciones;
    mnSolicitudPrimerPasoRequest.iD_ESTADO_SOLICITUD = solicitudData.iD_ESTADO_SOLICITUD;
    mnSolicitudPrimerPasoRequest.descC_ID_ESTADO_SOLICITUD = solicitudData.descC_ID_ESTADO_SOLICITUD;
    mnSolicitudPrimerPasoRequest.inD_ESTADO = true;
    mnSolicitudPrimerPasoRequest.idenT_USUARIO = identUsuario;
    mnSolicitudPrimerPasoRequest.idenT_PERSONA_COMPRA_PARA = GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '' ?
      solicitudData.idenT_PERSONA_COMPRA_PARA : 0;
    mnSolicitudPrimerPasoRequest.idenT_PERSONA_COMPRA_PARA_CONYUGUE = GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado &&
      GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '' ?
      solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE : 0;

    if (entityBlackListGesintel.observadoRiesgo) {
      mnSolicitudPrimerPasoRequest.idenT_USUARIO_RIESGOS = 0;
      mnSolicitudPrimerPasoRequest.idenT_SITUACION_RIESGOS = appConfigKey.CodigoSituacionPlaftRiesgosObservada;
    }
    if (entityBlackListGesintel.observadoPlaft) {
      mnSolicitudPrimerPasoRequest.idenT_USUARIO_PLAFT = 0;
      mnSolicitudPrimerPasoRequest.idenT_SITUACION_PLAFT = appConfigKey.CodigoSituacionPlaftRiesgosObservada;
    }

    if (blackListGesintel.filter((x: any) =>
      x.tipoDocumento === newTitularData.TipoDocumentoId &&
      x.numeroDocumento === newTitularData.NumeroDocumento).length > 0) {

      mnObservacionPersonaRequest = JsonHelper.crearJsonMnObservacionPersonaRequest();
      mnObservacionPersonaRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
      mnObservacionPersonaRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      mnObservacionPersonaRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionTitular;
      mnObservacionPersonaRequest.nrO_PASADA = nroPasada
      if (blackListGesintel.find((x: any) =>
        x.tipoDocumento === newTitularData.TipoDocumentoId &&
        x.numeroDocumento === newTitularData.NumeroDocumento)?.esObservadoPlaftYRiesgo) {
        mnObservacionPersonaRequest.obS_PLAFT = true;
        mnObservacionPersonaRequest.obS_RIESGOS = true;
      } else {
        mnObservacionPersonaRequest.obS_PLAFT = blackListGesintel.find((x: any) =>
          x.tipoDocumento === newTitularData.TipoDocumentoId &&
          x.numeroDocumento === newTitularData.NumeroDocumento)?.esObservadoPlaft;
        mnObservacionPersonaRequest.obS_RIESGOS = blackListGesintel.find((x: any) =>
          x.tipoDocumento === newTitularData.TipoDocumentoId &&
          x.numeroDocumento === newTitularData.NumeroDocumento)?.esObservadoRiesgos;
      }
      mnObservacionPersonaRequest.listA_NEGRA = blackListGesintel.find((x: any) =>
        x.tipoDocumento === newTitularData.TipoDocumentoId &&
        x.numeroDocumento === newTitularData.NumeroDocumento)?.bantotalJson;
      mnObservacionPersonaRequest.gesintel = blackListGesintel.find((x: any) =>
        x.tipoDocumento === newTitularData.TipoDocumentoId &&
        x.numeroDocumento === newTitularData.NumeroDocumento)?.gesintelJson;

      mnObservacionPersonaRequest.inD_ESTADO = true;
      mnObservacionPersonaRequest.idenT_USUARIO = identUsuario;
    }

    mnPersonaRequest = JsonHelper.crearJsonMnPersona();
    mnPersonaRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR ?? appDefaultValues.NumberDefault;
    mnPersonaRequest.iD_TIPO_PERSONA = newTitularData.TipoPersonaId;
    mnPersonaRequest.descC_TIPO_PERSONA = newTitularData.TipoPersonaId === appConfigKey.keyIdPersonaNatural ? appConfigKey.keyDescripPersonaNatural : appConfigKey.keyDescripPersonaJuridica;
    mnPersonaRequest.iD_TIPO_DOCUMENTO = newTitularData.TipoDocumentoId;
    mnPersonaRequest.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newTitularData.TipoDocumentoId)?.description);
    mnPersonaRequest.codL_NUMERO_DOCUMENTO = newTitularData.NumeroDocumento;
    mnPersonaRequest.descL_NOMBRE_COMPLETO = newTitularData.Nombres + " " +
      newTitularData.ApellidoPaterno + " " +
      newTitularData.ApellidoMaterno;
    mnPersonaRequest.descC_CELULAR = newTitularData.Celular;
    mnPersonaRequest.descL_EMAIL = newTitularData.Correo;
    mnPersonaRequest.fecH_NACIMIENTO_CONSTITUCION = newTitularData.FechaNacimiento;
    mnPersonaRequest.descL_APELLIDO_PATERNO = newTitularData.ApellidoPaterno;
    mnPersonaRequest.descL_APELLIDO_MATERNO = newTitularData.ApellidoMaterno;
    mnPersonaRequest.descL_NOMBRES = newTitularData.Nombres;
    mnPersonaRequest.iD_GENERO = GeneralHelper.ParseGeneroString(newTitularData?.GeneroId);
    mnPersonaRequest.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newTitularData.GeneroId)?.description);
    mnPersonaRequest.iD_MAGNITUD = newTitularData.MagnitudId;
    mnPersonaRequest.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newTitularData.MagnitudId)?.description);
    mnPersonaRequest.iD_TIPO_DOCUMENTO_COMPLEMENTARIO = newTitularData.DocumentoComplementarioId;
    mnPersonaRequest.descC_TIPO_DOCUMENTO_COMPLEMENTARIO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newTitularData.DocumentoComplementarioId)?.description);
    mnPersonaRequest.codL_NUMERO_DOCUMENTO_COMPLEMENTARIO = newTitularData.NumeroDocumentoComplementario;
    mnPersonaRequest.iD_ACTIVIDAD_ECONOMICA = newTitularData.ActividadEconomicaId;
    mnPersonaRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newTitularData.ActividadEconomicaId)?.description);
    mnPersonaRequest.flaG_PEP = newTitularData.ClientePep;
    mnPersonaRequest.iD_ESTADO_CIVIL = newTitularData.EstadoCivilId;
    mnPersonaRequest.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === newTitularData.EstadoCivilId)?.description);
    mnPersonaRequest.inD_ESTADO = true;
    mnPersonaRequest.descC_DOMICILIO = newTitularData.Domicilio;
    mnPersonaRequest.idenT_USUARIO = identUsuario;

    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {

      if (blackListGesintel.filter((x: any) =>
        x.tipoDocumento === newConyugeData.TipoDocumentoId &&
        x.numeroDocumento === newConyugeData.NumeroDocumento).length > 0) {

        mnObservacionPersonaConyugueRequest = JsonHelper.crearJsonMnObservacionPersonaRequest();
        mnObservacionPersonaConyugueRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
        mnObservacionPersonaConyugueRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
        mnObservacionPersonaConyugueRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionConyuge;
        mnObservacionPersonaConyugueRequest.nrO_PASADA = nroPasada
        if (blackListGesintel.find((x: any) =>
          x.tipoDocumento === newConyugeData.TipoDocumentoId &&
          x.numeroDocumento === newConyugeData.NumeroDocumento)?.esObservadoPlaftYRiesgo) {
          mnObservacionPersonaConyugueRequest.obS_PLAFT = true;
          mnObservacionPersonaConyugueRequest.obS_RIESGOS = true;
        } else {
          mnObservacionPersonaConyugueRequest.obS_PLAFT = blackListGesintel.find((x: any) =>
            x.tipoDocumento === newConyugeData.TipoDocumentoId &&
            x.numeroDocumento === newConyugeData.NumeroDocumento)?.esObservadoPlaft;
          mnObservacionPersonaConyugueRequest.obS_RIESGOS = blackListGesintel.find((x: any) =>
            x.tipoDocumento === newConyugeData.TipoDocumentoId &&
            x.numeroDocumento === newConyugeData.NumeroDocumento)?.esObservadoRiesgos;
        }
        mnObservacionPersonaConyugueRequest.listA_NEGRA = blackListGesintel.find((x: any) =>
          x.tipoDocumento === newConyugeData.TipoDocumentoId &&
          x.numeroDocumento === newConyugeData.NumeroDocumento)?.bantotalJson;
        mnObservacionPersonaConyugueRequest.gesintel = blackListGesintel.find((x: any) =>
          x.tipoDocumento === newConyugeData.TipoDocumentoId &&
          x.numeroDocumento === newConyugeData.NumeroDocumento)?.gesintelJson;

        mnObservacionPersonaConyugueRequest.inD_ESTADO = true;
        mnObservacionPersonaConyugueRequest.idenT_USUARIO = identUsuario;

      }

      mnPersonaConyugueRequest = JsonHelper.crearJsonMnPersona();
      mnPersonaConyugueRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE ?? appDefaultValues.NumberDefault;
      mnPersonaConyugueRequest.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
      mnPersonaConyugueRequest.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
      mnPersonaConyugueRequest.iD_TIPO_DOCUMENTO = newConyugeData.TipoDocumentoId;
      mnPersonaConyugueRequest.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newConyugeData.TipoDocumentoId)?.description);
      mnPersonaConyugueRequest.codL_NUMERO_DOCUMENTO = newConyugeData.NumeroDocumento;
      mnPersonaConyugueRequest.descL_NOMBRE_COMPLETO = newConyugeData.Nombres + " " +
        newConyugeData.ApellidoPaterno + " " +
        newConyugeData.ApellidoMaterno;
      mnPersonaConyugueRequest.descC_CELULAR = newConyugeData.Celular;
      mnPersonaConyugueRequest.descL_EMAIL = newConyugeData.Correo;
      mnPersonaConyugueRequest.fecH_NACIMIENTO_CONSTITUCION = newConyugeData.FechaNacimiento;
      mnPersonaConyugueRequest.descL_APELLIDO_PATERNO = newConyugeData.ApellidoPaterno;
      mnPersonaConyugueRequest.descL_APELLIDO_MATERNO = newConyugeData.ApellidoMaterno;
      mnPersonaConyugueRequest.descL_NOMBRES = newConyugeData.Nombres;
      mnPersonaConyugueRequest.iD_GENERO = GeneralHelper.ParseGeneroString(newConyugeData?.GeneroId);
      mnPersonaConyugueRequest.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newConyugeData.GeneroId)?.description);
      mnPersonaConyugueRequest.iD_MAGNITUD = newConyugeData.MagnitudId;
      mnPersonaConyugueRequest.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newConyugeData.MagnitudId)?.description);
      mnPersonaConyugueRequest.iD_ACTIVIDAD_ECONOMICA = newConyugeData.ActividadEconomicaId;
      mnPersonaConyugueRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newConyugeData.ActividadEconomicaId)?.description);
      mnPersonaConyugueRequest.flaG_PEP = newConyugeData.ClientePep;
      mnPersonaConyugueRequest.iD_ESTADO_CIVIL = appConfigKey.keyIdEstadoCivilCasado;
      mnPersonaConyugueRequest.descC_ESTADO_CIVIL = appConfigKey.keyDescripEstadoCivilCasado;
      mnPersonaConyugueRequest.inD_ESTADO = true;
      mnPersonaConyugueRequest.idenT_USUARIO = identUsuario;


    }

    mnPersonaTitularRequest.push({
      "mnObservacionPersonaRequest": mnObservacionPersonaRequest,
      "mnPersonaRequest": mnPersonaRequest,
      "mnObservacionPersonaConyugueRequest": mnObservacionPersonaConyugueRequest,
      "mnPersonaConyugueRequest": mnPersonaConyugueRequest
    });

    mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
    mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
    mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaId ?? appDefaultValues.NumberDefault;
    mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = newIngresosTitularData.identCategoriaLaboral;
    mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosTitularData.identCategoriaLaboral)?.description);
    mnIngresoPersonaInsertUpdateRequest.inD_ANUALIZADO = GeneralHelper.ParseBoolean(newIngresosTitularData.anualizado);
    mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaIngresoLaboral);
    mnIngresoPersonaInsertUpdateRequest.iD_TIPO_MONEDA_INGRESO = newIngresosTitularData.identTipoMoneda;
    mnIngresoPersonaInsertUpdateRequest.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newIngresosTitularData.identTipoMoneda)?.description);
    mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_NETO = GeneralHelper.ParseNumber(newIngresosTitularData.ingresosNetos);
    mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(newIngresosTitularData.anualizado) ? GeneralHelper.ParseNumber(newIngresosTitularData.calculoAnualizado) : 0;
    mnIngresoPersonaInsertUpdateRequest.inD_INGRESO_PRINCIPAL = true;
    mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
    mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenId);
    mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenId)?.description) : '';
    mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;

    mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
    mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaId ?? appDefaultValues.NumberDefault;
    mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = newIngresosTitularData.razonSocial;
    mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
    mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
    mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = newIngresosTitularData.ruc;
    mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
    mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;

    //Empresa titular observado
    let mnObservacionEmpresaRequest: any = null;
    let empresaObservedData = blackListGesintel.find((x: any) =>
      x.tipoDocumento === appConfigKey.keyIdRUC &&
      x.numeroDocumento === newIngresosTitularData.ruc)

    if (empresaObservedData) {

      mnObservacionEmpresaRequest = JsonHelper.crearJsonMnObservacionPersonaRequest();
      mnObservacionEmpresaRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      mnObservacionEmpresaRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionEmpresa;
      mnObservacionEmpresaRequest.nrO_PASADA = nroPasada

      if (empresaObservedData.esObservadoPlaftYRiesgo) {
        mnObservacionEmpresaRequest.obS_PLAFT = true;
        mnObservacionEmpresaRequest.obS_RIESGOS = true;
      } else {
        mnObservacionEmpresaRequest.obS_PLAFT = empresaObservedData.esObservadoPlaft;
        mnObservacionEmpresaRequest.obS_RIESGOS = empresaObservedData.esObservadoRiesgos;
      }
      mnObservacionEmpresaRequest.listA_NEGRA = empresaObservedData.bantotalJson;
      mnObservacionEmpresaRequest.gesintel = empresaObservedData.gesintelJson;

      mnObservacionEmpresaRequest.inD_ESTADO = true;
      mnObservacionEmpresaRequest.idenT_USUARIO = identUsuario;

    }
    //Empresa titular observado end

    ingresosDetallesTitular.push({
      "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
      "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest,
      "mnObservacionEmpresaRequest": mnObservacionEmpresaRequest
    })

    if (GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior)) {

      mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
      mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
      mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
      mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = newIngresosTitularData.identCategoriaLaboralAnterior;
      mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosTitularData.identCategoriaLaboralAnterior)?.description);
      mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaIngresoLaboralAnterior);
      mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
      mnIngresoPersonaInsertUpdateRequest.inD_TRABAJO_ANTERIOR = GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior);
      mnIngresoPersonaInsertUpdateRequest.fecH_FIN_TRABAJO_ANTERIOR = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaHastaAnterior);
      mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenIdAnterior);
      mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenIdAnterior) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenIdAnterior)?.description) : '';
      mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;

      mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
      mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
      mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = newIngresosTitularData.razonSocialAnterior;
      mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
      mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
      mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = newIngresosTitularData.rucAnterior;
      mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
      mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;


      ingresosDetallesTitular.push({
        "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
        "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest,
        "mnObservacionEmpresaRequest": null
      })
    }

    if (newIngresosTitularData.listaOtrosTrabajos.length > 0) {
      newIngresosTitularData.listaOtrosTrabajos.map((rowOtroTrabajo: any, index: any) => {

        mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
        mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
        mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = rowOtroTrabajo.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === rowOtroTrabajo.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest.inD_ANUALIZADO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado);
        mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', rowOtroTrabajo.fechaIngresoLaboral);
        mnIngresoPersonaInsertUpdateRequest.iD_TIPO_MONEDA_INGRESO = rowOtroTrabajo.identTipoMoneda;
        mnIngresoPersonaInsertUpdateRequest.descC_TIPO_MONEDA_INGRESO = listasTemp.listMoneda.find((x: any) => x.id === rowOtroTrabajo.identTipoMoneda).description;
        mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_NETO = GeneralHelper.ParseNumber(rowOtroTrabajo.ingresosNetos);
        mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado) ? GeneralHelper.ParseNumber(rowOtroTrabajo.calculoAnualizado) : 0;
        mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId);
        mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenId)?.description) : '';
        mnIngresoPersonaInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
        mnIngresoPersonaInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
        mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;

        mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
        mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = rowOtroTrabajo.razonSocial;
        mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
        mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = rowOtroTrabajo.ruc;
        mnEmpresaEmpleadorInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
        mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;

        //Otra Empresa titular observado
        let mnObservacionOtraEmpresaRequest: any = null;
        let empresaObservedData = blackListGesintel.find((x: any) =>
          x.tipoDocumento === appConfigKey.keyIdRUC &&
          x.numeroDocumento === rowOtroTrabajo.ruc)

        if (empresaObservedData) {

          mnObservacionOtraEmpresaRequest = JsonHelper.crearJsonMnObservacionPersonaRequest();
          mnObservacionOtraEmpresaRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
          mnObservacionOtraEmpresaRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionEmpresa;
          mnObservacionOtraEmpresaRequest.nrO_PASADA = nroPasada

          if (empresaObservedData.esObservadoPlaftYRiesgo) {
            mnObservacionOtraEmpresaRequest.obS_PLAFT = true;
            mnObservacionOtraEmpresaRequest.obS_RIESGOS = true;
          } else {
            mnObservacionOtraEmpresaRequest.obS_PLAFT = empresaObservedData.esObservadoPlaft;
            mnObservacionOtraEmpresaRequest.obS_RIESGOS = empresaObservedData.esObservadoRiesgos;
          }
          mnObservacionOtraEmpresaRequest.listA_NEGRA = empresaObservedData.bantotalJson;
          mnObservacionOtraEmpresaRequest.gesintel = empresaObservedData.gesintelJson;

          mnObservacionOtraEmpresaRequest.inD_ESTADO = true;
          mnObservacionOtraEmpresaRequest.idenT_USUARIO = identUsuario;

        }
        //Otra Empresa titular observado end

        ingresosDetallesTitular.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest,
          "mnObservacionEmpresaRequest": mnObservacionOtraEmpresaRequest
        })

      });

    }

    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
      if (newIngresosConyugeData.identCategoriaLaboral === appConfigKey.keyIdSinCategoria) {

        mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_INGRESO_PRINCIPAL = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

        ingresosDetallesConyugue.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge,
          "mnObservacionEmpresaRequest": null
        })

      } else {

        mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaId ?? appDefaultValues.NumberDefault;
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ANUALIZADO = GeneralHelper.ParseBoolean(newIngresosConyugeData.anualizado);
        mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaIngresoLaboral);
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_TIPO_MONEDA_INGRESO = newIngresosConyugeData.identTipoMoneda;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newIngresosConyugeData.identTipoMoneda)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_NETO = GeneralHelper.ParseNumber(newIngresosConyugeData.ingresosNetos);
        mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(newIngresosConyugeData.anualizado) ? GeneralHelper.ParseNumber(newIngresosConyugeData.calculoAnualizado) : 0;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_INGRESO_PRINCIPAL = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenId);
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenId)?.description) : '';
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;


        mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaId ?? appDefaultValues.NumberDefault;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = newIngresosConyugeData.razonSocial;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = newIngresosConyugeData.ruc;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

        //Empresa conyuge observado
        let mnObservacionEmpresaRequest_conyuge: any = null;
        let empresaObservedData = blackListGesintel.find((x: any) =>
          x.tipoDocumento === appConfigKey.keyIdRUC &&
          x.numeroDocumento === newIngresosConyugeData.ruc)

        if (empresaObservedData) {

          mnObservacionEmpresaRequest_conyuge = JsonHelper.crearJsonMnObservacionPersonaRequest();
          mnObservacionEmpresaRequest_conyuge.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
          mnObservacionEmpresaRequest_conyuge.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionEmpresa;
          mnObservacionEmpresaRequest_conyuge.nrO_PASADA = nroPasada

          if (empresaObservedData.esObservadoPlaftYRiesgo) {
            mnObservacionEmpresaRequest_conyuge.obS_PLAFT = true;
            mnObservacionEmpresaRequest_conyuge.obS_RIESGOS = true;
          } else {
            mnObservacionEmpresaRequest_conyuge.obS_PLAFT = empresaObservedData.esObservadoPlaft;
            mnObservacionEmpresaRequest_conyuge.obS_RIESGOS = empresaObservedData.esObservadoRiesgos;
          }
          mnObservacionEmpresaRequest_conyuge.listA_NEGRA = empresaObservedData.bantotalJson;
          mnObservacionEmpresaRequest_conyuge.gesintel = empresaObservedData.gesintelJson;

          mnObservacionEmpresaRequest_conyuge.inD_ESTADO = true;
          mnObservacionEmpresaRequest_conyuge.idenT_USUARIO = identUsuario;

        }
        //Empresa conyuge observado end

        ingresosDetallesConyugue.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge,
          "mnObservacionEmpresaRequest": mnObservacionEmpresaRequest_conyuge
        })

        if (GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior)) {

          mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
          mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboralAnterior;
          mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboralAnterior)?.description);
          mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaIngresoLaboralAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
          mnIngresoPersonaInsertUpdateRequest_conyuge.inD_TRABAJO_ANTERIOR = GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_FIN_TRABAJO_ANTERIOR = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaHastaAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenIdAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenIdAnterior) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenIdAnterior)?.description) : '';
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

          mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = newIngresosConyugeData.razonSocialAnterior;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = newIngresosConyugeData.rucAnterior;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;


          ingresosDetallesConyugue.push({
            "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
            "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge,
            "mnObservacionEmpresaRequest": null
          })
        }

        if (newIngresosConyugeData.listaOtrosTrabajos.length > 0) {
          newIngresosConyugeData.listaOtrosTrabajos.map((rowOtroTrabajo: any, index: any) => {
            mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = rowOtroTrabajo.identCategoriaLaboral;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === rowOtroTrabajo.identCategoriaLaboral)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ANUALIZADO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado);
            mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', rowOtroTrabajo.fechaIngresoLaboral);
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_TIPO_MONEDA_INGRESO = rowOtroTrabajo.identTipoMoneda;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === rowOtroTrabajo.identTipoMoneda)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_NETO = GeneralHelper.ParseNumber(rowOtroTrabajo.ingresosNetos);
            mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado) ? GeneralHelper.ParseNumber(rowOtroTrabajo.calculoAnualizado) : 0;
            mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId);
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenId)?.description) : '';
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

            mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = rowOtroTrabajo.razonSocial;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC)?.description);
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = rowOtroTrabajo.ruc;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

            //Otra Empresa conyuge observado
            let mnObservacionOtraEmpresaRequest_conyuge: any = null;
            let empresaObservedData = blackListGesintel.find((x: any) =>
              x.tipoDocumento === appConfigKey.keyIdRUC &&
              x.numeroDocumento === rowOtroTrabajo.ruc)

            if (empresaObservedData) {

              mnObservacionOtraEmpresaRequest_conyuge = JsonHelper.crearJsonMnObservacionPersonaRequest();
              mnObservacionOtraEmpresaRequest_conyuge.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
              mnObservacionOtraEmpresaRequest_conyuge.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionEmpresa;
              mnObservacionOtraEmpresaRequest_conyuge.nrO_PASADA = nroPasada

              if (empresaObservedData.esObservadoPlaftYRiesgo) {
                mnObservacionOtraEmpresaRequest_conyuge.obS_PLAFT = true;
                mnObservacionOtraEmpresaRequest_conyuge.obS_RIESGOS = true;
              } else {
                mnObservacionOtraEmpresaRequest_conyuge.obS_PLAFT = empresaObservedData.esObservadoPlaft;
                mnObservacionOtraEmpresaRequest_conyuge.obS_RIESGOS = empresaObservedData.esObservadoRiesgos;
              }
              mnObservacionOtraEmpresaRequest_conyuge.listA_NEGRA = empresaObservedData.bantotalJson;
              mnObservacionOtraEmpresaRequest_conyuge.gesintel = empresaObservedData.gesintelJson;

              mnObservacionOtraEmpresaRequest_conyuge.inD_ESTADO = true;
              mnObservacionOtraEmpresaRequest_conyuge.idenT_USUARIO = identUsuario;

            }
            //Otra Empresa conyuge observado end

            ingresosDetallesConyugue.push({
              "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
              "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge,
              "mnObservacionEmpresaRequest": mnObservacionOtraEmpresaRequest_conyuge
            })

          });
        }
      }

    }

    if (newFiadorData !== null && newFiadorData.ListaFiadores !== null) {
      if (newFiadorData.ListaFiadores.length > 0) {
        newFiadorData.ListaFiadores.map((rowFiador: any, index: any) => {

          //Fiador observado
          let fiadorObservedData = blackListGesintel.find((x: any) =>
            x.tipoDocumento === rowFiador.TipoDocumentoId &&
            x.numeroDocumento === rowFiador.NumeroDocumento)

          if (fiadorObservedData) {

            mnObservacionPersonaRequest_Fiador = JsonHelper.crearJsonMnObservacionPersonaRequest();
            mnObservacionPersonaRequest_Fiador.idenT_PERSONA = rowFiador.PersonaId;
            mnObservacionPersonaRequest_Fiador.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
            mnObservacionPersonaRequest_Fiador.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionFiador;
            mnObservacionPersonaRequest_Fiador.nrO_PASADA = nroPasada

            if (fiadorObservedData.esObservadoPlaftYRiesgo) {
              mnObservacionPersonaRequest_Fiador.obS_PLAFT = true;
              mnObservacionPersonaRequest_Fiador.obS_RIESGOS = true;
            } else {
              mnObservacionPersonaRequest_Fiador.obS_PLAFT = fiadorObservedData.esObservadoPlaft;
              mnObservacionPersonaRequest_Fiador.obS_RIESGOS = fiadorObservedData.esObservadoRiesgos;
            }
            mnObservacionPersonaRequest_Fiador.listA_NEGRA = fiadorObservedData.bantotalJson;
            mnObservacionPersonaRequest_Fiador.gesintel = fiadorObservedData.gesintelJson;

            mnObservacionPersonaRequest_Fiador.inD_ESTADO = true;
            mnObservacionPersonaRequest_Fiador.idenT_USUARIO = identUsuario;

          }
          //Fiador observado end

          mnPersonaRequest_Fiador = JsonHelper.crearJsonMnPersona();
          mnPersonaRequest_Fiador.idenT_PERSONA = rowFiador.PersonaId ?? appDefaultValues.NumberDefault;
          mnPersonaRequest_Fiador.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
          mnPersonaRequest_Fiador.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
          mnPersonaRequest_Fiador.iD_TIPO_DOCUMENTO = rowFiador.TipoDocumentoId;
          mnPersonaRequest_Fiador.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === rowFiador.TipoDocumentoId)?.description);
          mnPersonaRequest_Fiador.codL_NUMERO_DOCUMENTO = rowFiador.NumeroDocumento;
          mnPersonaRequest_Fiador.descL_NOMBRE_COMPLETO = rowFiador.Nombres + " " +
            rowFiador.ApellidoPaterno + " " +
            rowFiador.ApellidoMaterno;
          mnPersonaRequest_Fiador.fecH_NACIMIENTO_CONSTITUCION = rowFiador.FechaNacimiento;
          mnPersonaRequest_Fiador.descL_APELLIDO_PATERNO = rowFiador.ApellidoPaterno;
          mnPersonaRequest_Fiador.descL_APELLIDO_MATERNO = rowFiador.ApellidoMaterno;
          mnPersonaRequest_Fiador.descL_NOMBRES = rowFiador.Nombres;
          mnPersonaRequest_Fiador.iD_GENERO = GeneralHelper.ParseGeneroString(rowFiador?.GeneroId);
          mnPersonaRequest_Fiador.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === rowFiador.GeneroId)?.description);
          mnPersonaRequest_Fiador.iD_NACIONALIDAD = rowFiador.NacionalidadId;
          mnPersonaRequest_Fiador.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === rowFiador.NacionalidadId)?.description);
          mnPersonaRequest_Fiador.iD_PROFESION = rowFiador.NacionalidadId;
          mnPersonaRequest_Fiador.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === rowFiador.ProfesionId)?.description);
          mnPersonaRequest_Fiador.iD_ESTADO_CIVIL = GeneralHelper.ParseNumber(rowFiador.EstadoCivilId);
          mnPersonaRequest_Fiador.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.EstadoCivilId))?.description);
          mnPersonaRequest_Fiador.inD_ESTADO = true;
          mnPersonaRequest_Fiador.iD_PAIS_RESIDENCIA = rowFiador.PaisRecidenciaId;
          mnPersonaRequest_Fiador.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.PaisRecidenciaId))?.description);
          mnPersonaRequest_Fiador.idenT_USUARIO = identUsuario;


          if (rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
              rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {

            //Fiador conyuge observado
            let fiadorConyugeObservedData = blackListGesintel.find((x: any) =>
              x.tipoDocumento === rowFiador.Conyuge.TipoDocumentoId &&
              x.numeroDocumento === rowFiador.Conyuge.NumeroDocumento)

            if (fiadorConyugeObservedData) {

              mnObservacionPersonaConyugueRequest_Fiador = JsonHelper.crearJsonMnObservacionPersonaRequest();
              mnObservacionPersonaConyugueRequest_Fiador.idenT_PERSONA = rowFiador.Conyuge.PersonaId;
              mnObservacionPersonaConyugueRequest_Fiador.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
              mnObservacionPersonaConyugueRequest_Fiador.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionFiadorConyuge;
              mnObservacionPersonaConyugueRequest_Fiador.nrO_PASADA = nroPasada

              if (fiadorConyugeObservedData.esObservadoPlaftYRiesgo) {
                mnObservacionPersonaConyugueRequest_Fiador.obS_PLAFT = true;
                mnObservacionPersonaConyugueRequest_Fiador.obS_RIESGOS = true;
              } else {
                mnObservacionPersonaConyugueRequest_Fiador.obS_PLAFT = fiadorConyugeObservedData.esObservadoPlaft;
                mnObservacionPersonaConyugueRequest_Fiador.obS_RIESGOS = fiadorConyugeObservedData.esObservadoRiesgos;
              }
              mnObservacionPersonaConyugueRequest_Fiador.listA_NEGRA = fiadorConyugeObservedData.bantotalJson;
              mnObservacionPersonaConyugueRequest_Fiador.gesintel = fiadorConyugeObservedData.gesintelJson;

              mnObservacionPersonaConyugueRequest_Fiador.inD_ESTADO = true;
              mnObservacionPersonaConyugueRequest_Fiador.idenT_USUARIO = identUsuario;

            }
            //Fiador conyuge observado end

            mnPersonaConyugueRequest_Fiador = JsonHelper.crearJsonMnPersona();
            mnPersonaConyugueRequest_Fiador.idenT_PERSONA = rowFiador.Conyuge.PersonaId ?? appDefaultValues.NumberDefault;
            mnPersonaConyugueRequest_Fiador.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
            mnPersonaConyugueRequest_Fiador.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
            mnPersonaConyugueRequest_Fiador.iD_TIPO_DOCUMENTO = rowFiador.Conyuge.TipoDocumentoId;
            mnPersonaConyugueRequest_Fiador.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === rowFiador.Conyuge.TipoDocumentoId)?.description);
            mnPersonaConyugueRequest_Fiador.codL_NUMERO_DOCUMENTO = rowFiador.Conyuge.NumeroDocumento;
            mnPersonaConyugueRequest_Fiador.descL_NOMBRE_COMPLETO = rowFiador.Conyuge.Nombres + " " +
              rowFiador.Conyuge.ApellidoPaterno + " " +
              rowFiador.Conyuge.ApellidoMaterno;
            mnPersonaConyugueRequest_Fiador.fecH_NACIMIENTO_CONSTITUCION = rowFiador.Conyuge.FechaNacimiento;
            mnPersonaConyugueRequest_Fiador.descL_APELLIDO_PATERNO = rowFiador.Conyuge.ApellidoPaterno;
            mnPersonaConyugueRequest_Fiador.descL_APELLIDO_MATERNO = rowFiador.Conyuge.ApellidoMaterno;
            mnPersonaConyugueRequest_Fiador.descL_NOMBRES = rowFiador.Conyuge.Nombres;
            mnPersonaConyugueRequest_Fiador.iD_GENERO = GeneralHelper.ParseGeneroString(rowFiador?.Conyuge?.GeneroId);
            mnPersonaConyugueRequest_Fiador.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === rowFiador.Conyuge.GeneroId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_NACIONALIDAD = rowFiador.Conyuge.NacionalidadId;
            mnPersonaConyugueRequest_Fiador.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === rowFiador.Conyuge.NacionalidadId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_PROFESION = rowFiador.Conyuge.ProfesionId;
            mnPersonaConyugueRequest_Fiador.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === rowFiador.Conyuge.ProfesionId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_ESTADO_CIVIL = appConfigKey.keyIdEstadoCivilCasado;
            mnPersonaConyugueRequest_Fiador.descC_ESTADO_CIVIL = appConfigKey.keyDescripEstadoCivilCasado;
            mnPersonaConyugueRequest_Fiador.inD_ESTADO = true;
            mnPersonaConyugueRequest_Fiador.iD_PAIS_RESIDENCIA = rowFiador.Conyuge.PaisRecidenciaId;
            mnPersonaConyugueRequest_Fiador.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.Conyuge.PaisRecidenciaId))?.description);
            mnPersonaConyugueRequest_Fiador.idenT_USUARIO = identUsuario;

          }
          mnPersonasFiadoresRequest.push(
            {
              "mnObservacionPersonaRequest": mnObservacionPersonaRequest_Fiador,
              "mnPersonaRequest": mnPersonaRequest_Fiador,
              "mnObservacionPersonaConyugueRequest": mnObservacionPersonaConyugueRequest_Fiador,
              "mnPersonaConyugueRequest": mnPersonaConyugueRequest_Fiador
            }
          )
        });
      }
    }

    if (GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '') {

      //Compra Para observado
      let compraParaObservedData = blackListGesintel.find((x: any) =>
        x.tipoDocumento === newPersonaCompraParaData.TipoDocumentoId &&
        x.numeroDocumento === newPersonaCompraParaData.NumeroDocumento)

      if (compraParaObservedData) {

        mnObservacionPersonaRequest_CompraPara = JsonHelper.crearJsonMnObservacionPersonaRequest();
        mnObservacionPersonaRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA;
        mnObservacionPersonaRequest_CompraPara.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
        mnObservacionPersonaRequest_CompraPara.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionCompraPara;
        mnObservacionPersonaRequest_CompraPara.nrO_PASADA = nroPasada

        if (compraParaObservedData.esObservadoPlaftYRiesgo) {
          mnObservacionPersonaRequest_CompraPara.obS_PLAFT = true;
          mnObservacionPersonaRequest_CompraPara.obS_RIESGOS = true;
        } else {
          mnObservacionPersonaRequest_CompraPara.obS_PLAFT = compraParaObservedData.esObservadoPlaft;
          mnObservacionPersonaRequest_CompraPara.obS_RIESGOS = compraParaObservedData.esObservadoRiesgos;
        }
        mnObservacionPersonaRequest_CompraPara.listA_NEGRA = compraParaObservedData.bantotalJson;
        mnObservacionPersonaRequest_CompraPara.gesintel = compraParaObservedData.gesintelJson;

        mnObservacionPersonaRequest_CompraPara.inD_ESTADO = true;
        mnObservacionPersonaRequest_CompraPara.idenT_USUARIO = identUsuario;

      }
      //Compra Para observado end

      mnPersonaRequest_CompraPara = JsonHelper.crearJsonMnPersona();
      mnPersonaRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA ?? appDefaultValues.NumberDefault;
      mnPersonaRequest_CompraPara.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
      mnPersonaRequest_CompraPara.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
      mnPersonaRequest_CompraPara.iD_TIPO_DOCUMENTO = newPersonaCompraParaData.TipoDocumentoId;
      mnPersonaRequest_CompraPara.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newPersonaCompraParaData.TipoDocumentoId)?.description);
      mnPersonaRequest_CompraPara.codL_NUMERO_DOCUMENTO = newPersonaCompraParaData.NumeroDocumento;
      mnPersonaRequest_CompraPara.descL_NOMBRE_COMPLETO = newPersonaCompraParaData.Nombres + " " +
        newPersonaCompraParaData.ApellidoPaterno + " " +
        newPersonaCompraParaData.ApellidoMaterno;
      mnPersonaRequest_CompraPara.fecH_NACIMIENTO_CONSTITUCION = newPersonaCompraParaData.FechaNacimiento;
      mnPersonaRequest_CompraPara.descL_APELLIDO_PATERNO = newPersonaCompraParaData.ApellidoPaterno;
      mnPersonaRequest_CompraPara.descL_APELLIDO_MATERNO = newPersonaCompraParaData.ApellidoMaterno;
      mnPersonaRequest_CompraPara.descL_NOMBRES = newPersonaCompraParaData.Nombres;
      mnPersonaRequest_CompraPara.iD_GENERO = GeneralHelper.ParseGeneroString(newPersonaCompraParaData?.GeneroId);
      mnPersonaRequest_CompraPara.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newPersonaCompraParaData.GeneroId)?.description);
      mnPersonaRequest_CompraPara.iD_NACIONALIDAD = newPersonaCompraParaData.NacionalidadId;
      mnPersonaRequest_CompraPara.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newPersonaCompraParaData.NacionalidadId)?.description);
      mnPersonaRequest_CompraPara.iD_ESTADO_CIVIL = GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId);
      mnPersonaRequest_CompraPara.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId))?.description);
      mnPersonaRequest_CompraPara.inD_ESTADO = true;
      mnPersonaRequest_CompraPara.iD_PAIS_RESIDENCIA = newPersonaCompraParaData.PaisRecidenciaId;
      mnPersonaRequest_CompraPara.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newPersonaCompraParaData.PaisRecidenciaId)?.description);
      mnPersonaRequest_CompraPara.idenT_USUARIO = identUsuario;


      if (
        (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado ||
         GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilConviviente) &&
        GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '') {

        //Compra Para conyuge observado
        let compraParaConyugeObservedData = blackListGesintel.find((x: any) =>
          x.tipoDocumento === newPersonaCompraParaConyugeData.TipoDocumentoId &&
          x.numeroDocumento === newPersonaCompraParaConyugeData.NumeroDocumento)

        if (compraParaConyugeObservedData) {

          mnObservacionPersonaConyugueRequest_CompraPara = JsonHelper.crearJsonMnObservacionPersonaRequest();
          mnObservacionPersonaConyugueRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE;
          mnObservacionPersonaConyugueRequest_CompraPara.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
          mnObservacionPersonaConyugueRequest_CompraPara.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionCompraParaConyuge;
          mnObservacionPersonaConyugueRequest_CompraPara.nrO_PASADA = nroPasada

          if (compraParaConyugeObservedData.esObservadoPlaftYRiesgo) {
            mnObservacionPersonaConyugueRequest_CompraPara.obS_PLAFT = true;
            mnObservacionPersonaConyugueRequest_CompraPara.obS_RIESGOS = true;
          } else {
            mnObservacionPersonaConyugueRequest_CompraPara.obS_PLAFT = compraParaConyugeObservedData.esObservadoPlaft;
            mnObservacionPersonaConyugueRequest_CompraPara.obS_RIESGOS = compraParaConyugeObservedData.esObservadoRiesgos;
          }
          mnObservacionPersonaConyugueRequest_CompraPara.listA_NEGRA = compraParaConyugeObservedData.bantotalJson;
          mnObservacionPersonaConyugueRequest_CompraPara.gesintel = compraParaConyugeObservedData.gesintelJson;

          mnObservacionPersonaConyugueRequest_CompraPara.inD_ESTADO = true;
          mnObservacionPersonaConyugueRequest_CompraPara.idenT_USUARIO = identUsuario;

        }
        //Compra Para conyuge observado end

        mnPersonaConyugueRequest_CompraPara = JsonHelper.crearJsonMnPersona();
        mnPersonaConyugueRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE ?? appDefaultValues.NumberDefault;
        mnPersonaConyugueRequest_CompraPara.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
        mnPersonaConyugueRequest_CompraPara.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
        mnPersonaConyugueRequest_CompraPara.iD_TIPO_DOCUMENTO = newPersonaCompraParaConyugeData.TipoDocumentoId;
        mnPersonaConyugueRequest_CompraPara.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newPersonaCompraParaConyugeData.TipoDocumentoId)?.description);
        mnPersonaConyugueRequest_CompraPara.codL_NUMERO_DOCUMENTO = newPersonaCompraParaConyugeData.NumeroDocumento;
        mnPersonaConyugueRequest_CompraPara.descL_NOMBRE_COMPLETO = newPersonaCompraParaConyugeData.Nombres + " " +
          newPersonaCompraParaConyugeData.ApellidoPaterno + " " +
          newPersonaCompraParaConyugeData.ApellidoMaterno;
        mnPersonaConyugueRequest_CompraPara.fecH_NACIMIENTO_CONSTITUCION = newPersonaCompraParaConyugeData.FechaNacimiento;
        mnPersonaConyugueRequest_CompraPara.descL_APELLIDO_PATERNO = newPersonaCompraParaConyugeData.ApellidoPaterno;
        mnPersonaConyugueRequest_CompraPara.descL_APELLIDO_MATERNO = newPersonaCompraParaConyugeData.ApellidoMaterno;
        mnPersonaConyugueRequest_CompraPara.descL_NOMBRES = newPersonaCompraParaConyugeData.Nombres;
        mnPersonaConyugueRequest_CompraPara.iD_GENERO = GeneralHelper.ParseGeneroString(newPersonaCompraParaConyugeData?.GeneroId);
        mnPersonaConyugueRequest_CompraPara.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newPersonaCompraParaConyugeData.GeneroId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_MAGNITUD = newPersonaCompraParaConyugeData.MagnitudId;
        mnPersonaConyugueRequest_CompraPara.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newPersonaCompraParaConyugeData.MagnitudId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_ACTIVIDAD_ECONOMICA = newPersonaCompraParaConyugeData.ActividadEconomicaId;
        mnPersonaConyugueRequest_CompraPara.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newPersonaCompraParaConyugeData.ActividadEconomicaId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_ESTADO_CIVIL = appConfigKey.keyIdEstadoCivilCasado;
        mnPersonaConyugueRequest_CompraPara.descC_ESTADO_CIVIL = appConfigKey.keyDescripEstadoCivilCasado;
        mnPersonaConyugueRequest_CompraPara.inD_ESTADO = true;
        mnPersonaConyugueRequest_CompraPara.idenT_USUARIO = identUsuario;
      }

      mnPersonaCompraParaRequest.push({
        "mnObservacionPersonaRequest": mnObservacionPersonaRequest_CompraPara,
        "mnPersonaRequest": mnPersonaRequest_CompraPara,
        "mnObservacionPersonaConyugueRequest": mnObservacionPersonaConyugueRequest_CompraPara,
        "mnPersonaConyugueRequest": mnPersonaConyugueRequest_CompraPara
      })

    }

    //Ejecutivo observado
    let mnObservacionEjecutivoRequest = null;
    let ejecutivoObservedData = blackListGesintel.find((x: any) =>
      x.tipoRelacionId === appConfigKey.KeyIdTipoRelacionEjecutivo &&
      x.tipoDocumento === executiveData.iD_TIPO_DOCUMENTO &&
      x.numeroDocumento === executiveData.nrO_DOCUMENTO
    );

    if (ejecutivoObservedData) {

      mnObservacionEjecutivoRequest = JsonHelper.crearJsonMnObservacionPersonaRequest();
      mnObservacionEjecutivoRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      mnObservacionEjecutivoRequest.idenT_PERSONA = executiveData.idenT_USUARIO;
      mnObservacionEjecutivoRequest.idenT_TIPO_RELACION = ejecutivoObservedData.tipoRelacionId;
      mnObservacionEjecutivoRequest.nrO_PASADA = nroPasada

      if (ejecutivoObservedData.esObservadoPlaftYRiesgo) {
        mnObservacionEjecutivoRequest.obS_PLAFT = true;
        mnObservacionEjecutivoRequest.obS_RIESGOS = true;
      } else {
        mnObservacionEjecutivoRequest.obS_PLAFT = ejecutivoObservedData.esObservadoPlaft;
        mnObservacionEjecutivoRequest.obS_RIESGOS = ejecutivoObservedData.esObservadoRiesgos;
      }
      mnObservacionEjecutivoRequest.listA_NEGRA = ejecutivoObservedData.bantotalJson;
      mnObservacionEjecutivoRequest.gesintel = ejecutivoObservedData.gesintelJson;

      mnObservacionEjecutivoRequest.inD_ESTADO = true;
      mnObservacionEjecutivoRequest.idenT_USUARIO = identUsuario;

      mnPersonasObservadas.push(mnObservacionEjecutivoRequest);

    }
    //Ejecutivo observado end

    //Concesionario observado
    let mnObservacionConcesionarioRequest = null;
    let concesionarioObservedData = blackListGesintel.find((x: any) =>
      x.tipoRelacionId === appConfigKey.KeyIdTipoRelacionConcesionario &&
      x.tipoDocumento === partnerData?.datosPartner.tipoDocumento &&
      x.numeroDocumento === partnerData?.datosPartner.numeroDocumento
    );

    if (concesionarioObservedData) {

      mnObservacionConcesionarioRequest = JsonHelper.crearJsonMnObservacionAsociadoRequest();
      mnObservacionConcesionarioRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      mnObservacionConcesionarioRequest.idenT_SOLICITUD_ASOCIADO = 0;
      mnObservacionConcesionarioRequest.iD_TIPO_DOCUMENTO = concesionarioObservedData.tipoDocumento;
      mnObservacionConcesionarioRequest.codL_NUMERO_DOCUMENTO = concesionarioObservedData.numeroDocumento;
      mnObservacionConcesionarioRequest.idenT_TIPO_RELACION = concesionarioObservedData.tipoRelacionId;
      mnObservacionConcesionarioRequest.nrO_PASADA = nroPasada

      if (concesionarioObservedData.esObservadoPlaftYRiesgo) {
        mnObservacionConcesionarioRequest.obS_PLAFT = true;
        mnObservacionConcesionarioRequest.obS_RIESGOS = true;
      } else {
        mnObservacionConcesionarioRequest.obS_PLAFT = concesionarioObservedData.esObservadoPlaft;
        mnObservacionConcesionarioRequest.obS_RIESGOS = concesionarioObservedData.esObservadoRiesgos;
      }
      mnObservacionConcesionarioRequest.listA_NEGRA = concesionarioObservedData.bantotalJson;
      mnObservacionConcesionarioRequest.gesintel = concesionarioObservedData.gesintelJson;

      mnObservacionConcesionarioRequest.inD_ESTADO = true;
      mnObservacionConcesionarioRequest.idenT_USUARIO = identUsuario;

      mnAsociadosObservados.push(mnObservacionConcesionarioRequest);

    }
    //Concesionario observado end

    //Vendedor observado
    let mnObservacionVendedorRequest = null;
    let vendedorObservedData = blackListGesintel.find((x: any) =>
      x.tipoRelacionId === appConfigKey.KeyIdTipoRelacionVendedor &&
      x.tipoDocumento === partnerData?.datosVendedor.tipoDocumento &&
      x.numeroDocumento === partnerData?.datosVendedor.numeroDocumento
    );

    if (vendedorObservedData) {

      mnObservacionVendedorRequest = JsonHelper.crearJsonMnObservacionAsociadoRequest();
      mnObservacionVendedorRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      mnObservacionVendedorRequest.idenT_SOLICITUD_ASOCIADO = 0;
      mnObservacionVendedorRequest.iD_TIPO_DOCUMENTO = vendedorObservedData.tipoDocumento;
      mnObservacionVendedorRequest.codL_NUMERO_DOCUMENTO = vendedorObservedData.numeroDocumento;
      mnObservacionVendedorRequest.idenT_TIPO_RELACION = vendedorObservedData.tipoRelacionId;
      mnObservacionVendedorRequest.nrO_PASADA = nroPasada

      if (vendedorObservedData.esObservadoPlaftYRiesgo) {
        mnObservacionVendedorRequest.obS_PLAFT = true;
        mnObservacionVendedorRequest.obS_RIESGOS = true;
      } else {
        mnObservacionVendedorRequest.obS_PLAFT = vendedorObservedData.esObservadoPlaft;
        mnObservacionVendedorRequest.obS_RIESGOS = vendedorObservedData.esObservadoRiesgos;
      }
      mnObservacionVendedorRequest.listA_NEGRA = vendedorObservedData.bantotalJson;
      mnObservacionVendedorRequest.gesintel = vendedorObservedData.gesintelJson;

      mnObservacionVendedorRequest.inD_ESTADO = true;
      mnObservacionVendedorRequest.idenT_USUARIO = identUsuario;

      mnAsociadosObservados.push(mnObservacionVendedorRequest);

    }
    //Vendedor observado end

    return {
      "mnSolicitudPrimerPasoRequest": mnSolicitudPrimerPasoRequest,
      "mnPersonaTitularRequest": mnPersonaTitularRequest,
      "ingresosDetallesTitular": ingresosDetallesTitular,
      "ingresosDetallesConyugue": ingresosDetallesConyugue,
      "mnPersonasFiadoresRequest": mnPersonasFiadoresRequest,
      "mnPersonaCompraParaRequest": mnPersonaCompraParaRequest,
      "mnPersonasObservadas": mnPersonasObservadas,
      "mnAsociadosObservados": mnAsociadosObservados,
      "nroPasada": nroPasada,
      "identEtapa": identEtapa,
    }
  },

  crearJsonGetSolicitudCambiarEstadoById: () => {
    return {
      "idenT_SOLICITUD": appDefaultValues.NumberDefault,
      "iD_ESTADO_SOLICITUD": appDefaultValues.NumberDefault,
      "idenT_USUARIO_MODIF": appDefaultValues.NumberDefault
    }
  },

  crearJsonRequestGenerateActualizacionSolicitudV2: (
    nroPaso: number,
    solicitudData: any,
    newTitularData: any,
    newConyugeData: any,
    newIngresosTitularData: any,
    newIngresosConyugeData: any,
    newPersonaCompraParaData: any,
    newPersonaCompraParaConyugeData: any,
    newFiadorData: any,
    newObservacionData: any,
    newDatosFinanciamiento: any,
    newCondicionesFinanciamiento: any,
    newCronograma: any,
    newDatosAdicionalesTitularData: any,
    newDomicilioTitularData: any,
    newDatosLaboralesAdicionalesTitularData: any,
    newDatosAdicionalesConyugeData: any,
    newDatosLaboralesAdicionalesConyugeData: any,
    listasTemp: any,
    identUsuario: number,
    tcea: number,
    isTitularJuridico: boolean | null,
    newDatosTitularEmpresaData: any,
    newDatosAdicionalesTitularEmpresaData: any,
  ) => {

    let mnPersonaTitularRequest: any = [];
    let mnPersonaTitularEmpresaAdicionalRequest: any = null;
    let mnPersonaRequest: any = null;
    let mnPersonaConyugueRequest: any = null;
    let mnPersonaCompraParaRequest: any = [];
    let mnPersonaRequest_CompraPara: any = null;
    let mnPersonaConyugueRequest_CompraPara: any = null;
    let mnPersonasFiadoresRequest: any = [];
    let mnPersonaRequest_Fiador: any = null;
    let mnPersonaConyugueRequest_Fiador: any = null;
    let mnIngresosDetallesTitularRequest: any = [];
    let mnIngresoPersonaInsertUpdateRequest: any = null;
    let mnEmpresaEmpleadorInsertUpdateRequest: any = null;
    let mnIngresosDetallesConyugueRequest: any = [];
    let mnIngresoPersonaInsertUpdateRequest_conyuge: any = null;
    let mnEmpresaEmpleadorInsertUpdateRequest_conyuge: any = null;
    let mnSolicitudInsertUpdateRequest: any = null;
    let mnSolicitudGastosInsertRequest: any = [];
    let mnSolicitudCronogramaInsertRequest: any = [];

    mnPersonaRequest = JsonHelper.crearJsonMnPersona();
    mnPersonaRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR ?? appDefaultValues.NumberDefault;
    mnPersonaRequest.iD_TIPO_PERSONA = newTitularData.TipoPersonaId;
    mnPersonaRequest.descC_TIPO_PERSONA = newTitularData.TipoPersonaId === appConfigKey.keyIdPersonaNatural ? appConfigKey.keyDescripPersonaNatural : appConfigKey.keyDescripPersonaJuridica;
    mnPersonaRequest.iD_TIPO_DOCUMENTO = newTitularData.TipoDocumentoId;
    mnPersonaRequest.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newTitularData.TipoDocumentoId)?.description);
    mnPersonaRequest.codL_NUMERO_DOCUMENTO = newTitularData.NumeroDocumento;
    mnPersonaRequest.descL_NOMBRE_COMPLETO = newTitularData.Nombres.trim() + " " + newTitularData.ApellidoPaterno + " " + newTitularData.ApellidoMaterno;
    mnPersonaRequest.descC_CELULAR = newTitularData.Celular;
    mnPersonaRequest.descL_EMAIL = newTitularData.Correo;
    mnPersonaRequest.fecH_NACIMIENTO_CONSTITUCION = newTitularData.FechaNacimiento;
    mnPersonaRequest.descL_APELLIDO_PATERNO = newTitularData.ApellidoPaterno;
    mnPersonaRequest.descL_APELLIDO_MATERNO = newTitularData.ApellidoMaterno;
    mnPersonaRequest.descL_NOMBRES = newTitularData.Nombres.trim();
    mnPersonaRequest.iD_GENERO = GeneralHelper.ParseGeneroString(newTitularData?.GeneroId);
    mnPersonaRequest.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newTitularData.GeneroId)?.description);
    mnPersonaRequest.iD_MAGNITUD = newTitularData.MagnitudId;
    mnPersonaRequest.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newTitularData.MagnitudId)?.description);
    mnPersonaRequest.iD_TIPO_DOCUMENTO_COMPLEMENTARIO = newTitularData.DocumentoComplementarioId;
    mnPersonaRequest.descC_TIPO_DOCUMENTO_COMPLEMENTARIO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newTitularData.DocumentoComplementarioId)?.description);
    mnPersonaRequest.codL_NUMERO_DOCUMENTO_COMPLEMENTARIO = newTitularData.NumeroDocumentoComplementario;
    mnPersonaRequest.iD_ACTIVIDAD_ECONOMICA = newTitularData.ActividadEconomicaId;
    mnPersonaRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newTitularData.ActividadEconomicaId)?.description);
    mnPersonaRequest.flaG_PEP = newTitularData.ClientePep;
    mnPersonaRequest.iD_ESTADO_CIVIL = newTitularData.EstadoCivilId;
    mnPersonaRequest.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === newTitularData.EstadoCivilId)?.description);
    mnPersonaRequest.inD_ESTADO = true;
    mnPersonaRequest.descC_DOMICILIO = newTitularData.Domicilio;
    mnPersonaRequest.idenT_USUARIO = identUsuario;
    mnPersonaRequest.inD_SEPARACION_BIENES = newTitularData.SeparacionBienes;
    mnPersonaRequest.iD_NACIONALIDAD = newTitularData.NacionalidadId;
    mnPersonaRequest.iD_RANGO_VENTA_ANUAL = newTitularData.RangoVentaAnualId;
    mnPersonaRequest.descC_RANGO_VENTA_ANUAL = GeneralHelper.ParseString(listasTemp.listRangoVentaAnual?.find((x: any) => x.id === newTitularData.RangoVentaAnualId)?.description);
    mnPersonaRequest.fecH_INICIO_ACTIVIDAD = GeneralHelper.ObtenerStringNullable(newTitularData.FechaInicioActividad);
    mnPersonaRequest.iD_GIRO_NEGOCIO = newTitularData.GiroNegocioId;
    mnPersonaRequest.descC_GIRO_NEGOCIO = GeneralHelper.ParseString(listasTemp.listGiroNegocio?.find((x: any) => x.id === newTitularData.GiroNegocioId)?.description);

    if (nroPaso >= 3) {
      mnPersonaRequest.clientuid = newCondicionesFinanciamiento.clienteUId;
      mnPersonaRequest.personauid = newCondicionesFinanciamiento.titularPersonaUId;
    }

    if (nroPaso >= 5) {
      if (newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
        // mnPersonaRequest.iD_NACIONALIDAD = newDatosAdicionalesTitularData.identNacionalidad;
        // mnPersonaRequest.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newDatosAdicionalesTitularData.identNacionalidad)?.description);
        // mnPersonaRequest.iD_NIVEL_ESTUDIOS = newDatosAdicionalesTitularData.identNivelEducacion;
        // mnPersonaRequest.descC_NIVEL_ESTUDIOS = GeneralHelper.ParseString(listasTemp.listNivelEducacion.find((x: any) => x.id === newDatosAdicionalesTitularData.identNivelEducacion)?.description);
        // mnPersonaRequest.monT_NUMERO_HIJOS = newDatosAdicionalesTitularData.nroHijos;

        // if (newDatosAdicionalesTitularData.nroHijos === "") {
        //   mnPersonaRequest.monT_NUMERO_HIJOS = 0;
        // }

        // mnPersonaRequest.monT_NUMERO_DEPENDIENTES = newDatosAdicionalesTitularData.nroDependientes;

        // if (newDatosAdicionalesTitularData.nroDependientes === "") {
        //   mnPersonaRequest.monT_NUMERO_DEPENDIENTES = 0;
        // }

        // mnPersonaRequest.iD_PROFESION = newDatosAdicionalesTitularData.identProfesion;
        // mnPersonaRequest.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === newDatosAdicionalesTitularData.identProfesion)?.description);
        mnPersonaRequest.descL_NOMBRE_COMPLETO_CONTACTO1 = newDatosTitularEmpresaData.nomYApeContacto1;
        mnPersonaRequest.descL_NOMBRE_COMPLETO_CONTACTO2 = newDatosTitularEmpresaData.nomYApeContacto2;
        mnPersonaRequest.descC_NUMERO_CONTACTO1 = newDatosTitularEmpresaData.celular1;
        mnPersonaRequest.descC_NUMERO_CONTACTO2 = newDatosTitularEmpresaData.celular2;
        mnPersonaRequest.descM_NOMBRE_VIA = newDatosTitularEmpresaData.nombreVia;
        mnPersonaRequest.descC_NUMERO = newDatosTitularEmpresaData.numDomicilio;
        mnPersonaRequest.descC_INTERIOR_DEPARTAMENTO_PISO = newDatosTitularEmpresaData.intDptoPiso;
        mnPersonaRequest.descC_MANZANA = newDatosTitularEmpresaData.manzana;
        mnPersonaRequest.descC_LOTE = newDatosTitularEmpresaData.lote;
        mnPersonaRequest.descM_NOMBRE_AGRUPACION = newDatosTitularEmpresaData.nombreAgrupacion;
        mnPersonaRequest.descL_REFERENCIA = newDatosTitularEmpresaData.referencia;
        mnPersonaRequest.iD_TIPO_VIVIENDA = newDatosTitularEmpresaData.identTipoVivienda;
        mnPersonaRequest.descC_TIPO_VIVIENDA = GeneralHelper.ParseString(listasTemp.listTipoVivienda.find((x: any) => x.id === newDatosTitularEmpresaData.identTipoVivienda)?.description ?? '');
        mnPersonaRequest.iD_TIEMPO_RESIDENCIA = newDatosTitularEmpresaData.identTiempoResidencia;
        // mnPersonaRequest.idenT_CARGO_LABORAL = newDatosLaboralesAdicionalesTitularData.identCargoLaboral;
        // mnPersonaRequest.descL_DESCRIPCION_CARGO = newDatosLaboralesAdicionalesTitularData.descripcionCargo;
        // mnPersonaRequest.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesTitularData.identActividadEconomica; //Unificado
        // mnPersonaRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesTitularData.identActividadEconomica)?.description); //Unificado

      } else {
        mnPersonaRequest.iD_NACIONALIDAD = newDatosAdicionalesTitularData.identNacionalidad;
        mnPersonaRequest.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newDatosAdicionalesTitularData.identNacionalidad)?.description);
        mnPersonaRequest.iD_NIVEL_ESTUDIOS = newDatosAdicionalesTitularData.identNivelEducacion;
        mnPersonaRequest.descC_NIVEL_ESTUDIOS = GeneralHelper.ParseString(listasTemp.listNivelEducacion.find((x: any) => x.id === newDatosAdicionalesTitularData.identNivelEducacion)?.description);
        mnPersonaRequest.monT_NUMERO_HIJOS = newDatosAdicionalesTitularData.nroHijos;

        if (newDatosAdicionalesTitularData.nroHijos === "") {
          mnPersonaRequest.monT_NUMERO_HIJOS = 0;
        }

        mnPersonaRequest.monT_NUMERO_DEPENDIENTES = newDatosAdicionalesTitularData.nroDependientes;

        if (newDatosAdicionalesTitularData.nroDependientes === "") {
          mnPersonaRequest.monT_NUMERO_DEPENDIENTES = 0;
        }

        mnPersonaRequest.iD_PROFESION = newDatosAdicionalesTitularData.identProfesion;
        mnPersonaRequest.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === newDatosAdicionalesTitularData.identProfesion)?.description);
        mnPersonaRequest.descL_NOMBRE_COMPLETO_CONTACTO1 = newDatosAdicionalesTitularData.nomYApeContacto1;
        mnPersonaRequest.descL_NOMBRE_COMPLETO_CONTACTO2 = newDatosAdicionalesTitularData.nomYApeContacto2;
        mnPersonaRequest.descC_NUMERO_CONTACTO1 = newDatosAdicionalesTitularData.celular1;
        mnPersonaRequest.descC_NUMERO_CONTACTO2 = newDatosAdicionalesTitularData.celular2;
        mnPersonaRequest.descM_NOMBRE_VIA = newDomicilioTitularData.nombreVia;
        mnPersonaRequest.descC_NUMERO = newDomicilioTitularData.numDomicilio;
        mnPersonaRequest.descC_INTERIOR_DEPARTAMENTO_PISO = newDomicilioTitularData.intDptoPiso;
        mnPersonaRequest.descC_MANZANA = newDomicilioTitularData.manzana;
        mnPersonaRequest.descC_LOTE = newDomicilioTitularData.lote;
        mnPersonaRequest.descM_NOMBRE_AGRUPACION = newDomicilioTitularData.nombreAgrupacion;
        mnPersonaRequest.descL_REFERENCIA = newDomicilioTitularData.referencia;
        mnPersonaRequest.iD_TIPO_VIVIENDA = newDomicilioTitularData.identTipoVivienda;
        mnPersonaRequest.descC_TIPO_VIVIENDA = GeneralHelper.ParseString(listasTemp.listTipoVivienda.find((x: any) => x.id === newDomicilioTitularData.identTipoVivienda)?.description);
        mnPersonaRequest.iD_TIEMPO_RESIDENCIA = newDomicilioTitularData.identTiempoResidencia;
        mnPersonaRequest.idenT_CARGO_LABORAL = newDatosLaboralesAdicionalesTitularData.identCargoLaboral;
        mnPersonaRequest.descL_DESCRIPCION_CARGO = newDatosLaboralesAdicionalesTitularData.descripcionCargo;
        mnPersonaRequest.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesTitularData.identActividadEconomica; //Unificado
        mnPersonaRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesTitularData.identActividadEconomica)?.description); //Unificado
      }
    }

    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {
      mnPersonaConyugueRequest = JsonHelper.crearJsonMnPersona();
      mnPersonaConyugueRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE ?? appDefaultValues.NumberDefault;
      mnPersonaConyugueRequest.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
      mnPersonaConyugueRequest.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
      mnPersonaConyugueRequest.iD_TIPO_DOCUMENTO = newConyugeData.TipoDocumentoId;
      mnPersonaConyugueRequest.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newConyugeData.TipoDocumentoId)?.description);
      mnPersonaConyugueRequest.codL_NUMERO_DOCUMENTO = newConyugeData.NumeroDocumento;
      mnPersonaConyugueRequest.descL_NOMBRE_COMPLETO = newConyugeData.Nombres.trim() + " " + newConyugeData.ApellidoPaterno + " " + newConyugeData.ApellidoMaterno;
      mnPersonaConyugueRequest.descC_CELULAR = newConyugeData.Celular;
      mnPersonaConyugueRequest.fecH_NACIMIENTO_CONSTITUCION = newConyugeData.FechaNacimiento;
      mnPersonaConyugueRequest.descL_APELLIDO_PATERNO = newConyugeData.ApellidoPaterno;
      mnPersonaConyugueRequest.descL_APELLIDO_MATERNO = newConyugeData.ApellidoMaterno;
      mnPersonaConyugueRequest.descL_NOMBRES = newConyugeData.Nombres.trim();
      mnPersonaConyugueRequest.iD_GENERO = GeneralHelper.ParseGeneroString(newConyugeData?.GeneroId);
      mnPersonaConyugueRequest.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newConyugeData.GeneroId)?.description);
      mnPersonaConyugueRequest.iD_MAGNITUD = newConyugeData.MagnitudId;
      mnPersonaConyugueRequest.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newConyugeData.MagnitudId)?.description);
      mnPersonaConyugueRequest.iD_ACTIVIDAD_ECONOMICA = newConyugeData.ActividadEconomicaId;
      mnPersonaConyugueRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newConyugeData.ActividadEconomicaId)?.description);
      mnPersonaConyugueRequest.flaG_PEP = newConyugeData.ClientePep;
      mnPersonaConyugueRequest.iD_ESTADO_CIVIL = newTitularData.EstadoCivilId;
      mnPersonaConyugueRequest.descC_ESTADO_CIVIL = newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? appConfigKey.keyDescripEstadoCivilCasado : appConfigKey.keyDescripEstadoCivilConviviente;
      mnPersonaConyugueRequest.inD_ESTADO = true;
      mnPersonaConyugueRequest.idenT_USUARIO = identUsuario;
      mnPersonaConyugueRequest.iD_NACIONALIDAD = newConyugeData.NacionalidadId;
      mnPersonaConyugueRequest.descL_EMAIL = newConyugeData.Correo;

      if (nroPaso >= 3) {
        mnPersonaConyugueRequest.personauid = newCondicionesFinanciamiento.conyugePersonaUId;
      }

      if (nroPaso >= 5) {
        if (newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
          // Persona jurídica no tiene cónyuge
        } else {
          mnPersonaConyugueRequest.iD_NACIONALIDAD = newDatosAdicionalesConyugeData.identNacionalidad;
          mnPersonaConyugueRequest.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newDatosAdicionalesConyugeData.identNacionalidad)?.description);
          mnPersonaConyugueRequest.iD_NIVEL_ESTUDIOS = newDatosAdicionalesConyugeData.identNivelEducacion;
          mnPersonaConyugueRequest.descC_NIVEL_ESTUDIOS = GeneralHelper.ParseString(listasTemp.listNivelEducacion.find((x: any) => x.id === newDatosAdicionalesConyugeData.identNivelEducacion)?.description);
          mnPersonaConyugueRequest.monT_NUMERO_HIJOS = newDatosAdicionalesConyugeData.nroHijos;
          mnPersonaConyugueRequest.monT_NUMERO_DEPENDIENTES = newDatosAdicionalesConyugeData.nroDependientes;
          mnPersonaConyugueRequest.inD_SEPARACION_BIENES = newTitularData.SeparacionBienes;
          mnPersonaConyugueRequest.iD_PROFESION = newDatosAdicionalesConyugeData.identProfesion;
          mnPersonaConyugueRequest.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === newDatosAdicionalesConyugeData.identProfesion)?.description);
          mnPersonaConyugueRequest.descL_NOMBRE_COMPLETO_CONTACTO1 = newDatosAdicionalesConyugeData.nomYApeContacto1;
          mnPersonaConyugueRequest.descL_NOMBRE_COMPLETO_CONTACTO2 = newDatosAdicionalesConyugeData.nomYApeContacto2;
          mnPersonaConyugueRequest.descC_NUMERO_CONTACTO1 = newDatosAdicionalesConyugeData.celular1;
          mnPersonaConyugueRequest.descC_NUMERO_CONTACTO2 = newDatosAdicionalesConyugeData.celular2;
          mnPersonaConyugueRequest.descL_EMAIL = newDatosAdicionalesConyugeData.correo;

          if (newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {
            mnPersonaConyugueRequest.idenT_CARGO_LABORAL = newDatosLaboralesAdicionalesConyugeData.identCargoLaboral;
            mnPersonaConyugueRequest.descL_DESCRIPCION_CARGO = newDatosLaboralesAdicionalesConyugeData.descripcionCargo;//pendiente
            mnPersonaConyugueRequest.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesConyugeData.identActividadEconomica; //Unificado
            mnPersonaConyugueRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesConyugeData.identActividadEconomica)?.description); //Unificado
          }
        }
      }
    }

    if (nroPaso >= 5) {
      if (newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
        mnPersonaTitularEmpresaAdicionalRequest = JsonHelper.crearJsonMnPersonaEmpresaAdicional();
        mnPersonaTitularEmpresaAdicionalRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR ?? appDefaultValues.NumberDefault;
        mnPersonaTitularEmpresaAdicionalRequest.codL_CODIGO_SBS = newDatosAdicionalesTitularEmpresaData.codSBS;
        mnPersonaTitularEmpresaAdicionalRequest.monT_CAPITAL_SOCIAL_ACTUAL = GeneralHelper.QuitarComasAMiles(newDatosAdicionalesTitularEmpresaData.capitalSocialActual);
        mnPersonaTitularEmpresaAdicionalRequest.monT_PATRIMONIO = GeneralHelper.QuitarComasAMiles(newDatosAdicionalesTitularEmpresaData.patrimonio);
        mnPersonaTitularEmpresaAdicionalRequest.monT_PATRIMONIO_PERSONA = GeneralHelper.QuitarComasAMiles(newDatosAdicionalesTitularEmpresaData.patrimonioPersonal);
        mnPersonaTitularEmpresaAdicionalRequest.descC_SECTOR_ECONOMICO = newDatosAdicionalesTitularEmpresaData.sectorEconomico;
        mnPersonaTitularEmpresaAdicionalRequest.descC_TIPO_CADENA_PRODUCTIVA = newDatosAdicionalesTitularEmpresaData.tipoCadenaProductiva;
        mnPersonaTitularEmpresaAdicionalRequest.descC_ACTIVIDAD_COMPLEMENTARIA = newDatosAdicionalesTitularEmpresaData.actividadComplementaria;
      } else {
        // Persona natural con negocio no tiene esta información
      }
    }

    mnPersonaTitularRequest.push({
      "mnPersonaRequest": mnPersonaRequest,
      "mnPersonaConyugueRequest": mnPersonaConyugueRequest,
      "mnPersonaEmpresaAdicionalRequest": mnPersonaTitularEmpresaAdicionalRequest,
    });

    if (GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '') {

      mnPersonaRequest_CompraPara = JsonHelper.crearJsonMnPersona();

      mnPersonaRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA ?? appDefaultValues.NumberDefault;

      mnPersonaRequest_CompraPara.iD_TIPO_DOCUMENTO = newPersonaCompraParaData.TipoDocumentoId;

      if (newPersonaCompraParaData.TipoDocumentoId === appConfigKey.keyIdRUC) {
        mnPersonaRequest_CompraPara.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaJuridica;
        mnPersonaRequest_CompraPara.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaJuridica;
        mnPersonaRequest_CompraPara.descL_NOMBRE_COMPLETO = newPersonaCompraParaData.RazonSocial;
      } else {
        mnPersonaRequest_CompraPara.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
        mnPersonaRequest_CompraPara.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
        mnPersonaRequest_CompraPara.descL_NOMBRE_COMPLETO = newPersonaCompraParaData.Nombres + " " + newPersonaCompraParaData.ApellidoPaterno + " " + newPersonaCompraParaData.ApellidoMaterno;
      }

      mnPersonaRequest_CompraPara.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newPersonaCompraParaData.TipoDocumentoId)?.description);
      mnPersonaRequest_CompraPara.codL_NUMERO_DOCUMENTO = newPersonaCompraParaData.NumeroDocumento;

      mnPersonaRequest_CompraPara.fecH_NACIMIENTO_CONSTITUCION = newPersonaCompraParaData?.FechaNacimiento;
      mnPersonaRequest_CompraPara.descL_APELLIDO_PATERNO = newPersonaCompraParaData?.ApellidoPaterno;
      mnPersonaRequest_CompraPara.descL_APELLIDO_MATERNO = newPersonaCompraParaData?.ApellidoMaterno;
      mnPersonaRequest_CompraPara.descL_NOMBRES = newPersonaCompraParaData?.Nombres;
      mnPersonaRequest_CompraPara.iD_GENERO = GeneralHelper.ParseGeneroString(newPersonaCompraParaData?.GeneroId, "M");
      mnPersonaRequest_CompraPara.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newPersonaCompraParaData.GeneroId)?.description);
      mnPersonaRequest_CompraPara.iD_NACIONALIDAD = newPersonaCompraParaData?.NacionalidadId;
      mnPersonaRequest_CompraPara.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newPersonaCompraParaData.NacionalidadId)?.description);
      mnPersonaRequest_CompraPara.iD_ESTADO_CIVIL = GeneralHelper.ParseNumber(newPersonaCompraParaData?.EstadoCivilId);
      mnPersonaRequest_CompraPara.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId))?.description);
      mnPersonaRequest_CompraPara.inD_ESTADO = true;
      mnPersonaRequest_CompraPara.iD_PAIS_RESIDENCIA = newPersonaCompraParaData?.PaisRecidenciaId;
      mnPersonaRequest_CompraPara.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === newPersonaCompraParaData.PaisRecidenciaId)?.description);
      mnPersonaRequest_CompraPara.idenT_USUARIO = identUsuario;

      if (nroPaso >= 3) {
        // mnPersonaRequest_CompraPara.clientuid = null;
        mnPersonaRequest_CompraPara.personauid = newCondicionesFinanciamiento.compraParaPersonaUId ?? appDefaultValues.NullDefault;;
      }

      if (
        (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado ||
        GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilConviviente) &&
        GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '' &&
        newPersonaCompraParaData.TipoDocumentoId !== appConfigKey.keyIdRUC
      ) {

        mnPersonaConyugueRequest_CompraPara = JsonHelper.crearJsonMnPersona();
        mnPersonaConyugueRequest_CompraPara.idenT_PERSONA = solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE ?? appDefaultValues.NumberDefault;
        mnPersonaConyugueRequest_CompraPara.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
        mnPersonaConyugueRequest_CompraPara.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
        mnPersonaConyugueRequest_CompraPara.iD_TIPO_DOCUMENTO = newPersonaCompraParaConyugeData.TipoDocumentoId;
        mnPersonaConyugueRequest_CompraPara.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === newPersonaCompraParaConyugeData.TipoDocumentoId)?.description);
        mnPersonaConyugueRequest_CompraPara.codL_NUMERO_DOCUMENTO = newPersonaCompraParaConyugeData.NumeroDocumento;
        mnPersonaConyugueRequest_CompraPara.descL_NOMBRE_COMPLETO = newPersonaCompraParaConyugeData.Nombres + " " + newPersonaCompraParaConyugeData.ApellidoPaterno + " " + newPersonaCompraParaConyugeData.ApellidoMaterno;
        mnPersonaConyugueRequest_CompraPara.fecH_NACIMIENTO_CONSTITUCION = newPersonaCompraParaConyugeData.FechaNacimiento;
        mnPersonaConyugueRequest_CompraPara.descL_APELLIDO_PATERNO = newPersonaCompraParaConyugeData.ApellidoPaterno;
        mnPersonaConyugueRequest_CompraPara.descL_APELLIDO_MATERNO = newPersonaCompraParaConyugeData.ApellidoMaterno;
        mnPersonaConyugueRequest_CompraPara.descL_NOMBRES = newPersonaCompraParaConyugeData.Nombres;
        mnPersonaConyugueRequest_CompraPara.iD_GENERO = GeneralHelper.ParseGeneroString(newPersonaCompraParaConyugeData.GeneroId);
        mnPersonaConyugueRequest_CompraPara.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === newPersonaCompraParaConyugeData.GeneroId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_MAGNITUD = newPersonaCompraParaConyugeData.MagnitudId;
        mnPersonaConyugueRequest_CompraPara.descC_MAGNITUD = GeneralHelper.ParseString(listasTemp.listMagnitud.find((x: any) => x.id === newPersonaCompraParaConyugeData.MagnitudId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_ACTIVIDAD_ECONOMICA = newPersonaCompraParaConyugeData.ActividadEconomicaId;
        mnPersonaConyugueRequest_CompraPara.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newPersonaCompraParaConyugeData.ActividadEconomicaId)?.description);
        mnPersonaConyugueRequest_CompraPara.iD_ESTADO_CIVIL = appConfigKey.keyIdEstadoCivilCasado;
        mnPersonaConyugueRequest_CompraPara.descC_ESTADO_CIVIL = appConfigKey.keyDescripEstadoCivilCasado;
        mnPersonaConyugueRequest_CompraPara.inD_ESTADO = true;
        mnPersonaConyugueRequest_CompraPara.idenT_USUARIO = identUsuario;

        if (nroPaso >= 3) {
          // mnPersonaConyugueRequest_CompraPara.clientuid = null;
          mnPersonaConyugueRequest_CompraPara.personauid = newCondicionesFinanciamiento.compraParaConyugePersonaUId ?? appDefaultValues.NullDefault;
        }
      }

      mnPersonaCompraParaRequest.push({
        "mnPersonaRequest": mnPersonaRequest_CompraPara,
        "mnPersonaConyugueRequest": mnPersonaConyugueRequest_CompraPara,
        "mnPersonaEmpresaAdicionalRequest": null,
      })

    }

    if (newFiadorData !== null && newFiadorData.ListaFiadores !== null) {
      if (newFiadorData.ListaFiadores.length > 0) {
        newFiadorData.ListaFiadores.map((rowFiador: any, index: any) => {
          mnPersonaRequest_Fiador = JsonHelper.crearJsonMnPersona();
          mnPersonaRequest_Fiador.idenT_PERSONA = rowFiador.PersonaId ?? appDefaultValues.NumberDefault;
          mnPersonaRequest_Fiador.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
          mnPersonaRequest_Fiador.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
          mnPersonaRequest_Fiador.iD_TIPO_DOCUMENTO = rowFiador.TipoDocumentoId;
          mnPersonaRequest_Fiador.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === rowFiador.TipoDocumentoId)?.description);
          mnPersonaRequest_Fiador.codL_NUMERO_DOCUMENTO = rowFiador.NumeroDocumento;
          mnPersonaRequest_Fiador.descL_NOMBRE_COMPLETO = rowFiador.Nombres + " " + rowFiador.ApellidoPaterno + " " + rowFiador.ApellidoMaterno;
          mnPersonaRequest_Fiador.fecH_NACIMIENTO_CONSTITUCION = rowFiador.FechaNacimiento;
          mnPersonaRequest_Fiador.descL_APELLIDO_PATERNO = rowFiador.ApellidoPaterno;
          mnPersonaRequest_Fiador.descL_APELLIDO_MATERNO = rowFiador.ApellidoMaterno;
          mnPersonaRequest_Fiador.descL_NOMBRES = rowFiador.Nombres;
          mnPersonaRequest_Fiador.iD_GENERO = GeneralHelper.ParseGeneroString(rowFiador?.GeneroId);
          mnPersonaRequest_Fiador.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === rowFiador.GeneroId)?.description);
          mnPersonaRequest_Fiador.iD_NACIONALIDAD = rowFiador.NacionalidadId;
          mnPersonaRequest_Fiador.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === rowFiador.NacionalidadId)?.description);
          mnPersonaRequest_Fiador.iD_PROFESION = rowFiador.ProfesionId;
          mnPersonaRequest_Fiador.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === rowFiador.ProfesionId)?.description);
          mnPersonaRequest_Fiador.iD_ESTADO_CIVIL = GeneralHelper.ParseNumber(rowFiador.EstadoCivilId);
          mnPersonaRequest_Fiador.descC_ESTADO_CIVIL = GeneralHelper.ParseString(listasTemp.listEstadoCivil.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.EstadoCivilId))?.description);
          mnPersonaRequest_Fiador.inD_ESTADO = true;
          mnPersonaRequest_Fiador.iD_PAIS_RESIDENCIA = rowFiador.PaisRecidenciaId;
          mnPersonaRequest_Fiador.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.PaisRecidenciaId))?.description);
          mnPersonaRequest_Fiador.idenT_USUARIO = identUsuario;


          if (rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
              rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {
            mnPersonaConyugueRequest_Fiador = JsonHelper.crearJsonMnPersona();
            mnPersonaConyugueRequest_Fiador.idenT_PERSONA = rowFiador.Conyuge.PersonaId ?? appDefaultValues.NumberDefault;
            mnPersonaConyugueRequest_Fiador.iD_TIPO_PERSONA = appConfigKey.keyIdPersonaNatural;
            mnPersonaConyugueRequest_Fiador.descC_TIPO_PERSONA = appConfigKey.keyDescripPersonaNatural;
            mnPersonaConyugueRequest_Fiador.iD_TIPO_DOCUMENTO = rowFiador.Conyuge.TipoDocumentoId;
            mnPersonaConyugueRequest_Fiador.descC_TIPO_DOCUMENTO = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === rowFiador.Conyuge.TipoDocumentoId)?.description);
            mnPersonaConyugueRequest_Fiador.codL_NUMERO_DOCUMENTO = rowFiador.Conyuge.NumeroDocumento;
            mnPersonaConyugueRequest_Fiador.descL_NOMBRE_COMPLETO = rowFiador.Conyuge.Nombres + " " + rowFiador.Conyuge.ApellidoPaterno + " " + rowFiador.Conyuge.ApellidoMaterno;
            mnPersonaConyugueRequest_Fiador.fecH_NACIMIENTO_CONSTITUCION = rowFiador.Conyuge.FechaNacimiento;
            mnPersonaConyugueRequest_Fiador.descL_APELLIDO_PATERNO = rowFiador.Conyuge.ApellidoPaterno;
            mnPersonaConyugueRequest_Fiador.descL_APELLIDO_MATERNO = rowFiador.Conyuge.ApellidoMaterno;
            mnPersonaConyugueRequest_Fiador.descL_NOMBRES = rowFiador.Conyuge.Nombres;
            mnPersonaConyugueRequest_Fiador.iD_GENERO = GeneralHelper.ParseGeneroString(rowFiador?.Conyuge?.GeneroId);
            mnPersonaConyugueRequest_Fiador.descC_GENERO = GeneralHelper.ParseString(listasTemp.listIdentidadesGenero.find((x: any) => x.id === rowFiador.Conyuge.GeneroId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_NACIONALIDAD = rowFiador.Conyuge.NacionalidadId;
            mnPersonaConyugueRequest_Fiador.descC_NACIONALIDAD = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === rowFiador.Conyuge.NacionalidadId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_PROFESION = rowFiador.Conyuge.ProfesionId;
            mnPersonaConyugueRequest_Fiador.descC_PROFESION = GeneralHelper.ParseString(listasTemp.listProfesion.find((x: any) => x.id === rowFiador.Conyuge.ProfesionId)?.description);
            mnPersonaConyugueRequest_Fiador.iD_ESTADO_CIVIL = appConfigKey.keyIdEstadoCivilCasado;
            mnPersonaConyugueRequest_Fiador.descC_ESTADO_CIVIL = appConfigKey.keyDescripEstadoCivilCasado;
            mnPersonaConyugueRequest_Fiador.inD_ESTADO = true;
            mnPersonaConyugueRequest_Fiador.iD_PAIS_RESIDENCIA = rowFiador.Conyuge.PaisRecidenciaId;
            mnPersonaConyugueRequest_Fiador.descL_PAIS_RESIDENCIA = GeneralHelper.ParseString(listasTemp.listPais.find((x: any) => x.id === GeneralHelper.ParseNumber(rowFiador.Conyuge.PaisRecidenciaId))?.description);
            mnPersonaConyugueRequest_Fiador.idenT_USUARIO = identUsuario;

          }
          mnPersonasFiadoresRequest.push(
            {
              "mnPersonaRequest": mnPersonaRequest_Fiador,
              "mnPersonaConyugueRequest": mnPersonaConyugueRequest_Fiador,
              "mnPersonaEmpresaAdicionalRequest": null,
            }
          )
        });
      }
    }

    mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
    mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
    mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaId ?? appDefaultValues.NumberDefault;
    mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = newIngresosTitularData.identCategoriaLaboral;
    mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosTitularData.identCategoriaLaboral)?.description);
    mnIngresoPersonaInsertUpdateRequest.inD_ANUALIZADO = GeneralHelper.ParseBoolean(newIngresosTitularData.anualizado);
    mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaIngresoLaboral);
    mnIngresoPersonaInsertUpdateRequest.iD_TIPO_MONEDA_INGRESO = newIngresosTitularData.identTipoMoneda;
    mnIngresoPersonaInsertUpdateRequest.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newIngresosTitularData.identTipoMoneda)?.description);
    mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_NETO = GeneralHelper.ParseNumber(newIngresosTitularData.ingresosNetos);
    mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(newIngresosTitularData.anualizado) ? GeneralHelper.ParseNumber(newIngresosTitularData.calculoAnualizado) : 0;
    mnIngresoPersonaInsertUpdateRequest.inD_INGRESO_PRINCIPAL = true;
    mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
    mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenId);
    mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenId)?.description) : '';
    mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;
    mnIngresoPersonaInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = newTitularData.ActividadEconomicaId; //Unificado
    mnIngresoPersonaInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newTitularData.ActividadEconomicaId)?.description); //Unificado

    mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
    mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaId ?? appDefaultValues.NumberDefault;
    mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = newIngresosTitularData.razonSocial;
    mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
    mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
    mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = newIngresosTitularData.ruc;
    mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
    mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;
    mnEmpresaEmpleadorInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = newTitularData.ActividadEconomicaId; //Unificado
    mnEmpresaEmpleadorInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newTitularData.ActividadEconomicaId)?.description); //Unificado

    if (nroPaso >= 5) {
      if (newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
        // Persona jurídica almacena la información de empresa en la tabla persona
      } else {
        mnIngresoPersonaInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesTitularData.identActividadEconomica; //Unificado
        mnIngresoPersonaInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesTitularData.identActividadEconomica)?.description); //Unificado
        mnEmpresaEmpleadorInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesTitularData.identActividadEconomica;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesTitularData.identActividadEconomica)?.description);
        mnEmpresaEmpleadorInsertUpdateRequest.descM_NOMBRE_VIA = newDatosLaboralesAdicionalesTitularData.nombreVia;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_NUMERO = newDatosLaboralesAdicionalesTitularData.numeroVia;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_INTERIOR_DEPARTAMENTO_PISO = newDatosLaboralesAdicionalesTitularData.intDptoPiso;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_MANZANA = newDatosLaboralesAdicionalesTitularData.manzana;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_LOTE = newDatosLaboralesAdicionalesTitularData.lote;
        mnEmpresaEmpleadorInsertUpdateRequest.descM_NOMBRE_AGRUPACION = newDatosLaboralesAdicionalesTitularData.nombreAgrupacion;
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_PAIS = newDatosLaboralesAdicionalesTitularData.identPais;
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_UBIGEO = newDatosLaboralesAdicionalesTitularData.identDistritos;
        mnEmpresaEmpleadorInsertUpdateRequest.descL_REFERENCIA = newDatosLaboralesAdicionalesTitularData.referencia;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_TELEFONO = newDatosLaboralesAdicionalesTitularData.telefono;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_ANEXOS = newDatosLaboralesAdicionalesTitularData.anexo;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_CORREO = newDatosLaboralesAdicionalesTitularData.correo;
      }
    }

    mnIngresosDetallesTitularRequest.push({
      "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
      "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest
    })

    if (GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior)) {

      mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
      mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
      mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
      mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = newIngresosTitularData.identCategoriaLaboralAnterior;
      mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosTitularData.identCategoriaLaboralAnterior)?.description);
      mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaIngresoLaboralAnterior);
      mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
      mnIngresoPersonaInsertUpdateRequest.inD_TRABAJO_ANTERIOR = GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior);
      mnIngresoPersonaInsertUpdateRequest.fecH_FIN_TRABAJO_ANTERIOR = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosTitularData.fechaHastaAnterior);
      mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenIdAnterior);
      mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosTitularData.regimenIdAnterior) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenIdAnterior)?.description) : '';
      mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;

      mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
      mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = newIngresosTitularData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
      mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = newIngresosTitularData.razonSocialAnterior;
      mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
      mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
      mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = newIngresosTitularData.rucAnterior;
      mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
      mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;

      mnIngresosDetallesTitularRequest.push({
        "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
        "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest
      });

    }

    if (newIngresosTitularData.listaOtrosTrabajos.length > 0) {
      newIngresosTitularData.listaOtrosTrabajos.map((rowOtroTrabajo: any, index: any) => {

        mnIngresoPersonaInsertUpdateRequest = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest.idenT_PERSONA = solicitudData.idenT_PERSONA_TITULAR;
        mnIngresoPersonaInsertUpdateRequest.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
        mnIngresoPersonaInsertUpdateRequest.iD_CATEGORIA_LABORAL = rowOtroTrabajo.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === rowOtroTrabajo.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest.inD_ANUALIZADO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado);
        mnIngresoPersonaInsertUpdateRequest.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', rowOtroTrabajo.fechaIngresoLaboral);
        mnIngresoPersonaInsertUpdateRequest.iD_TIPO_MONEDA_INGRESO = rowOtroTrabajo.identTipoMoneda;
        mnIngresoPersonaInsertUpdateRequest.descC_TIPO_MONEDA_INGRESO = listasTemp.listMoneda.find((x: any) => x.id === rowOtroTrabajo.identTipoMoneda).description;
        mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_NETO = GeneralHelper.ParseNumber(rowOtroTrabajo.ingresosNetos);
        mnIngresoPersonaInsertUpdateRequest.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado) ? GeneralHelper.ParseNumber(rowOtroTrabajo.calculoAnualizado) : 0;
        mnIngresoPersonaInsertUpdateRequest.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest.iD_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId);
        mnIngresoPersonaInsertUpdateRequest.descC_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosTitularData.regimenId)?.description) : '';
        mnIngresoPersonaInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
        mnIngresoPersonaInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
        mnIngresoPersonaInsertUpdateRequest.idenT_USUARIO = identUsuario;

        mnEmpresaEmpleadorInsertUpdateRequest = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
        mnEmpresaEmpleadorInsertUpdateRequest.descL_RAZON_SOCIAL_EMPRESA = rowOtroTrabajo.razonSocial;
        mnEmpresaEmpleadorInsertUpdateRequest.iD_TIPO_DOC = appConfigKey.keyIdRUC;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
        mnEmpresaEmpleadorInsertUpdateRequest.descL_NRO_DOC = rowOtroTrabajo.ruc;
        mnEmpresaEmpleadorInsertUpdateRequest.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
        mnEmpresaEmpleadorInsertUpdateRequest.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
        mnEmpresaEmpleadorInsertUpdateRequest.inD_ESTADO = true;
        mnEmpresaEmpleadorInsertUpdateRequest.idenT_USUARIO = identUsuario;

        mnIngresosDetallesTitularRequest.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest
        });

      });

    }

    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {
      if (newIngresosConyugeData.identCategoriaLaboral === appConfigKey.keyIdSinCategoria) {

        mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_INGRESO_PRINCIPAL = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

        mnIngresosDetallesConyugueRequest.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge
        });

      } else {

        mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaId ?? appDefaultValues.NumberDefault;
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboral;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboral)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ANUALIZADO = GeneralHelper.ParseBoolean(newIngresosConyugeData.anualizado);
        mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaIngresoLaboral);
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_TIPO_MONEDA_INGRESO = newIngresosConyugeData.identTipoMoneda;
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newIngresosConyugeData.identTipoMoneda)?.description);
        mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_NETO = GeneralHelper.ParseNumber(newIngresosConyugeData.ingresosNetos);
        mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(newIngresosConyugeData.anualizado) ? GeneralHelper.ParseNumber(newIngresosConyugeData.calculoAnualizado) : 0;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_INGRESO_PRINCIPAL = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenId);
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenId)?.description) : '';
        mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;
        mnIngresoPersonaInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = newConyugeData.ActividadEconomicaId; //Unificado
        mnIngresoPersonaInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newConyugeData.ActividadEconomicaId)?.description); //Unificado

        mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaId ?? appDefaultValues.NumberDefault;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = newIngresosConyugeData.razonSocial;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = newIngresosConyugeData.ruc;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = newConyugeData.ActividadEconomicaId; //Unificado
        mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newConyugeData.ActividadEconomicaId)?.description); //Unificado

        if (nroPaso >= 5) {
          if (newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
            // Persona jurídica no tiene cónyuge
          } else {
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesConyugeData.identActividadEconomica; //Unificado
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesConyugeData.identActividadEconomica)?.description); //Unificado

            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = newDatosLaboralesAdicionalesConyugeData.identActividadEconomica;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === newDatosLaboralesAdicionalesConyugeData.identActividadEconomica)?.description);
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descM_NOMBRE_VIA = newDatosLaboralesAdicionalesConyugeData.nombreVia;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_NUMERO = newDatosLaboralesAdicionalesConyugeData.numeroVia;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_INTERIOR_DEPARTAMENTO_PISO = newDatosLaboralesAdicionalesConyugeData.intDptoPiso;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_MANZANA = newDatosLaboralesAdicionalesConyugeData.manzana;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_LOTE = newDatosLaboralesAdicionalesConyugeData.lote;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descM_NOMBRE_AGRUPACION = newDatosLaboralesAdicionalesConyugeData.nombreAgrupacion;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_PAIS = newDatosLaboralesAdicionalesConyugeData.identPais;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_UBIGEO = newDatosLaboralesAdicionalesConyugeData.identDistritos;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_REFERENCIA = newDatosLaboralesAdicionalesConyugeData.referencia;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TELEFONO = newDatosLaboralesAdicionalesConyugeData.telefono;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_ANEXOS = newDatosLaboralesAdicionalesConyugeData.anexo;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_CORREO = newDatosLaboralesAdicionalesConyugeData.correo;
          }
        }
        mnIngresosDetallesConyugueRequest.push({
          "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
          "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge
        })

        if (GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior)) {

          mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
          mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = newIngresosConyugeData.identCategoriaLaboralAnterior;
          mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === newIngresosConyugeData.identCategoriaLaboralAnterior)?.description);
          mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaIngresoLaboralAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
          mnIngresoPersonaInsertUpdateRequest_conyuge.inD_TRABAJO_ANTERIOR = GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_FIN_TRABAJO_ANTERIOR = GeneralHelper.ParseDateString('YYYY-MM-DD', newIngresosConyugeData.fechaHastaAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenIdAnterior);
          mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(newIngresosConyugeData.regimenIdAnterior) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenIdAnterior)?.description) : '';
          mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

          mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = newIngresosConyugeData.empresaAnteriorId ?? appDefaultValues.NumberDefault;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = newIngresosConyugeData.razonSocialAnterior;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC).description;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = newIngresosConyugeData.rucAnterior;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
          mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

          mnIngresosDetallesConyugueRequest.push({
            "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
            "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge
          })
        }

        if (newIngresosConyugeData.listaOtrosTrabajos.length > 0) {
          newIngresosConyugeData.listaOtrosTrabajos.map((rowOtroTrabajo: any, index: any) => {
            mnIngresoPersonaInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnIngresoPersonaInsertUpdate();
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_PERSONA = solicitudData.idenT_PERSONA_CONYUGUE;
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_CATEGORIA_LABORAL = rowOtroTrabajo.identCategoriaLaboral;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_CATEGORIA_LABORAL = GeneralHelper.ParseString(listasTemp.listCategoriaLaboral.find((x: any) => x.id === rowOtroTrabajo.identCategoriaLaboral)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ANUALIZADO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado);
            mnIngresoPersonaInsertUpdateRequest_conyuge.fecH_INGRESO_EMPRESA = GeneralHelper.ParseDateString('YYYY-MM-DD', rowOtroTrabajo.fechaIngresoLaboral);
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_TIPO_MONEDA_INGRESO = rowOtroTrabajo.identTipoMoneda;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_TIPO_MONEDA_INGRESO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === rowOtroTrabajo.identTipoMoneda)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_NETO = GeneralHelper.ParseNumber(rowOtroTrabajo.ingresosNetos);
            mnIngresoPersonaInsertUpdateRequest_conyuge.monT_INGRESO_BRUTO = GeneralHelper.ParseBoolean(rowOtroTrabajo.anualizado) ? GeneralHelper.ParseNumber(rowOtroTrabajo.calculoAnualizado) : 0;
            mnIngresoPersonaInsertUpdateRequest_conyuge.inD_ESTADO = true;
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId);
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_REGIMEN = GeneralHelper.ParseNumber(rowOtroTrabajo.regimenId) > 0 ? GeneralHelper.ParseString(listasTemp.listRegimen.find((x: any) => x.id === newIngresosConyugeData.regimenId)?.description) : '';
            mnIngresoPersonaInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
            mnIngresoPersonaInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
            mnIngresoPersonaInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

            mnEmpresaEmpleadorInsertUpdateRequest_conyuge = JsonHelper.crearJsonMnEmpresaEmpleadorInsertUpdate();
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_EMPRESA = rowOtroTrabajo.empresaId ?? appDefaultValues.NumberDefault;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_RAZON_SOCIAL_EMPRESA = rowOtroTrabajo.razonSocial;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_TIPO_DOC = appConfigKey.keyIdRUC;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_TIPO_DOC = GeneralHelper.ParseString(listasTemp.listTipoDocumento.find((x: any) => x.id === appConfigKey.keyIdRUC)?.description);
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descL_NRO_DOC = rowOtroTrabajo.ruc;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.iD_ACTIVIDAD_ECONOMICA = rowOtroTrabajo.actividadEconomicaId;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.descC_ACTIVIDAD_ECONOMICA = GeneralHelper.ParseString(listasTemp.listActividadEconomica.find((x: any) => x.id === rowOtroTrabajo.actividadEconomicaId)?.description);
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.inD_ESTADO = true;
            mnEmpresaEmpleadorInsertUpdateRequest_conyuge.idenT_USUARIO = identUsuario;

            mnIngresosDetallesConyugueRequest.push({
              "mnIngresoPersonaInsertUpdateRequest": mnIngresoPersonaInsertUpdateRequest_conyuge,
              "mnEmpresaEmpleadorInsertUpdateRequest": mnEmpresaEmpleadorInsertUpdateRequest_conyuge
            });

          });
        }
      }

    }

    mnSolicitudInsertUpdateRequest = JsonHelper.crearJsonMnSolicitudInsertUpdateResquest();
    mnSolicitudInsertUpdateRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
    mnSolicitudInsertUpdateRequest.idenT_PERSONA_TITULAR = solicitudData.idenT_PERSONA_TITULAR;

    if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
      mnSolicitudInsertUpdateRequest.idenT_PERSONA_CONYUGUE = solicitudData.idenT_PERSONA_CONYUGUE;
    }

    mnSolicitudInsertUpdateRequest.descL_OBSERVACION = newObservacionData.Observaciones;
    mnSolicitudInsertUpdateRequest.inD_ESTADO = true;
    mnSolicitudInsertUpdateRequest.idenT_PERSONA_COMPRA_PARA = GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '' ?
      solicitudData.idenT_PERSONA_COMPRA_PARA : 0;
    mnSolicitudInsertUpdateRequest.idenT_PERSONA_COMPRA_PARA_CONYUGUE = GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado &&
      GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '' ?
      solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE : 0;
    mnSolicitudInsertUpdateRequest.idenT_USUARIO = identUsuario;

    if (nroPaso >= 2) {
      mnSolicitudInsertUpdateRequest.iD_ESTADO_VEHICULO_FINANCIAR = newDatosFinanciamiento.EstadoVehiculoId;
      mnSolicitudInsertUpdateRequest.descC_ESTADO_VEHICULO_FINANCIAR = GeneralHelper.ParseString(listasTemp.listaEstadosVehiculos.find((x: any) => x.id === newDatosFinanciamiento.EstadoVehiculoId)?.description);
      mnSolicitudInsertUpdateRequest.idenT_ANIO_VEHICULO = newDatosFinanciamiento.VehiculoYear;
      mnSolicitudInsertUpdateRequest.idenT_CONCESIONARIO = newDatosFinanciamiento.ConcesionarioId;
      mnSolicitudInsertUpdateRequest.descC_CONCESIONARIO = newDatosFinanciamiento.Concesionario;
      mnSolicitudInsertUpdateRequest.idenT_SUCURSAL = newDatosFinanciamiento.SucursalId;
      mnSolicitudInsertUpdateRequest.descC_SUCURSAL = newDatosFinanciamiento.Sucursal;
      mnSolicitudInsertUpdateRequest.idenT_VENDEDOR = newDatosFinanciamiento.VendedorId;
      mnSolicitudInsertUpdateRequest.descC_VENDEDOR = newDatosFinanciamiento.Vendedor;
      mnSolicitudInsertUpdateRequest.descL_EMAIL_VENDEDOR = newDatosFinanciamiento.VendedorEmail;
      mnSolicitudInsertUpdateRequest.monT_TIPO_CAMBIO = GeneralHelper.ParseNumber(newDatosFinanciamiento.TipoCambio);
      mnSolicitudInsertUpdateRequest.iD_TIPO_MONEDA_FINANCIAMIENTO = newDatosFinanciamiento.MonedaId;
      mnSolicitudInsertUpdateRequest.descC_TIPO_MONEDA_FINANCIAMIENTO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newDatosFinanciamiento.MonedaId)?.description);
      mnSolicitudInsertUpdateRequest.iD_TIPO_MONEDA_PRECIO_VEHICULO = appConfigKey.keyIdentificadorDolares;
      mnSolicitudInsertUpdateRequest.descC_TIPO_MONEDA_PRECIO_VEHICULO = appConfigKey.keyDescripcionDolares;
      mnSolicitudInsertUpdateRequest.monT_PRECIO_VEHICULO = GeneralHelper.ParseNumber(newDatosFinanciamiento.MontoVehiculoDolares);
      mnSolicitudInsertUpdateRequest.iD_TIPO_MONEDA_CUOTA_INICIAL = appConfigKey.keyIdentificadorDolares;
      mnSolicitudInsertUpdateRequest.descC_TIPO_MONEDA_CUOTA_INICIAL = appConfigKey.keyDescripcionDolares;
      mnSolicitudInsertUpdateRequest.monT_CUOTA_INICIAL = GeneralHelper.ParseNumber(newDatosFinanciamiento.MontoInicialDolares);
      mnSolicitudInsertUpdateRequest.iD_USO_VEHICULO = newDatosFinanciamiento.UsoVehiculoId;
      mnSolicitudInsertUpdateRequest.descC_USO_VEHICULO = newDatosFinanciamiento.UsosVehiculo;
      mnSolicitudInsertUpdateRequest.idenT_DESTINO_CREDITO = newDatosFinanciamiento.DestinoCreditoId;
      mnSolicitudInsertUpdateRequest.descC_DESTINO_CREDITO = newDatosFinanciamiento.DestinoCredito;
      mnSolicitudInsertUpdateRequest.idenT_MARCA = newDatosFinanciamiento.MarcaId;
      mnSolicitudInsertUpdateRequest.descC_MARCA = newDatosFinanciamiento.Marca;
      mnSolicitudInsertUpdateRequest.idenT_MODELO = newDatosFinanciamiento.ModeloId;
      mnSolicitudInsertUpdateRequest.descC_MODELO = newDatosFinanciamiento.Modelo;
      mnSolicitudInsertUpdateRequest.idenT_VERSION = newDatosFinanciamiento.VersionId;
      mnSolicitudInsertUpdateRequest.descC_VERSION = newDatosFinanciamiento.Version;
      mnSolicitudInsertUpdateRequest.inD_BONO = newDatosFinanciamiento.AplicaBono;
    }

    if (nroPaso >= 3) {
      mnSolicitudInsertUpdateRequest.idenT_PRODUCTO = newCondicionesFinanciamiento.identProducto;
      mnSolicitudInsertUpdateRequest.descC_PRODUCTO = newCondicionesFinanciamiento.Producto;
      mnSolicitudInsertUpdateRequest.inD_PROCESO_DERECHO_ENDOSO = newCondicionesFinanciamiento.esSeguroVehicularEndosado;
      mnSolicitudInsertUpdateRequest.idenT_SEGURO_VEHICULAR = newCondicionesFinanciamiento.identSeguroVehicular;
      mnSolicitudInsertUpdateRequest.descC_SEGURO_VEHICULAR = newCondicionesFinanciamiento.SeguroVehicular;
      mnSolicitudInsertUpdateRequest.indeT_PLAZO = GeneralHelper.ParseNumber(newCondicionesFinanciamiento.plazosMeses);
      mnSolicitudInsertUpdateRequest.descC_DIA_PAGO = newCondicionesFinanciamiento.diaPago;
      mnSolicitudInsertUpdateRequest.inD_CUOTAS_DOBLES = newCondicionesFinanciamiento.esCuotasDobles;
      mnSolicitudInsertUpdateRequest.iD_COBERTURA_SEGURO_DESGRAVAMENT = newCondicionesFinanciamiento.identTipoCoberturaDesg;
      mnSolicitudInsertUpdateRequest.descC_COBERTURA_SEGURO_DESGRAVAMENT = GeneralHelper.ParseString(listasTemp.listaTipoCoberturaDesg.find((x: any) => x.id === newCondicionesFinanciamiento.identTipoCoberturaDesg)?.description);
      mnSolicitudInsertUpdateRequest.idenT_PROVEEDOR_GPS = newCondicionesFinanciamiento.identProveedorGPS;
      mnSolicitudInsertUpdateRequest.descC_PROVEEDOR_GPS = GeneralHelper.ParseString(listasTemp.listProveedorGPS.find((x: any) => x.id === newCondicionesFinanciamiento.identProveedorGPS)?.description);
      mnSolicitudInsertUpdateRequest.idenT_CAMPANA = newCondicionesFinanciamiento.identCampania;
      mnSolicitudInsertUpdateRequest.descC_CAMPANA = GeneralHelper.ParseString(listasTemp.listaCampania.find((x: any) => x.id === newCondicionesFinanciamiento.identCampania)?.description);
      mnSolicitudInsertUpdateRequest.monT_TEA = GeneralHelper.ParseNumber(newCondicionesFinanciamiento.tea);
      mnSolicitudInsertUpdateRequest.derechO_ENDOSO = newCondicionesFinanciamiento.esInfDerechoEndosoProc;
      mnSolicitudInsertUpdateRequest.monT_PORCENTAJE_SEGURO_VEHICULAR = GeneralHelper.ParseNumber(newCondicionesFinanciamiento.costoSeguroVehicular);
      mnSolicitudInsertUpdateRequest.idenT_CAMPANIA_CI_PRECIO_TEA = newCondicionesFinanciamiento.identCIPrecio ? Number(newCondicionesFinanciamiento.identCIPrecio) : null;
      mnSolicitudInsertUpdateRequest.cuotA_BALLON = Number(newCondicionesFinanciamiento.CuotaBallon);
    }

    if (nroPaso >= 4) {
      let montoCalGasto: number = 0;
      //let montoCalFinanciamiento: number = 0;
      let comisNoFinanciadas: any = {};
      let mnSolicitudGastosInsert: any = null;
      let newRowCronograma: any = null;

      if (newCronograma !== null && newCronograma.listaCronograma !== null) {
        if (newCronograma.listaCronograma.length > 0) {
          for (let i = 0; i < newCronograma.listaCronograma.length; i++) {
            if (GeneralHelper.ParseNumber(newCondicionesFinanciamiento.plazosMeses) === GeneralHelper.ParseNumber(newCronograma.listaCronograma[i].cuota)) {
              montoCalGasto = GeneralHelper.ParseNumber(newCronograma.listaCronograma[i].mSeguro) +
                GeneralHelper.ParseNumber(newCronograma.listaCronograma[i].seguroDesg) +
                GeneralHelper.ParseNumber(newCronograma.listaCronograma[i].portes);
              break;
            }
          }
        }

      }

      if (newCronograma.listaCuotasPorPlazos.length > 0) {
        for (let i = 0; i < newCronograma.listaCuotasPorPlazos.length; i++) {
          if (GeneralHelper.ParseNumber(newCondicionesFinanciamiento.plazosMeses) === GeneralHelper.ParseNumber(newCronograma.listaCuotasPorPlazos[i].plazo)) {
            //montoCalFinanciamiento = newCondicionesFinanciamiento.esCuotasDobles === true ? GeneralHelper.ParseNumber(newCronograma.listaCuotasPorPlazos[i].montoDoble) : GeneralHelper.ParseNumber(newCronograma.listaCuotasPorPlazos[i].monto)
            comisNoFinanciadas = newCondicionesFinanciamiento.esCuotasDobles === true ? newCronograma.listaCuotasPorPlazos[i].comisNoFinanciadasDobles : newCronograma.listaCuotasPorPlazos[i].comisNoFinanciadasSimple;
            break;
          }
        }
      }

      mnSolicitudInsertUpdateRequest.monT_CALC_GASTOS = montoCalGasto;
      mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = newCronograma.totalFinanciamiento;
      mnSolicitudInsertUpdateRequest.idenT_OPERACION = newCronograma.operacionUId;
      mnSolicitudInsertUpdateRequest.tcea = tcea;

      if (!GeneralHelper.EsObjetoNullOrUndefined(comisNoFinanciadas)) {

        if (!GeneralHelper.EsObjetoNullOrUndefined(comisNoFinanciadas.sdtsBTComisionPrestamo)) {
          if (comisNoFinanciadas.sdtsBTComisionPrestamo.length > 0) {
            for (let i = 0; i < comisNoFinanciadas.sdtsBTComisionPrestamo.length; i++) {
              mnSolicitudGastosInsert = JsonHelper.crearJsonMnSolicitudGastosInsert();
              mnSolicitudGastosInsert.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
              mnSolicitudGastosInsert.iD_TIPO_GASTO = comisNoFinanciadas.sdtsBTComisionPrestamo[i].codigo;
              mnSolicitudGastosInsert.descC_TIPO_GASTO = comisNoFinanciadas.sdtsBTComisionPrestamo[i].descripcion;
              mnSolicitudGastosInsert.iD_TIPO_MONEDA_MONT_GASTO = newDatosFinanciamiento.MonedaId;
              mnSolicitudGastosInsert.descC_TIPO_MONEDA_MONT_GASTO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newDatosFinanciamiento.MonedaId)?.description);
              mnSolicitudGastosInsert.monT_GASTO = comisNoFinanciadas.sdtsBTComisionPrestamo[i].importe;
              mnSolicitudGastosInsert.inD_ESTADO = true;
              mnSolicitudGastosInsert.idenT_USUARIO = identUsuario;

              mnSolicitudGastosInsertRequest.push(mnSolicitudGastosInsert);
            }
          }
        }

      }

      if (newCronograma.listaComisionesCuotaCronoSel?.length > 0) {
        for (let i = 0; i < newCronograma.listaComisionesCuotaCronoSel.length; i++) {

          mnSolicitudGastosInsert = JsonHelper.crearJsonMnSolicitudGastosInsert();
          mnSolicitudGastosInsert.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
          mnSolicitudGastosInsert.iD_TIPO_GASTO = newCronograma.listaComisionesCuotaCronoSel[i].codigo;
          mnSolicitudGastosInsert.descC_TIPO_GASTO = newCronograma.listaComisionesCuotaCronoSel[i].descripcion;
          mnSolicitudGastosInsert.iD_TIPO_MONEDA_MONT_GASTO = newDatosFinanciamiento.MonedaId;
          mnSolicitudGastosInsert.descC_TIPO_MONEDA_MONT_GASTO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newDatosFinanciamiento.MonedaId)?.description);
          mnSolicitudGastosInsert.monT_GASTO = newCronograma.listaComisionesCuotaCronoSel[i].importe;
          mnSolicitudGastosInsert.inD_SELECCION = true;
          mnSolicitudGastosInsert.inD_ESTADO = true;
          mnSolicitudGastosInsert.idenT_USUARIO = identUsuario;

          mnSolicitudGastosInsertRequest.push(mnSolicitudGastosInsert);
        }
      }

      if (newCronograma.listaComisionesCronoSel.length > 0) {
        for (let i = 0; i < newCronograma.listaComisionesCronoSel.length; i++) {
          mnSolicitudGastosInsert = JsonHelper.crearJsonMnSolicitudGastosInsert();
          mnSolicitudGastosInsert.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
          mnSolicitudGastosInsert.iD_TIPO_GASTO = newCronograma.listaComisionesCronoSel[i].codigo;
          mnSolicitudGastosInsert.descC_TIPO_GASTO = newCronograma.listaComisionesCronoSel[i].descripcion;
          mnSolicitudGastosInsert.iD_TIPO_MONEDA_MONT_GASTO = newDatosFinanciamiento.MonedaId;
          mnSolicitudGastosInsert.descC_TIPO_MONEDA_MONT_GASTO = GeneralHelper.ParseString(listasTemp.listMoneda.find((x: any) => x.id === newDatosFinanciamiento.MonedaId)?.description);
          mnSolicitudGastosInsert.monT_GASTO = newCronograma.listaComisionesCronoSel[i].importe;
          mnSolicitudGastosInsert.inD_SELECCION = true;
          mnSolicitudGastosInsert.inD_ESTADO = true;
          mnSolicitudGastosInsert.idenT_USUARIO = identUsuario;

          mnSolicitudGastosInsertRequest.push(mnSolicitudGastosInsert);
        }
      }

      if (newCronograma !== null && newCronograma.listaCronograma !== null) {
        if (newCronograma.listaCronograma.length > 0) {
          newCronograma.listaCronograma.map((rowCronograma: any, index: any) => {
            newRowCronograma = JsonHelper.crearJsonMnSolicitudCronograma();
            newRowCronograma.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
            newRowCronograma.canT_NUMERO_CUOTA = GeneralHelper.ParseNumber(rowCronograma.cuota);
            newRowCronograma.fecH_VENCIMIENTO = GeneralHelper.ParseDateString('DD/MM/YYYY', rowCronograma.fVence);
            newRowCronograma.idenT_CANT_DIAS = GeneralHelper.ParseNumber(rowCronograma.dias);
            newRowCronograma.monT_SALDO_CAPITAL_INICIAL = GeneralHelper.ParseNumber(rowCronograma.saldoCapital);
            newRowCronograma.monT_SALDO_TOTAL_INICIAL = GeneralHelper.ParseNumber(rowCronograma.cuotaMonto);
            newRowCronograma.monT_TOTAL_AMORTIZACION = GeneralHelper.ParseNumber(rowCronograma.amortTotal);
            newRowCronograma.monT_AMORTIZACION = GeneralHelper.ParseNumber(rowCronograma.amort);
            newRowCronograma.monT_PORTES = GeneralHelper.ParseNumber(rowCronograma.portes);
            newRowCronograma.monT_TOTAL_CUOTA = GeneralHelper.ParseNumber(rowCronograma.pagoTotal);

            newRowCronograma.monT_INTERES = GeneralHelper.ParseNumber(rowCronograma.interes);
            newRowCronograma.monT_INTERES_CALCULADO = 0; // OBS
            newRowCronograma.monT_INTERES_DEVENGO = 0; // OBS
            newRowCronograma.monT_SEGURO = GeneralHelper.ParseNumber(rowCronograma.mSeguro);
            newRowCronograma.monT_SEGURO_DESGRAVAMEN = GeneralHelper.ParseNumber(rowCronograma.seguroDesg);

            newRowCronograma.inD_ESTADO = true;
            newRowCronograma.idenT_USUARIO = identUsuario;

            mnSolicitudCronogramaInsertRequest.push(newRowCronograma)
          });
        }
      }
    }

    return {
      "nroPaso": nroPaso,
      "identSolicitud": solicitudData.idenT_SOLICITUD,
      "identOperacionOld": solicitudData.idenT_OPERACION,
      "identUsuario": identUsuario,
      "mnPersonaTitularRequest": mnPersonaTitularRequest,
      "mnPersonaCompraParaRequest": mnPersonaCompraParaRequest,
      "mnPersonasFiadoresRequest": mnPersonasFiadoresRequest,
      "mnIngresosDetallesTitularRequest": mnIngresosDetallesTitularRequest,
      "mnIngresosDetallesConyugueRequest": mnIngresosDetallesConyugueRequest,
      "mnSolicitudInsertUpdateRequest": mnSolicitudInsertUpdateRequest,
      "mnSolicitudGastosInsertRequest": mnSolicitudGastosInsertRequest,
      "mnSolicitudCronogramaInsertRequest": mnSolicitudCronogramaInsertRequest,
    }

  },

  crearJsonRequestGenerateActualizacionSolicitudReconfirmacion: (
    nroPaso: number,
    solicitudData: any,
    estadoReconfirmacion: any,
    comentarioReconfirmacion: any,
    identUsuario: number
  ) => {


    let mnSolicitudInsertUpdateRequest = JsonHelper.crearJsonMnSolicitudInsertUpdateResquest();
    mnSolicitudInsertUpdateRequest.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
    mnSolicitudInsertUpdateRequest.iD_ESTADO_RECONFIRMACION = estadoReconfirmacion
    mnSolicitudInsertUpdateRequest.descL_COMENTARIO_RECONFIRMACION = comentarioReconfirmacion
    mnSolicitudInsertUpdateRequest.idenT_USUARIO = identUsuario;

    return {
      "nroPaso": nroPaso,
      "identSolicitud": solicitudData.idenT_SOLICITUD,
      "identOperacionOld": solicitudData.idenT_OPERACION,
      "identUsuario": identUsuario,
      "mnPersonaTitularRequest": null,
      "mnPersonaCompraParaRequest": null,
      "mnPersonasFiadoresRequest": null,
      "mnIngresosDetallesTitularRequest": null,
      "mnIngresosDetallesConyugueRequest": null,
      "mnSolicitudInsertUpdateRequest": mnSolicitudInsertUpdateRequest,
      "mnSolicitudGastosInsertRequest": null,
      "mnSolicitudCronogramaInsertRequest": null
    }

  },

  crearJsonOtroIngreso: () => {
    return {
      "categoria_Laboral": appDefaultValues.NumberDefault,
      "ruc": appDefaultValues.StringEmpty,
      "fecha_Inicio_Actividad": appDefaultValues.StringEmpty,
      "regimen_tributario": null,
      "moneda": appDefaultValues.NumberDefault,
      "ingreso_Mensual": appDefaultValues.NumberDefault,
      "ingreso_Anualizado": appDefaultValues.NumberDefault
    }
  },

  crearJsonRequestModellicaMotorEvaluacion: () => {
    return {
      "request": {
        "parametros_Globales": {
          "canal": appCanalMotorConfig.Santander,
          "aplicacion": appAplicacionMotorConfig.Santander,
          "tipo_Identificador": appDefaultValues.NumberDefault,
          "identificador": appDefaultValues.StringEmpty,
          "tipo_Evaluacion": appDefaultValues.NumberDefault,
          "tipo_llamada": appDefaultValues.NumberDefault,
        },
        "datos_Evaluacion": {
          "datos_cliente": {
            "tipo_documento": appDefaultValues.NumberDefault,
            "numero_documento": appDefaultValues.StringEmpty,
            "genero": appDefaultValues.NumberDefault,
            "estado_civil": appDefaultValues.NumberDefault,
            "fecha_nacimiento": appDefaultValues.StringEmpty,
            "actividad_Economica": appDefaultValues.NumberDefault,
            "magnitud": appDefaultValues.NumberDefault,
            "domicilio": {
              "departamento": appDefaultValues.StringEmpty,
              "provincia": appDefaultValues.StringEmpty,
              "distrito": appDefaultValues.StringEmpty,
              "ubigeo": appDefaultValues.StringEmpty,
              "dirección": appDefaultValues.StringEmpty
            }
          },
          "datos_conyugue": {
            "tipo_documento": appDefaultValues.NumberDefault,
            "numero_documento": appDefaultValues.StringEmpty,
            "genero": appDefaultValues.NumberDefault,
            "fecha_nacimiento": appDefaultValues.StringEmpty
          },
          "ingreso_declarado": {
            "titular": {
              "categoria_Laboral": appDefaultValues.NumberDefault,
              "ruc": appDefaultValues.StringEmpty,
              "fecha_Inicio_Actividad": appDefaultValues.StringEmpty,
              "moneda": appDefaultValues.NumberDefault,
              "ingreso_Mensual": appDefaultValues.NumberDefault,
              "ingreso_Anualizado": appDefaultValues.NumberDefault,
              "otros_Ingresos": appDefaultValues.ArrayDefault
            },
            "conyugue": {
              "categoria_Laboral": appDefaultValues.NumberDefault,
              "ruc": appDefaultValues.StringEmpty,
              "fecha_Inicio_Actividad": appDefaultValues.StringEmpty,
              "moneda": appDefaultValues.NumberDefault,
              "ingreso_Mensual": appDefaultValues.NumberDefault,
              "ingreso_Anualizado": appDefaultValues.NumberDefault,
              "otros_Ingresos": appDefaultValues.ArrayDefault
            }
          },
          "datos_canal": {
            "concesionario": appDefaultValues.StringEmpty,
            "sucursal": appDefaultValues.StringEmpty,
            "vendedor": appDefaultValues.StringEmpty,
            "ejecutivo": appDefaultValues.StringEmpty
          },
          "datos_financiamiento": {
            "moneda_financiamiento": appDefaultValues.NumberDefault,
            "tipo_cambio": appDefaultValues.NumberDefault,
            "aliado": appDefaultValues.NumberDefault,
            "valor_vehiculo_USD": appDefaultValues.NumberDefault,
            "marca": appDefaultValues.StringEmpty,
            "modelo": appDefaultValues.StringEmpty,
            "version": appDefaultValues.StringEmpty,
            "tipo_GPS": appDefaultValues.NumberDefault,
            "segmento": appDefaultValues.NumberDefault,
            "tipo_vehiculo": appDefaultValues.NumberDefault,
            "ano_fabricacion": appDefaultValues.StringEmpty,
            "cuota_inicial": {
              "moneda": appDefaultValues.NumberDefault,
              "monto": appDefaultValues.NumberDefault,
              "porcentaje": appDefaultValues.NumberDefault
            },
            "bono": {
              "flag_Bono": 1,
              "moneda": appDefaultValues.NumberDefault,
              "monto": appDefaultValues.NumberDefault,
              "tipo_Bono": appDefaultValues.NumberDefault,
              "campana_comercial": appDefaultValues.StringEmpty,
            },
            "gastos": {
              "total_gastos": appDefaultValues.NumberDefault,
              "detalle_gastos": [
                {
                  "codigo": appDefaultValues.NumberDefault,
                  "descripcion": appDefaultValues.StringEmpty,
                  "monto": appDefaultValues.NumberDefault
                }
              ]
            },
            "monto_a_financiar": appDefaultValues.NumberDefault,
            "seguros": {
              "seguro_vehicular": {
                "compañia": appDefaultValues.StringEmpty,
                "tasa": appDefaultValues.StringEmpty
              },
              "seguro_desgravamen": {
                "compañia": appDefaultValues.StringEmpty,
                "tasa": appDefaultValues.StringEmpty
              }
            }
          },
          "modificacion_Deudas_Externas": {
            "flag_Modificacion_Deudas_Externas": 0,
            "deudas_Titular": {
              "lineas": {
                "linea_original": {
                  "monto": 0
                },
                "linea_modificada": {
                  "monto": 0
                }
              },
              "deudas": {
                "deuda_original": [],
                "deuda_modificada": []
              }
            },
            "deudas_Conyugue": {
              "lineas": {
                "linea_original": {
                  "monto": 0
                },
                "linea_modificada": {
                  "monto": 0
                }
              },
              "deudas": {
                "deuda_original": [],
                "deuda_modificada": []
              }
            }
          },
          "producto": appDefaultValues.NumberDefault
        }

      }
    }
  },

  crearJsonSolicitudPersonaPorValidar: (
    solicitudData: any,
    usuarioId: any,
    miembros: ValidationMemberStepOneDataInteface[],
  ) => {
    let request = new TMnSolicitudPersonaPorValidarRequest();
    let miembrosRequest = miembros.map((m) => {
      let miembro = new SolicitudPersonaPorValidarType();
      miembro.idenT_TIPO_RELACION = m.TipoRelacionId;
      miembro.iD_TIPO_DOCUMENTO = m.TipoDocumento;
      miembro.codL_NUMERO_DOCUMENTO = m.NumeroDocumento;
      return miembro;
    });

    request.idenT_SOLICITUD = solicitudData?.idenT_SOLICITUD;
    request.idenT_USUARIO = usuarioId;
    request.tbL_SOLICITUD_PERSONA_POR_VALIDAR = miembrosRequest;

    return request;
  },

  crearJsonCotizacionSimulacionRequest: (state: any,): GetCotizacionSimulacionRequest => {
    let cronogramaList: any[] = []
    let cuota: number = 0;
    let fechaPrimerVencimiento: string = "";
    const primerRegistro = state.resuletList?.find((row: any) => {
      const fechaVencimiento = new Date(row.fVence);
      const mes = fechaVencimiento.getMonth() + 1; // Los meses en JavaScript son indexados desde 0
      return mes !== 1 && mes !== 8; // No considerar enero y agosto
    });
    if (primerRegistro) {
      cuota = Number(GeneralHelper.QuitarComasAMiles(primerRegistro.pagoTotal));
      fechaPrimerVencimiento = state.resuletList[0].fVence;
    }
    state.resuletList?.forEach((row: any) => {
      cronogramaList.push(
        {
          nroCuota: Number(row.cuota),
          fechaPago: row.fVence,
          saldoCapital: Number(GeneralHelper.QuitarComasAMiles(row.saldoCapital)),
          amortizacion: Number(GeneralHelper.QuitarComasAMiles(row.amort)),
          seguro: Number(GeneralHelper.QuitarComasAMiles(row.mSeguro)),
          interes: Number(GeneralHelper.QuitarComasAMiles(row.interes)),
          seguroDesgravamen: Number(GeneralHelper.QuitarComasAMiles(row.seguroDesg)),
          portes: Number(GeneralHelper.QuitarComasAMiles(row.portes)),
          pagoTotal: Number(GeneralHelper.QuitarComasAMiles(row.pagoTotal)),
        }
      );
    });
    return {
      cotizacionId: state.quotationIdentificador,
      cliente: {
        documento: state.nroDocumentoTitular
      },
      vehiculo: {
        estado: state.descripEstadoVehiculo,
        marca: state.descripMarca,
        modelo: state.descripModelo,
        concesionario: state.concesionario,
        sucursal: state.descripSucursal,
        monto: Number(GeneralHelper.QuitarComasAMiles(state.montoVehiculoDolares)),
        moneda: appConfigKey.keyIdentificadorDolares,
      },
      financiamiento: {
        producto: state.descripProducto,
        cuotaInicial: Number(GeneralHelper.QuitarComasAMiles(state.montoInicialDolares)),
        CuotaInicialMoneda: appConfigKey.keyIdentificadorDolares,
        seguroVehicular: state.descripSeguroVehicular ?? appDefaultValues.StringEmpty,
        tea: Number(state.nuevaTEA),
        fechaPrimerVencimiento: fechaPrimerVencimiento,
        cuotaDoble: state.esCuotasDobles,
        cuota: cuota,
        plazo: Number(state.selectedPlazoMeses),
        monto: Number(state.totalFinanciamiento),
        moneda: Number(state.selectedTipoMonedaFinanciamiento),
        tipoCambio: Number(state.tipoCambioFinanciamiento),
        tcea: Number(GeneralHelper.QuitarPorcentaje(state.TCEA)),
        desgravamen: state.descripCoberturaDesgravamen
      },
      cronograma: cronogramaList
    } as GetCotizacionSimulacionRequest;
  },
  
  crearJsonUpdateMnFormatoRegimen: (
    newIngresosTitularData: any,
    identUsuario: number,
  ) => {
    let request = new MnFormatoRegimenUpdateRequest();
    request.identFormatoRegistro = newIngresosTitularData?.matrizRegimen?.identFormatoRegistro ?? 0;
    request.jsonTable = JSON.stringify(newIngresosTitularData?.matrizRegimen?.matrizGeneral);
    request.identUsuario = identUsuario;
    return request;
  },

}

export default JsonHelper;