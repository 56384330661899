
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import SesionState from "../../../1.-shared/1.2.-layout/1.2.4-sessionState/sessionState";
import "./cardTitleStart.scss";

function CardTitleStart(props: any) {
    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card card-title-start">
            <div className="row g-2 mb-2 align-items-center">

                {(props.identSolicitud != undefined) ?
                    (<div className="col-lg-9">
                        <span className="fw-bold cardTextTop cardTop">{props.Ventana}&nbsp;</span>
                        <span className="cardTextTop">&nbsp;{props.identSolicitud}</span>
                    </div>) :
                    (<div className="col-lg-9">
                        <span className="fw-bold cardTextTop">{props.Ventana}&nbsp;</span>
                        <span></span>
                    </div>)}
                <div className="col-lg-3 displayProfile">
                    <SesionState />
                </div>
            </div>
        </div>
    );
};

export default CardTitleStart;

