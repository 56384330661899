import { appDefaultValues } from "../../config/Config";
import { MotorTipoMoneda } from "../constant/MotorTipoMoneda";
import { DatoAdicionalMotor } from "../modules/1.-shared/1.7.-component/1.7.5.-card/detailsAdditionalMotorCard/interfaces";
import GeneralHelper from "./GeneralHelper";
import TimeHelper from "./TimeHelper";

export const obtenerTipoMoneda = (tipoMonedaCore: any) => {

  const numberTipoMonedaCore = Number(tipoMonedaCore);

  const motorTipoMoneda = MotorTipoMoneda.find((item: any) => item.TipoMonedaCoreUid === numberTipoMonedaCore);

  if (motorTipoMoneda === undefined) {
    return 0;
  }

  return motorTipoMoneda.TipoMonedaUid;

};

export class DatosAdicionalMotorTipo {
  static readonly VALOR_TIPO_INT = "int";
  static readonly VALOR_TIPO_DECIMAL = "decimal";
  static readonly VALOR_TIPO_TEXT = "text";
  static readonly VALOR_TIPO_DATE = "date";
}

export const obtenerDatosAdicionalesMotor = (...responses: any[]): DatoAdicionalMotor[] | null => {
  const datosUnificados = new Map<string, DatoAdicionalMotor>();

  const obtenerDatoAdicional = (item: {
    variable: string;
    valor_int?: number | null | undefined;
    valor_decimal?: number | null | undefined;
    valor_text?: string | null | undefined;
    valor_date?: string | null | undefined;
    flag_visible: boolean;
  }): DatoAdicionalMotor => {
    let datoAdicional: DatoAdicionalMotor = {
      variable: item.variable,
      valor: null,
      valorTipo: null,
      visible: item.flag_visible,
    }

    if (item.valor_int !== null && item.valor_int !== undefined) {
      datoAdicional.valor = item.valor_int;
      datoAdicional.valorTipo = DatosAdicionalMotorTipo.VALOR_TIPO_INT;
    } else if (item.valor_decimal !== null && item.valor_decimal !== undefined) {
      datoAdicional.valor = item.valor_decimal;
      datoAdicional.valorTipo = DatosAdicionalMotorTipo.VALOR_TIPO_DECIMAL;
    } else if (item.valor_text !== null && item.valor_text !== undefined) {
      datoAdicional.valor = item.valor_text;
      datoAdicional.valorTipo = DatosAdicionalMotorTipo.VALOR_TIPO_TEXT;
    } else if (item.valor_date !== null && item.valor_date !== undefined) {
      datoAdicional.valor = item.valor_date;
      datoAdicional.valorTipo = DatosAdicionalMotorTipo.VALOR_TIPO_DATE;
    }

    return datoAdicional;
  }

  responses.forEach(response => {
    response?.datos_adicionales?.forEach((item: any) => {
      if (!datosUnificados.has(item.variable)) {
        datosUnificados.set(item.variable, obtenerDatoAdicional(item));
      }
    });
  });

  return datosUnificados.size > 0 ? Array.from(datosUnificados.values()) : null;
}

export const obtenerValorDatoAdicional = (dato: DatoAdicionalMotor | null): string => {
  if (!dato) return appDefaultValues.StringEmpty;
  const { valor, valorTipo } = dato;
  if (valor === null || valor === undefined) return appDefaultValues.StringEmpty;

  if (valorTipo === DatosAdicionalMotorTipo.VALOR_TIPO_INT) {
    return valor.toString();
  }
  else if (valorTipo === DatosAdicionalMotorTipo.VALOR_TIPO_DECIMAL) {
    return GeneralHelper.AgregarComasAMilesDecimales(valor);
  }
  else if (valorTipo === DatosAdicionalMotorTipo.VALOR_TIPO_TEXT) {
    return valor as string;
  }
  else if (valorTipo === DatosAdicionalMotorTipo.VALOR_TIPO_DATE) {
    return TimeHelper.ObtenerFormatoFechaPeru(valor as string);
  }

  return appDefaultValues.StringEmpty;
}