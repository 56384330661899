import {
  createContext,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { usePreQuotationSteps } from "./preQuotationStepsProvider";
import {
  IDatosCliente,
  IDatosCondicionesFinanciamiento,
  IDatosConyuge,
  getDatosConyugeDefault,
} from "../interfaces/PreQuotationStepOneInterfaces";
import { appConfigKey, appDefaultValues } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import WindowHelper from "../../../../helpers/WindowHelper";

interface PreQuotationStepOneContextType {
  datosCliente: IDatosCliente;
  datosConyuge: IDatosConyuge | null;
  datosCondicionesFinanciamiento: IDatosCondicionesFinanciamiento;
  setDatosCliente: (partialData: Partial<IDatosCliente>) => void;
  setDatosConyuge: (partialData: Partial<IDatosConyuge> | null) => void;
  setDatosCondicionesFinanciamiento: (partialData: Partial<IDatosCondicionesFinanciamiento>) => void;
}

export const PreQuotationStepOneContext = createContext<PreQuotationStepOneContextType | null>(null);

interface PreQuotationStepOneProviderProps {
  children: ReactNode;
}

export const PreQuotationStepOneProvider = ({ children }: PreQuotationStepOneProviderProps) => {

  const { viewModelStepOne, setViewModelStepOne } = usePreQuotationSteps();

  // Titular
  /**
   * Evento que se ejecuta cuando cambia el estado civil del titular.
   * Si el estado civil es "Casado" o "Conviviente", se crea un conyuge con los valores por defecto.
   * Sino, se borra el conyuge.
   */
  useEffect(() => {
    const estadoCivil = viewModelStepOne.datosCliente.estadoCivilId;
    const conyugeValue = [
      appConfigKey.keyIdEstadoCivilCasado,
      appConfigKey.keyIdEstadoCivilConviviente,
    ].includes(estadoCivil)
      ? getDatosConyugeDefault()
      : appDefaultValues.NullDefault;

    if (viewModelStepOne.datosConyuge !== conyugeValue) {
      setDatosConyuge(conyugeValue);
    }
  }, [viewModelStepOne.datosCliente.estadoCivilId]);

  /**
   * Calcula los ingresos anualizados del titular
   */
  useEffect(() => {
    const { esAnualizado, ingresosNetos } = viewModelStepOne.datosCliente;

    const ingresosAnualizadosNew = esAnualizado
      ? GeneralHelper.CalcularAnulizado(ingresosNetos)
      : appDefaultValues.StringEmpty;

    setDatosCliente({ ingresosAnualizados: ingresosAnualizadosNew });
  }, [
    viewModelStepOne.datosCliente.esAnualizado,
    viewModelStepOne.datosCliente.ingresosNetos,
  ]);


  // Conyuge
  /**
  * Calcula los ingresos anualizados del conyuge
  */
  useEffect(() => {
    const { datosConyuge } = viewModelStepOne;
    if (!datosConyuge) return;

    const ingresosAnualizados = datosConyuge.esAnualizado
      ? GeneralHelper.CalcularAnulizado(datosConyuge.ingresosNetos)
      : appDefaultValues.StringEmpty;

    setDatosConyuge({ ...datosConyuge, ingresosAnualizados });
  }, [
    viewModelStepOne.datosConyuge?.esAnualizado,
    viewModelStepOne.datosConyuge?.ingresosNetos,
  ]);

  // Condiciones de financiamiento
  /**
   * Evento que se ejecuta cuando cambian los valores de "Monto de vehículo" y "Porcentaje de financiamiento".
   * Asigna los valores de "Monto inicial" y "Monto a financiar".
   */
  useEffect(() => {
    const { montoVehiculo, porcentajeInicial } = viewModelStepOne.datosCondicionesFinanciamiento;

    const montoVehiculoTemp = Number(GeneralHelper.QuitarComasAMiles(montoVehiculo));
    const porcentajeInicialTemp = Number(GeneralHelper.QuitarComasAMiles(porcentajeInicial));

    const montoInicialNew = (montoVehiculoTemp * porcentajeInicialTemp) / 100;
    const montoFinanciarNew = montoVehiculoTemp - montoInicialNew;

    setDatosCondicionesFinanciamiento({
      montoInicial: GeneralHelper.AgregarComasAMiles(montoInicialNew.toFixed(2)),
      montoFinanciar: GeneralHelper.AgregarComasAMiles(montoFinanciarNew.toFixed(2)),
    });
  }, [
    viewModelStepOne.datosCondicionesFinanciamiento.montoVehiculo,
    viewModelStepOne.datosCondicionesFinanciamiento.porcentajeInicial,
  ]);

  /**
   * Envoltorio para actualizar campos de DatosCliente
   */
  const setDatosCliente = useCallback((partialData: Partial<IDatosCliente>) => {
    setViewModelStepOne((prev) => ({ ...prev, datosCliente: { ...prev.datosCliente, ...partialData } }));
  }, []);

  /**
   * Envoltorio para actualizar campos de DatosConyuge
   */
  const setDatosConyuge = useCallback((partialData: Partial<IDatosConyuge> | null) => {
    setViewModelStepOne((prev) => ({ ...prev, datosConyuge: partialData ? { ...prev.datosConyuge ?? getDatosConyugeDefault(), ...partialData } : null }));
  }, []);

  /**
   * Envoltorio para actualizar campos de DatosCondicionesFinanciamiento
   */
  const setDatosCondicionesFinanciamiento = useCallback(
    (partialData: Partial<IDatosCondicionesFinanciamiento>) => {
      setViewModelStepOne((prev) => ({ ...prev, datosCondicionesFinanciamiento: { ...prev.datosCondicionesFinanciamiento, ...partialData } }));
    },
    []
  );

  useEffect(() => {
    WindowHelper.ScrollToTop();
  }, []);

  const contextValue = {
    datosCliente: viewModelStepOne.datosCliente,
    datosConyuge: viewModelStepOne.datosConyuge,
    datosCondicionesFinanciamiento: viewModelStepOne.datosCondicionesFinanciamiento,
    setDatosCliente,
    setDatosConyuge,
    setDatosCondicionesFinanciamiento,
  };

  return (
    <PreQuotationStepOneContext.Provider value={contextValue}>
      {children}
    </PreQuotationStepOneContext.Provider>
  );
};

export const usePreQuotationStepOne = (): PreQuotationStepOneContextType => {
  const context = useContext(PreQuotationStepOneContext);
  if (!context) {
    throw new Error("usePreQuotationStepOne debe usarse dentro de PreQuotationStepOneProvider");
  }
  return context;
};
