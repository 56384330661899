import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { General, ObtenerListas } from "../obtenerServicios";
import { appConfigEstadoSolicitud, appConfigKey, appConfigPerfiles, appDefaultValues, appSecurityConfiguration } from "../../../../../config/Config";
import WindowHelper from "../../../../helpers/WindowHelper";
import ValidacionStepsHelper from "../helpers/ValidacionStepsHelper";
import JsonHelper from "../../../../helpers/JsonHelper";
import RequestManager from "../../../../services/origination/wapirequest.service";
import { toast } from "sonner";
import { TMnReiniciarDatosSolicitudRequest } from "../../../../models/RequestManager/solicitud-request.model";
import SolicitudPersonaObservadaHelper from "../../../1.-shared/1.5.-observed/1.5.9.-helpers/SolicitudPersonaObservadaHelper";
import MontoSolicitudHelper from "../helpers/MontoSolicitudHelper";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { TMnSolicitudAsociadoRequest } from "../../../../models/MasterManager/solicitud-asociado-request.model";
import { TMnSolicitudAsociadoAuditDto } from "../../../../models/MasterManager/solicitud-asociado-response.model";
import MasterManager from "../../../../services/origination/wapimaster.service";

export const StepsContext = createContext<any>({});

export const StepsProvider = ({ children }: any) => {

  const navigate = useNavigate();

  const { solicitudRow } = useLocation()?.state ?? {};

  const [loadingSteps, setLoadingSteps] = useState(false);

  const [retrocedio, setRetrocedio] = useState(false);

  const [retrocedioAlPrimerPaso, setRetrocedioAlPrimerPaso] = useState(false);
  const [retrocedioAlSegundoPaso, setRetrocedioAlSegundoPaso] = useState(false);
  const [retrocedioAlTercerPaso, setRetrocedioAlTercePaso] = useState(false);
  const [retrocedioAlQuintoPaso, setRetrocedioAlQuintoPaso] = useState(false);

  const { solicitudId, showResult } = useParams();
  const [step, setStep] = useState(showResult !== undefined ? 6 : 1);
  const [messageCustomerIsBlackListGesintel, setMessageCustomerIsBlackListGesintel] = useState<string | null>(null);
  const [detailCustomerIsBlackListGesintel, setDetailCustomerIsBlackListGesintel] = useState<string[] | null>(null);
  const [readonlyForm, setReadonlyForm] = useState<boolean>(false);
  const [canResetForm, setCanResetForm] = useState<boolean>(false);
  const [canSendForEvaluation, setCanSendForEvaluation] = useState<boolean>(false);
  const [updateSolicitudValue, setUpdateSolicitudValue] = useState<number>(0);

  const [solicitudData, setSolicitudData] = useState<any>(0);
  const [solicitudDataActualizado, setSolicitudDataActualizado] = useState<any>(0);

  const [requestTitularIngresos, setRequestTitularIngresos] = useState<any>(null);
  const [requestConyugeIngresos, setRequestConyugeIngresos] = useState<any>(null);

  const [clickNext, setClickNext] = useState(0);
  const [btnContinuarLoading, setBtnContinuarLoading] = useState(false);

  const [TCEA, setTCEA] = useState(appDefaultValues.NumberDefault);

  const [listTipoDocumento, setListTipoDocumento] = useState([]);
  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [listIdentidadesGenero, setListIdentidadesGenero] = useState([]);
  const [listMagnitud, setListMagnitud] = useState([]);
  const [listActividadEconomica, setListActividadEconomica] = useState([]);
  const [listCategoriaLaboral, setListCategoriaLaboral] = useState([]);
  const [listRegimen, setListRegimen] = useState([]);
  const [listRangoVentaAnual, setRangoVentaAnual] = useState([]);
  const [listGiroNegocio, setGiroNegocio] = useState([]);
  const [listMoneda, setListMoneda] = useState([]);
  const [listProfesion, setListProfesion] = useState([]);
  const [listPais, setListPais] = useState([]);
  const [listCIPrecio, setListCIPrecio] = useState([]);
  const [listProveedorGPS, setListProveedorGPS] = useState([]);

  const [listaEstadosVehiculos, setListaEstadosVehiculos] = useState([]);
  const [listaVehiculoYears, setListaVehiculoYears] = useState([]);
  const [listaConcesionarios, setListaConcesionarios] = useState([]);
  const [listaSucursales, setListaSucursales] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [listaUsosVehiculo, setListaUsosVehiculo] = useState([]);
  const [listaMarcas, setListaMarcas] = useState([]);
  const [listaModelos, setListaModelos] = useState([]);
  const [listaVersiones, setListaVersiones] = useState([]);
  const [listaDestinoCredito, setListaDestinoCredito] = useState([]);
  const [listaDomicilio, setListaDomicilio] = useState([]);

  const [listaGastos, setListaGastos] = useState([]);

  const [stepsListaCampanias, setStepsListaCampanias] = useState([]);
  const [stepsCampaniaId, setStepsCampaniaId] = useState(0);
  const [stepsMontoBono, setStepsMontoBono] = useState(0);
  const [stepsTipoDescuento, setStepsTipoDescuento] = useState(appDefaultValues.NumberDefault);
  const [stepsCIPrecio, setStepsCIPrecio] = useState(appDefaultValues.NumberDefault);

  const [stepsTotalFinanciamiento, setStepsTotalFinanciamiento] = useState(0);

  const [listTipoPersona] = useState([
    { id: 1, description: "Persona Natural" },
    { id: 2, description: "Persona Juridica" },

  ]);

  const [modelicaRequestBody, setModelicaRequestBody] = useState(appDefaultValues.NullDefault);

  //PASO 1
  const [requestStepOne, setRequestStepOne] = useState(null);
  const [responseStepOne, setResponseStepOne] = useState(null);
  const [viewModelStepOneFinal, setViewModelStepOneFinal] = useState(null);

  //PASO 2
  const [requestStepTwo, setRequestStepTwo] = useState(null);
  const [responseStepTwo, setResponseStepTwo] = useState(null);
  const [viewModelStepTwoFinal, setViewModelStepTwoFinal] = useState(null);
  //PASO 3
  const [requestStepThree, setRequestStepThree] = useState(null);
  const [responseStepThree, setResponseStepThree] = useState(null);
  const [viewModelStepThreeFinal, setViewModelStepThreeFinal] = useState(null);
  const [productoSeleccionado, setProductoSeleccionado] = useState(0);
  const [memValor, setMemValor] = useState(0);
  const [cmaValor, setCMAValor] = useState('');
  //PASO 4
  const [viewModelStepFourFinal, setViewModelStepFourFinal] = useState(null);
  const [openEnviarAEvaluacion, setOpenEnviarAEvaluacion] = useState(false);
  //PASO 5  
  const [viewModelStepFiveFinal, setViewModelStepFiveFinal] = useState(null);
  const [openInformeComercial, setOpenInformeComercial] = useState(false);

  const [listNivelEducacion, setListNivelEducacion] = useState([]);
  const [listTipoVivienda, setListTipoVivienda] = useState([]);
  const [listTiempoResidencia, setListTiempoResidencia] = useState([]);
  const [listDiaPago, setListDiaPago] = useState([]);

  const [personaUId, setPersonaUId] = useState(0);
  const [conyugeUId, setConyugeUId] = useState(0);
  const [clienteUId, setClienteUId] = useState(0);

  const [clickOnObtenerTEA, setClickOnObtenerTEA] = useState(false);

  const [stepTwoReadOnlyViewModel, setStepTwoReadOnlyViewModel] = useState<any>(null);

  const [concesionarioAsociado, setConcesionarioAsociado] = useState<TMnSolicitudAsociadoAuditDto | null>(null);
  const [vendedorAsociado, setVendedorAsociado] = useState<TMnSolicitudAsociadoAuditDto | null>(null);
  const [isTitularJuridico, setTitularJuridico] = useState<boolean | null>(null);

  const [validacionModelicaDecisionRCH, setValidacionModelicaDecisionRCH] = useState('');

  const getObtenerEstadosVehiculo = async () => {
    const response = await ObtenerListas.EstadosVehiculo();
    setListaEstadosVehiculos(response);
  };

  const getObtenerConcesionario = async () => {
    const response = await ObtenerListas.Concesionarios();

    if (response.length > 0) {
      response.sort((a: any, b: any) => a.description.localeCompare(b.description));
    }

    setListaConcesionarios(response);
  };

  const getObtenerSucursales = async (partnetUid: number) => {
    const response = await ObtenerListas.Sucursales(partnetUid);

    if (response.length > 0) {
      response.sort((a: any, b: any) => a.description.localeCompare(b.description));
    }

    setListaSucursales(response);
  };

  const getObtenerVendores = async (partnerUId: number, puntoVentaUId: number) => {
    const response = await ObtenerListas.Vendedores(partnerUId, puntoVentaUId);
    
    if (response.length > 0) {
      response.sort((a: any, b: any) => a.description.localeCompare(b.description));
    }

    setListaVendedores(response);
  };

  const getObtenerUsosVehiculo = async () => {
    const response = await ObtenerListas.UsosVehiculo();
    const listaUsoVehiculo = response.filter((x: any) => x.description !== "TAXI");
    setListaUsosVehiculo(listaUsoVehiculo);
  };

  const getObtenerMarcas = async (puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {
    const response = await ObtenerListas.Marcas(puntoVentaUId, vendedorUId, partnerUId);
    setListaMarcas(response);
  };

  const getObtenerModelos = async (marcaUId: number, puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {
    const response = await ObtenerListas.Modelos(marcaUId, puntoVentaUId, vendedorUId, partnerUId);
    setListaModelos(response);
  };

  const getVersiones = async (modeloUId: number, puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {
    const response = await ObtenerListas.Version(modeloUId, puntoVentaUId, vendedorUId, partnerUId);
    setListaVersiones(response);
  };

  const getObtenerDestinoCredito = async () => {
    const response = await ObtenerListas.DestinoCredito();
    setListaDestinoCredito(response);
  };

  const InitListas = async () => {
    await ObtenerListas.TipoDocumento().then(response => {
      setListTipoDocumento(response);
    });

    await ObtenerListas.EstadoCiviles().then(response => {
      setListEstadoCivil(response);
    });

    await ObtenerListas.IdentidadesGenero().then(response => {
      setListIdentidadesGenero(response);
    });

    await ObtenerListas.MagnitudPN(27).then(response => {
      setListMagnitud(response);
    });

    await ObtenerListas.Generica(appConfigKey.keyIdRangoVentasAnual).then(response => {
      setRangoVentaAnual(response);
    });

    await ObtenerListas.GirosNegocios().then(response => {
      setGiroNegocio(response);
    });

    await ObtenerListas.ActividadEconomica().then(response => {
      setListActividadEconomica(response);
    });

    await ObtenerListas.CategoriaLaboral().then(response => {
      setListCategoriaLaboral(response);
    });

    await ObtenerListas.Moneda().then(response => {
      setListMoneda(response);
    });

    await ObtenerListas.Profesion().then(response => {
      setListProfesion(response);
    });

    await ObtenerListas.Pais().then(response => {
      setListPais(response);
    });

    await ObtenerListas.Regimenes().then(response => {
      setListRegimen(response);
    });

    await ObtenerListas.NivelesEducativos().then(response => {
      setListNivelEducacion(response);
    });
    await ObtenerListas.Generica(15).then(response => {
      setListTiempoResidencia(response);
    });

    await ObtenerListas.TiposDeVivienda().then(response => {
      setListTipoVivienda(response);
    });

    await ObtenerListas.Generica(appConfigKey.keyIdDiaPago).then(response => {
      setListDiaPago(response);
    });

    // await ObtenerListas.Generica(appConfigKey.keyIdCIPrecio).then(response => {
    //   setListCIPrecio(response);
    // });

    await ObtenerListas.ProveedorGPS().then(response => {
      setListProveedorGPS(response);
    });

    await ObtenerListas.Domicilios().then(response => {
      setListaDomicilio(response);
    });
  };

  const InitEvaluacionSolicitud = async () => {
    const tempEvaluacionSolicitud = await General.GetMnEvaluacionSolicitud(solicitudId);
    if (!tempEvaluacionSolicitud) return;
    
    for (let evaluacion of tempEvaluacionSolicitud) {

      let request = evaluacion?.jsoN_REQUEST ? JSON.parse(evaluacion.jsoN_REQUEST) : null;
      let response = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;

      if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepOne) {
        setRequestStepOne(request);
        setResponseStepOne(response);
      } else if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
        setRequestStepTwo(request);
        setResponseStepTwo(response);
      } else if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepThree) {
        setRequestStepThree(request);
        setResponseStepThree(response);
      }
    }
  }

  const loadSolicitudAsociados = async (tempSolicitud: any | null = null) => {
    const solicitud = tempSolicitud ?? solicitudData;
    if (solicitud == 0) return;

    var concesionarioAsociadoRequest = new TMnSolicitudAsociadoRequest();
    concesionarioAsociadoRequest.idenT_SOLICITUD = solicitud?.idenT_SOLICITUD;
    concesionarioAsociadoRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionConcesionario;
    concesionarioAsociadoRequest.uid = solicitud?.idenT_CONCESIONARIO;

    var concesionarioAsociadoData = await General.GetMnSolicitudAsociado(concesionarioAsociadoRequest)
      .then(response => response)
      .catch(_error => null);

    if (!concesionarioAsociadoData || concesionarioAsociadoData.length == 0) {
      // toast.error('Falló la obtención de concesionario asociado.', { duration: appConfigKey.keyDurationToast });
    } else {
      setConcesionarioAsociado(concesionarioAsociadoData[0]);
    }

    var vendedorAsociadoRequest = new TMnSolicitudAsociadoRequest();
    vendedorAsociadoRequest.idenT_SOLICITUD = solicitud?.idenT_SOLICITUD;
    vendedorAsociadoRequest.idenT_TIPO_RELACION = appConfigKey.KeyIdTipoRelacionVendedor;
    vendedorAsociadoRequest.uid = solicitud?.idenT_VENDEDOR;

    var vendedorAsociadoData = await General.GetMnSolicitudAsociado(vendedorAsociadoRequest)
      .then(response => response)
      .catch(_error => null);

    if (!vendedorAsociadoData || vendedorAsociadoData.length == 0) {
      // toast.error('Falló la obtención de vendedor asociado.', { duration: appConfigKey.keyDurationToast });
    } else {
      setVendedorAsociado(vendedorAsociadoData[0]);
    }
  }

  const resetRetrocederFlag = () => {
    setRetrocedioAlPrimerPaso(false);
    setRetrocedioAlSegundoPaso(false);
    setRetrocedioAlTercePaso(false);
    setRetrocedioAlQuintoPaso(false);
  }

  const checkReadonly = () => {
    let newSolicitud = {
      estadoSolicitud: solicitudData ? solicitudData?.iD_ESTADO_SOLICITUD : solicitudRow ? solicitudRow?.idEstado : null,
      idSituacionPlaft: solicitudData ? solicitudData?.idenT_SITUACION_PLAFT : solicitudRow ? solicitudRow?.idSituacionPlaft : null,
      idSituacionRiesgo: solicitudData ? solicitudData?.idenT_SITUACION_RIESGOS : solicitudRow ? solicitudRow?.idSituacionRiesgo : null,
      idUsuarioCreador: solicitudData ? solicitudData?.idenT_USUARIO_CREADOR : solicitudRow ? solicitudRow?.idUsuarioCreador : null,
    };
    let perfilId = appSecurityConfiguration.SessionPerfil;
    let result = ValidacionStepsHelper.IsSolicitudReadonly(newSolicitud, perfilId)

    setReadonlyForm(result.readonly);
    setCanResetForm(result.canResetData);
    // if (result.readonly || showResult !== undefined) {
    //   InitEvaluacionSolicitud();
    // }
  }

  const checkPlaftRiesgoObservado = (tempSolicitud: any) => {
    if (tempSolicitud && tempSolicitud !== 0) {
      if (tempSolicitud.iD_ESTADO_SOLICITUD === appConfigKey.KeyIdEstadoSolicitudObservado &&
        [tempSolicitud.idenT_SITUACION_PLAFT, tempSolicitud.idenT_SITUACION_RIESGOS].includes(appConfigKey.CodigoSituacionPlaftRiesgosObservada)
      ) {
        setReadonlyForm(true);
        General.GetSolicitudPersonaObservada(tempSolicitud?.idenT_SOLICITUD).then(miembroList => {
          if (!miembroList) {
            setDetailCustomerIsBlackListGesintel([appConfigKey.keyMsjErrorListaNegraYGesintelDetalle]);
          } else {
            const detalleObservaciones = SolicitudPersonaObservadaHelper.GetDetalleObservaciones(miembroList)
            setDetailCustomerIsBlackListGesintel(detalleObservaciones);
          }
        })
        if (tempSolicitud.idenT_SITUACION_PLAFT === appConfigKey.CodigoSituacionPlaftRiesgosObservada &&
          tempSolicitud.idenT_SITUACION_RIESGOS === appConfigKey.CodigoSituacionPlaftRiesgosObservada) {
          setMessageCustomerIsBlackListGesintel(appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT y RIESGOS'));
        } else if (tempSolicitud.idenT_SITUACION_PLAFT === appConfigKey.CodigoSituacionPlaftRiesgosObservada) {
          setMessageCustomerIsBlackListGesintel(appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT'));
        } else {
          setMessageCustomerIsBlackListGesintel(appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'RIESGOS'));
        }
      }
    }
  }

  const InitSolicitud = async () => {
    setLoadingSteps(true);
    try {

      const tempSolicitud: any = await General.Solicitud(solicitudId);
      setSolicitudData(tempSolicitud);
      setTitularJuridico(tempSolicitud.titulaR_JURIDICO || tempSolicitud.titulaR_ID_CATEGORIA_LABORAL_PRINCIPAL === appConfigKey.keyCodigoCategoriaLaboralTres);
      setStepsMontoBono(Number(tempSolicitud.monT_BONO));
      setStepsTipoDescuento(Number(tempSolicitud.tipO_DESCUENTO));
      setStepsCampaniaId(Number(tempSolicitud.idenT_CAMPANA));
      setStepsTotalFinanciamiento(Number(tempSolicitud.monT_CALC_FINANCIAMIENTO));
      setStepsCIPrecio(tempSolicitud.idenT_CAMPANIA_CI_PRECIO_TEA); //Tipo descuento

      const gastos = await General.GetMnSolicitudGastos(Number(solicitudId));

      setListaGastos(gastos);

    } catch (error) {

    } finally {

      setLoadingSteps(false);

    }
  };

  const onClickNext = (event: any) => {
    event.preventDefault();
    setClickNext(clickNext + 1);
    WindowHelper.ScrollToTop();
  };

  const primeraCarga = () => {
    InitListas();
    if (solicitudId) {
      InitSolicitud();
      InitEvaluacionSolicitud();
    }
  };

  useEffect(() => primeraCarga(), []);
  useEffect(() => checkReadonly(), [appSecurityConfiguration.SessionPerfil, solicitudData]);
  useEffect(() => {
    checkPlaftRiesgoObservado(solicitudData)
    loadSolicitudAsociados(solicitudData)
  }, [solicitudData]);

  useEffect(() => {
    if (clickNext !== 0) {
      setLoadingSteps(true);
      setBtnContinuarLoading(true);
    }
  }, [clickNext]);

  const eventOnClickReiniciarDatos = async (event: Event) => {
    event.preventDefault();
    setLoadingSteps(true);
    try {

      const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(solicitudId));

      if (listaSolicitudEtapas.length > 0) {
        for (let solicitudEtapa of listaSolicitudEtapas) {
          await General.GetSolicitudEtapasUpdate(
            {
              "identSolicitudEtapas": solicitudEtapa.identSolicitudEtapas,
              "identSolicitud": solicitudEtapa.identSolicitud,
              "identDecisionMotor": solicitudEtapa.identDecisionMotor,
              "identEtapa": solicitudEtapa.identEtapa,
              "identProceso": solicitudEtapa.identProceso,
              "identEstadoProceso": solicitudEtapa.identEstadoProceso,
              "indEstado": false,
              "identUsuarioModif": appSecurityConfiguration.SessionUserId,
              "ordenProceso": solicitudEtapa.ordenProceso
            }
          );
        }
      }

      MasterManager.GetMnDocumentacionDocumentoDelete({
        IDENT_SOLICITUD: solicitudData.idenT_SOLICITUD,
        IDENT_TIPO_LISTADO: appConfigKey.KeyIdCheckListUno
      });

      const request = new TMnReiniciarDatosSolicitudRequest();
      request.idenT_SOLICITUD = solicitudData.idenT_SOLICITUD;
      request.idenT_USUARIO_MODIF = appSecurityConfiguration.SessionUserId;
      request.iD_ESTADO_SOLICITUD = appConfigEstadoSolicitud.RegistroDeSolicitud;
      const response = await RequestManager.GetMnReiniciarDatosSolicitud(request);
      if (response) {
        let isValid = response?.data?.isValid;
        let content = response?.data?.content;
        if (isValid && content) {
          resetRetrocederFlag();
          setSolicitudData(content);
          setReadonlyForm(false);
          toast.success('Solicitud reiniciada correctamente', { duration: appConfigKey.keyDurationToast });
        } else {
          toast.error('Falló el reinicio de solicitud', { duration: appConfigKey.keyDurationToast });
        }
      }
    } catch (error) {
      toast.error('Falló el reinicio de solicitud', { duration: appConfigKey.keyDurationToast });
    } finally {
      setLoadingSteps(false);
    }

  };

  const eventOnClickRegresar = (event: Event) => {
    event.preventDefault();

    const pasoAnterior = step - 1;

    setStep(pasoAnterior);

    if (pasoAnterior === 1) {
      setRetrocedioAlPrimerPaso(true);
    }

    if (pasoAnterior === 2) {
      setRetrocedioAlSegundoPaso(true);
    }

    if (pasoAnterior === 3) {
      setRetrocedioAlTercePaso(true);
    }

    if (pasoAnterior === 5) {
      setRetrocedioAlQuintoPaso(true);
    }

    setRetrocedio(true);
    WindowHelper.ScrollToTop();
  };

  const eventOnClickInformeComercial = (event: Event) => {
    event.preventDefault();
    setOpenInformeComercial(true);
  };

  const eventOnClickEnviarAEvaluacion = (event: Event) => {
    event.preventDefault();
    setOpenEnviarAEvaluacion(true);
  };

  const cargarViewModelStepTwoReadOnly = () => {

    const {
      descC_VERSION,
      descC_SUCURSAL,
      descC_MARCA,
      descC_DESTINO_CREDITO,
      descC_USO_VEHICULO,
      monT_CUOTA_INICIAL,
      monT_PRECIO_VEHICULO,
      iD_TIPO_MONEDA_FINANCIAMIENTO,
      monT_TIPO_CAMBIO,
      descC_TIPO_MONEDA_FINANCIAMIENTO,
      descL_EMAIL_VENDEDOR,
      descC_VENDEDOR,
      descC_CONCESIONARIO,
      idenT_ANIO_VEHICULO,
      descC_ESTADO_VEHICULO_FINANCIAR,
      iD_TIPO_MONEDA_PRECIO_VEHICULO,
      iD_TIPO_MONEDA_CUOTA_INICIAL,
      iD_TIPO_MONEDA_PRECIO_VEHICULO_BASE,
      monT_PRECIO_VEHICULO_BASE,
      iD_TIPO_MONEDA_CUOTA_INICIAL_BASE,
      monT_CUOTA_INICIAL_BASE,
      monT_TIPO_CAMBIO_BASE,
    } = solicitudData;

    const montoSolicitudHelper = new MontoSolicitudHelper(
      Number(monT_PRECIO_VEHICULO),
      Number(iD_TIPO_MONEDA_PRECIO_VEHICULO),
      Number(monT_TIPO_CAMBIO)
    );

    const montoSolicitudHelper2 = new MontoSolicitudHelper(
      Number(monT_CUOTA_INICIAL),
      Number(iD_TIPO_MONEDA_CUOTA_INICIAL),
      Number(monT_TIPO_CAMBIO)
    );

    const montoVehiculoSolicitudBaseHelper = new MontoSolicitudHelper(
      Number(monT_PRECIO_VEHICULO_BASE),
      Number(iD_TIPO_MONEDA_PRECIO_VEHICULO_BASE),
      Number(monT_TIPO_CAMBIO_BASE)
    );

    const montoCuotaInicialSolicitudBaseHelper = new MontoSolicitudHelper(
      Number(monT_CUOTA_INICIAL_BASE),
      Number(iD_TIPO_MONEDA_CUOTA_INICIAL_BASE),
      Number(monT_TIPO_CAMBIO_BASE)
    );

    let montoFinanciar = 0;
    let montoFinanciarBase = 0;

    if (iD_TIPO_MONEDA_FINANCIAMIENTO === 1) {
      montoFinanciar = montoSolicitudHelper.getSoles() - montoSolicitudHelper2.getSoles();
      montoFinanciarBase = montoVehiculoSolicitudBaseHelper.getSoles() - montoCuotaInicialSolicitudBaseHelper.getSoles();
    }

    if (iD_TIPO_MONEDA_FINANCIAMIENTO === 2) {
      montoFinanciar = montoSolicitudHelper.getDolares() - montoSolicitudHelper2.getDolares();
      montoFinanciarBase = montoVehiculoSolicitudBaseHelper.getDolares() - montoCuotaInicialSolicitudBaseHelper.getDolares();
    }

    const model = {
      MonedaId: iD_TIPO_MONEDA_FINANCIAMIENTO,
      Version: descC_VERSION,
      Modelo: descC_VERSION,
      Marca: descC_MARCA,
      DestinoCredito: descC_DESTINO_CREDITO,
      UsoVehiculo: descC_USO_VEHICULO,
      MontoInicialSoles: GeneralHelper.AgregarComasAMiles(montoSolicitudHelper2.getSoles().toFixed(2)),
      MontoInicialDolares: GeneralHelper.AgregarComasAMiles(montoSolicitudHelper2.getDolares().toFixed(2)),
      Inicial: montoSolicitudHelper2.getPorcentajeInicial(montoSolicitudHelper.getDolares(), montoSolicitudHelper2.getDolares()),
      MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(montoSolicitudHelper.getSoles().toFixed(2)),
      MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(montoSolicitudHelper.getDolares().toFixed(2)),
      TipoCambio: monT_TIPO_CAMBIO,
      Moneda: descC_TIPO_MONEDA_FINANCIAMIENTO,
      VendedorEmail: descL_EMAIL_VENDEDOR,
      Vendedor: descC_VENDEDOR,
      Sucursal: descC_SUCURSAL,
      Concesionario: descC_CONCESIONARIO,
      VehiculoYear: idenT_ANIO_VEHICULO,
      EstadoVehiculo: descC_ESTADO_VEHICULO_FINANCIAR,
      MontoFinanciar: GeneralHelper.AgregarComasAMiles(montoFinanciar.toFixed(2)),
      TipoCambioBase: monT_TIPO_CAMBIO_BASE,
      MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(montoVehiculoSolicitudBaseHelper.getSoles().toFixed(2)),
      MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(montoVehiculoSolicitudBaseHelper.getDolares().toFixed(2)),
      MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(montoCuotaInicialSolicitudBaseHelper.getSoles().toFixed(2)),
      MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(montoCuotaInicialSolicitudBaseHelper.getDolares().toFixed(2)),
      InicialBase: montoCuotaInicialSolicitudBaseHelper.getPorcentajeInicial(montoVehiculoSolicitudBaseHelper.getDolares(), montoCuotaInicialSolicitudBaseHelper.getDolares()),
      MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(montoFinanciarBase.toFixed(2)),
    };

    setStepTwoReadOnlyViewModel(model);

  };

  useEffect(() => {

    if (readonlyForm && solicitudData !== 0) {
      cargarViewModelStepTwoReadOnly();
    }

  }, [readonlyForm, solicitudData]);

  return (
    <StepsContext.Provider value={{
      step,
      setStep,
      solicitudData,
      setSolicitudData,
      updateSolicitudValue,
      setUpdateSolicitudValue,
      clickNext,
      setClickNext,
      onClickNext,
      btnContinuarLoading,
      setBtnContinuarLoading,
      eventOnClickReiniciarDatos,
      eventOnClickRegresar,
      eventOnClickInformeComercial,
      eventOnClickEnviarAEvaluacion,
      loadingSteps,
      setLoadingSteps,
      retrocedio,

      listTipoDocumento,
      listActividadEconomica,
      listCategoriaLaboral,
      listIdentidadesGenero,
      listEstadoCivil,
      listMagnitud,
      listMoneda,
      listRegimen,
      listRangoVentaAnual,
      listGiroNegocio,
      listTipoPersona,
      listProfesion,
      listPais,

      listNivelEducacion,
      listTipoVivienda,
      listTiempoResidencia,

      modelicaRequestBody,

      responseStepOne,
      responseStepTwo,
      responseStepThree,

      viewModelStepOneFinal,
      viewModelStepTwoFinal,
      viewModelStepThreeFinal,
      viewModelStepFourFinal,
      viewModelStepFiveFinal,

      setModelicaRequestBody,

      setResponseStepOne,
      setResponseStepTwo,
      setResponseStepThree,

      setViewModelStepOneFinal,
      setViewModelStepTwoFinal,
      setViewModelStepThreeFinal,
      setViewModelStepFourFinal,
      setViewModelStepFiveFinal,

      listDiaPago,

      listCIPrecio, setListCIPrecio,
      listProveedorGPS,

      setRetrocedio,

      setRequestTitularIngresos,
      setRequestConyugeIngresos,

      requestTitularIngresos,
      requestConyugeIngresos,

      retrocedioAlPrimerPaso,
      retrocedioAlSegundoPaso,
      retrocedioAlTercerPaso,
      retrocedioAlQuintoPaso,

      listaEstadosVehiculos,
      listaVehiculoYears,
      listaConcesionarios,
      listaSucursales,
      listaVendedores,
      listaUsosVehiculo,
      listaMarcas,
      listaModelos,
      listaVersiones,
      listaDestinoCredito,

      getObtenerSucursales,
      getObtenerVendores,
      getObtenerMarcas,
      getObtenerModelos,
      getVersiones,
      getObtenerEstadosVehiculo,
      getObtenerConcesionario,
      getObtenerUsosVehiculo,
      getObtenerDestinoCredito,
      setListaVehiculoYears,

      listaGastos,

      requestStepOne, setRequestStepOne,
      requestStepTwo, setRequestStepTwo,
      requestStepThree, setRequestStepThree,

      personaUId, setPersonaUId,
      clienteUId, setClienteUId,
      messageCustomerIsBlackListGesintel,
      setMessageCustomerIsBlackListGesintel,
      detailCustomerIsBlackListGesintel,
      setDetailCustomerIsBlackListGesintel,

      readonlyForm,
      setReadonlyForm,

      canResetForm,

      conyugeUId, setConyugeUId,
      TCEA, setTCEA,
      listaDomicilio,
      productoSeleccionado,
      setProductoSeleccionado,
      memValor,
      setMemValor,
      cmaValor,
      setCMAValor,
      solicitudDataActualizado, setSolicitudDataActualizado,
      stepsListaCampanias, setStepsListaCampanias,
      stepsCampaniaId, setStepsCampaniaId,
      stepsMontoBono, stepsTipoDescuento,
      stepsCIPrecio, setStepsCIPrecio,
      stepsTotalFinanciamiento, setStepsTotalFinanciamiento,
      clickOnObtenerTEA, setClickOnObtenerTEA,
      stepTwoReadOnlyViewModel,

      concesionarioAsociado,
      vendedorAsociado,
      validacionModelicaDecisionRCH,
      setValidacionModelicaDecisionRCH,

      isTitularJuridico,
      setTitularJuridico,

      solicitudId,
      openInformeComercial,
      setOpenInformeComercial,
      openEnviarAEvaluacion,
      setOpenEnviarAEvaluacion,
      canSendForEvaluation,
      setCanSendForEvaluation,
    }}>
      {children}
    </StepsContext.Provider>
  );
};