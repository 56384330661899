import { useEffect, useState } from "react";
import { DatosDetailsStepsOneCard, DatosDetailsStepsOneCardDefault } from "./interfaces";
import { appConfigKey } from "../../../../../../config/Config";
import GeneralHelper from "../../../../../helpers/GeneralHelper";


const DetailStepOneCard = () => {

  const [viewModel, setViewModel] = useState<DatosDetailsStepsOneCard>(DatosDetailsStepsOneCardDefault);

  useEffect(() => {


  }, []);

  return (
    <>
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details mlr-0-pre-quotation">
        <div className="row g-2 mb-2 align-items-center item-body-details">

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Titular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Conyuge</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Resultado evaluación</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>MEM</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>CMA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Segmento</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>TEA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default DetailStepOneCard;
