import DatosDetalleCronogramaCard from "../components/DatosDetalleCronogramaCard";
import DatosResumenCronogramaCard from "../components/DatosResumenCronogramaCard";

export const CronogramaSection = () => {

  return (
    <>
      <DatosResumenCronogramaCard />
      <DatosDetalleCronogramaCard />
    </>
  );
};