import CardTitleStart from '../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import { Toaster } from "sonner";
import './preQuotation.scss';
import { PreQuotationStepsProvider } from './providers';
import PreQuotationSteps from './components/preQuotationSteps';

const PreQuotation = () => {

  return (
    <>
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />
      <MainLayout />
      <CardTitleStart Ventana="Pre-Cotización" />
      <PreQuotationStepsProvider>
        <PreQuotationSteps />
      </PreQuotationStepsProvider>
    </>
  );

};

export default PreQuotation;