import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../providers";
import DetailsAdditionalMotorCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/detailsAdditionalMotorCard";
import { DatosDetailsStepAdditionalMotorCard } from "./interfaces";
import { obtenerDatosAdicionalesMotor } from "../../../../../helpers/ModelicaMotorHelper";

const DetailsStepAdditionalMotorCard = () => {

  const {
    step,
    responseStepOne,
    responseStepTwo,
    responseStepThree,
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<DatosDetailsStepAdditionalMotorCard | null>(null);

  useEffect(() => {
    let lista = null;

    if (step == 2) {
      lista = obtenerDatosAdicionalesMotor(responseStepOne?.response);
    } else if (step == 3) {
      lista = obtenerDatosAdicionalesMotor(responseStepTwo?.response, responseStepOne?.response);
    } else if (step >= 4) {
      lista = obtenerDatosAdicionalesMotor(responseStepThree?.response, responseStepTwo?.response, responseStepOne?.response);
    }

    setViewModel({ datosAdicionales: lista });

  }, [responseStepOne, responseStepTwo, responseStepThree, step]);

  if (!viewModel) return null;

  return (
    <>
      <DetailsAdditionalMotorCard
        datosAdicionales={viewModel?.datosAdicionales}
      />
    </>
  );
};

export default DetailsStepAdditionalMotorCard;
