import TitleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/titleCard";
import { usePreQuotationStepTwo } from "../../../18.10.-base/providers";
import SummaryScheduleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/summaryScheduleCard";

const DatosResumenCronogramaCard = () => {
  const { datosFinancierosViewModel } = usePreQuotationStepTwo();

  const resumen = [
    {
      plazo: "12",
      monedaFinanciamientoId: 1,
      simple: "20000",
      doble: "50000",
      montoRango: "80000",
      monto: "3000",
      montoCuotaDoble: "6000",
    },
    {
      plazo: "18",
      monedaFinanciamientoId: 1,
      simple: "18000",
      doble: "19000",
      montoRango: "80000",
      monto: "3000",
      montoCuotaDoble: "6000",
    },
    {
      plazo: "24",
      monedaFinanciamientoId: 1,
      simple: "16000",
      doble: "18000",
      montoRango: "80000",
      monto: "3000",
      montoCuotaDoble: "6000",
    },
    {
      plazo: "36",
      monedaFinanciamientoId: 1,
      simple: "14000",
      doble: "17000",
      montoRango: "80000",
      monto: "3000",
      montoCuotaDoble: "6000",
    },
    {
      plazo: "48",
      monedaFinanciamientoId: 1,
      simple: "10000",
      doble: "15000",
      montoRango: "80000",
      monto: "3000",
      montoCuotaDoble: "6000",
    },
  ];

  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card mlr-0-pre-quotation">
      <TitleCard title={"CUOTAS POR PLAZOS"} obligatorio={false} />
      <SummaryScheduleCard data={resumen} monedaFinanciamientoId={1} mem={18000} />
    </div>
  );
};

export default DatosResumenCronogramaCard;
