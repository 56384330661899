import { usePreQuotationStepOne } from "../../../18.10.-base/providers";
import TitleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/titleCard";
import { SelectInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/selectInput";
import { MoneyInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/moneyInput";
import { SwitchInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/switchInput";
import { NumberDecimalInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/numberDecimalInput";
import { appConfigKey } from "../../../../../../config/Config";
import { Button } from "@mui/joy";
import { IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const DatosCondicionesFinanciamientoCard = () => {

  const {
    datosCondicionesFinanciamiento,
    setDatosCondicionesFinanciamiento,
  } = usePreQuotationStepOne();

  return (
    <>
      <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card mlr-0-pre-quotation">
        <TitleCard title={'DATOS Y CONDICIONES DEL FINANCIAMIENTO'} obligatorio={true} />
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 pt-4">
            <SelectInput
              title={"Concesionario"}
              options={[]}
              value={datosCondicionesFinanciamiento.concesionarioId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ concesionarioId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Sucursal"}
              options={[]}
              value={datosCondicionesFinanciamiento.sucursalId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ sucursalId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Vendedor"}
              options={[]}
              value={datosCondicionesFinanciamiento.vendedorId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ vendedorId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Marca"}
              options={[]}
              value={datosCondicionesFinanciamiento.marcaId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ marcaId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Modelo"}
              options={[]}
              value={datosCondicionesFinanciamiento.modeloId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ modeloId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Version"}
              options={[]}
              value={datosCondicionesFinanciamiento.versionId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ versionId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Moneda"}
              options={[]}
              value={datosCondicionesFinanciamiento.monedaId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ monedaId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <NumberDecimalInput
              title={"Tipo de cambio"}
              value={datosCondicionesFinanciamiento.tipoCambio}
              disabled={false}
              numberOfDecimals={4}
              longNumber={appConfigKey.keyLongTipoCambio}
              onChange={(event: any) => setDatosCondicionesFinanciamiento({ tipoCambio: event.target.value })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <MoneyInput
              startDecorator={"$"}
              name="montoVehiculo"
              title={"Monto de Vehículo"}
              placeholder=""
              value={datosCondicionesFinanciamiento.montoVehiculo}
              disabled={false}
              onChange={(event: any) => setDatosCondicionesFinanciamiento({ montoVehiculo: event.target.value })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <NumberDecimalInput
              name="porcentajeInicial"
              startDecorator={"%"}
              title={"Inicial"}
              placeholder=""
              numberOfDecimals={4}
              value={datosCondicionesFinanciamiento.porcentajeInicial}
              disabled={false}
              onChange={(event: any) => setDatosCondicionesFinanciamiento({ porcentajeInicial: event.target.value })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <MoneyInput
              startDecorator={"$"}
              title={"Monto a financiar"}
              placeholder=""
              value={datosCondicionesFinanciamiento.montoFinanciar}
              disabled={true}
              onChange={(event: any) => setDatosCondicionesFinanciamiento({ montoFinanciar: event.target.value })}
            />
          </div>

        </div>

        <hr className="hr hr-blurry mt-4 mb-0" style={{ borderColor: "#0089B5", opacity: 1 }} />

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-auto col-xxl-2 pt-4 align-self-end">
            <Button className="button-pre-quotation action w-100" disabled={false} onClick={() => { }} size="md">
              <div className="d-flex align-items-center w-100">
                <div style={{ width: 'inherit', paddingRight: '4px' }}>Obtener productos</div>
                <SearchIcon />
              </div>
            </Button>
          </div>

          <div className="col-lg-4 pt-4">
            <SelectInput
              title={"Producto"}
              options={[]}
              value={datosCondicionesFinanciamiento.productoId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ productoId: newValue })}
            />
          </div>
        </div>

        <div className="row g-2 mb-2">
          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Cuotas"}
              options={[]}
              value={datosCondicionesFinanciamiento.cuotasId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ cuotasId: newValue })}
            />
          </div>

          <div className="col-lg-4 pt-4">
            <SelectInput
              title={"Proveedor seguro vehic."}
              options={[]}
              value={datosCondicionesFinanciamiento.proveedorSeguroVehicularId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ proveedorSeguroVehicularId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SwitchInput
              name={"esGastoGPS"}
              title={"Gastos inclusión GPS"}
              checked={datosCondicionesFinanciamiento.esGastoGps}
              disabled={false}
              onChange={(event: any) => setDatosCondicionesFinanciamiento({ esGastoGps: event.target.checked })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Campaña"}
              options={[]}
              value={datosCondicionesFinanciamiento.campaniaId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ campaniaId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"CI/Precio"}
              options={[]}
              value={datosCondicionesFinanciamiento.campaniaCIPrecioId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosCondicionesFinanciamiento({ campaniaCIPrecioId: newValue })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DatosCondicionesFinanciamientoCard;
