import { ReactComponent as CloseIcon } from "../../../../../assets/media/icons/ico-close-modal.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Button, FormControl, FormLabel, Textarea } from "@mui/joy";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import {
  appConfigKey,
  appConfigPerfiles,
  appSecurityConfiguration,
} from "../../../../../config/Config";
import { TMnSolicitudObservadaInsertUpdateRequest } from "../../../../models/MasterManager/solicitud-observada-request.model";
import { ObtenerServicio } from "../1.5.11.-service/ObtenerServicios";

export enum ReleaseRejectDialogAction {
  REJECT,
  RELEASE,
}

const ReleaseRejectDialog = (props: any) => {
  const [requestBody, setRequestBody] = useState<any>();
  const [modalRequest, setModalRequest] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setModalRequest(props.data);
    setRequestBody({});
  }, [props.open]);

  const handleChange = (e: any) => {
    let { name, value, type } = e.target;
    if (type === "number" && value === "") {
      value = 0;
    }
    if (type === "select") {
      value = parseInt(value);
    }
    if (type === "date" && value === "Invalid Date") {
      value = null;
    }

    setRequestBody({
      ...requestBody,
      [name]: value,
    });
  };

  const handleClose = (e: any) => { };

  const observedResultSolicitudeSave = async function (request: TMnSolicitudObservadaInsertUpdateRequest) {
    let datos = await ObtenerServicio.GetSolicitudObservadaInsert(request);
    return datos != null;
  };

  const SaveModal = async () => {
    try {
      var request = new TMnSolicitudObservadaInsertUpdateRequest();
      request.idenT_SOLICITUD = modalRequest.solicitudeId;
      request.idenT_USUARIO = appSecurityConfiguration.SessionUserId;
      request.idenT_AREA_SITUACION = modalRequest.areaId;

      if (modalRequest?.action === ReleaseRejectDialogAction.REJECT) {
        request.idenT_SITUACION = appConfigKey.CodigoSituacionPlaftRiesgosRechazada;
      } else if (modalRequest?.action === ReleaseRejectDialogAction.RELEASE) {
        request.idenT_SITUACION = appConfigKey.CodigoSituacionPlaftRiesgosLiberada;
      }

      if (!requestBody.sustento || requestBody.sustento.trim() === "") {
        toast.error(appConfigKey.keySustentoReleaseRejectEmpty, {
          duration: appConfigKey.keyDurationToast,
        });
        return;
      }

      request.descC_SUSTENTO = requestBody.sustento;
      request.descC_COMENTARIO = requestBody.comentario ?? null;

      if (props.open) {
        setLoading(true)
        let successful = await observedResultSolicitudeSave(request);

        if (!successful) {
          toast.error("Falló el guardado, vuelva a intentarlo nuevamente.", {
            duration: appConfigKey.keyDurationToast,
          });
        } else {
          var actionMessage = modalRequest?.action === ReleaseRejectDialogAction.REJECT ? "Rechazado" :
            modalRequest?.action === ReleaseRejectDialogAction.RELEASE ? "Liberado" : "";
          props.handleSaved!(`${actionMessage} correctamente.`)
        }
      } else {
        toast.error("Operación cancelada, vuelva a intentarlo nuevamente.", {
          duration: appConfigKey.keyDurationToast,
        });
      }
    } catch (error) {
      setLoading(false)
    }
  };

  return (
    <Dialog
      open={props.open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px",
        },
      }}
    >
      <Toaster position="top-center" richColors closeButton />
      <div className="release-reject-request-dialog-content">
        <button
          className="btn btn-sm change-rate-close-btn"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </button>
        <div className="title mb-4">
          {modalRequest?.action === ReleaseRejectDialogAction.REJECT
            ? "RECHAZAR"
            : "LIBERAR"}
          {" "}
          {
            modalRequest?.areaId === appConfigKey.CodigoAreaPlaft ? "PLAFT"
              : modalRequest?.areaId === appConfigKey.CodigoAreaRiesgos ? "RIESGOS" : ""
          }
        </div>
        <div className="obs-text-dialog mb-4">
          <label>
            {"¿Estás seguro de "}
            {modalRequest?.action === ReleaseRejectDialogAction.REJECT
              ? "rechazar"
              : "liberar"}
            {" esta solicitud?"}
          </label>
        </div>
        {modalRequest?.action === ReleaseRejectDialogAction.RELEASE && modalRequest.areaId === appConfigKey.CodigoAreaPlaft && (
          <div className="mb-3">
            <FormLabel>
              <span className="from-label mb-1">
                Comentarios: Documentos adicionales por integrante
              </span>
            </FormLabel>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-12">
                <FormControl>
                  <Textarea
                    id="comentario"
                    placeholder="Ingresar texto"
                    minRows={2}
                    sx={{ mb: 1 }}
                    name="comentario"
                    onChange={handleChange}
                    value={requestBody?.comentario}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}
        <div className="mb-3">
          <FormLabel>
            <span className="from-label mb-1">
              {"Sustento de "}
              {modalRequest?.action === ReleaseRejectDialogAction.REJECT
                ? "rechazo"
                : "liberación"}
            </span>
            <span style={{ color: "red" }}> * </span>
          </FormLabel>
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-12">
              <FormControl>
                <Textarea
                  id="sustento"
                  placeholder="Ingresar texto"
                  minRows={2}
                  sx={{ mb: 1 }}
                  name="sustento"
                  onChange={handleChange}
                  value={requestBody?.sustento}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <div className="obs-text-dialog mb-4">
          <label className="w-75">
            {"Una vez "}
            {modalRequest?.action === ReleaseRejectDialogAction.REJECT
              ? "rechazado"
              : "liberado"}
            {" no se podrá hacer cambios"}
          </label>
        </div>
        <div className="row">
          <div className="col-6">
            <Button
              disabled={isLoading}
              color="neutral"
              className="justify-content-start"
              sx={{
                borderRadius: 24,
                width: "100%",
              }}
              onClick={props.handleClose}
            >
              <div>
                <ChevronLeftIcon />
                <label className="ms-1">Cancelar</label>
              </div>
            </Button>
          </div>
          <div className="col-6">
            <Button
              disabled={isLoading}
              color="danger"
              className="justify-content-end"
              sx={{
                borderRadius: 24,
                width: "100%",
              }}
              onClick={() => SaveModal()}
            >
              <div>
                <label className="me-1">
                  {modalRequest?.action === ReleaseRejectDialogAction.REJECT
                    ? "Rechazar"
                    : "Liberar"}
                </label>
                <KeyboardArrowRight />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ReleaseRejectDialog;
