import { FinanciamientoSection, TitularSection } from "./sections";

const PreQuotationStepOne = () => {
  return (
    <>
      <TitularSection />
      <FinanciamientoSection />
    </>
  );
};

export default PreQuotationStepOne;

