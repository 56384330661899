import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/joy/Tooltip";
import { Box } from "@mui/joy";

interface TextWithTooltipProps {
    text: string;
}

export const TextWithTooltip = ({ text }: TextWithTooltipProps) => {
    const textRef = useRef<HTMLSpanElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const checkTruncation = () => {
            if (textRef.current) {
                setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
            }
        };

        checkTruncation();
        window.addEventListener("resize", checkTruncation);
        return () => window.removeEventListener("resize", checkTruncation);
    }, []);

    return (
        <Tooltip
            sx={{ borderColor: '#0089B5' }}
            title={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 250,
                        justifyContent: 'center',
                        p: 1
                    }}
                >
                    {text}
                </Box>
            }
            variant="outlined"
            placement="top-start"
            size="sm"
            open={showTooltip && isTruncated}
        >
            <span
                ref={textRef}
                className="text-negrita"
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    maxWidth: "100%",
                    // cursor: isTruncated ? "pointer" : "default"
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {text}
            </span>
        </Tooltip>
    );
};