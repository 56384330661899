import { appConfigKey } from "../../../../../../config/Config";
import { usePreQuotationStepOne } from "../../../18.10.-base/providers";
import TitleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/titleCard";
import { NumberInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/numberInput";
import { SelectInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/selectInput";
import { MoneyInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/moneyInput";
import { SwitchInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/switchInput";

const DatosPersonalesDelConyugeCard = () => {

  const {
    datosCliente,
    datosConyuge,
    setDatosConyuge
  } = usePreQuotationStepOne();

  if (!datosConyuge) return null;

  return (
    <>
      <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card mlr-0-pre-quotation">
        <TitleCard title={`DATOS ${datosCliente.estadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? 'CÓNYUGE' : 'CONVIVIENTE'}`} obligatorio={true} />
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Tipo de doc. Conyuge"}
              options={[]}
              value={datosConyuge.tipoDocumentoId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosConyuge({ tipoDocumentoId: newValue })}
            />
          </div>

          <div className="col-lg-4 pt-4">
            <NumberInput
              title={"Número de documento"}
              placeholder="Ej: 00000000"
              longNumber={8}
              value={datosConyuge.numeroDocumento}
              disabled={false}
              onChange={(event: any) => setDatosConyuge({ numeroDocumento: event.target.value })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SelectInput
              title={"Categoria laboral"}
              options={[]}
              value={datosConyuge.categoriaLaboralId}
              disabled={false}
              onChange={(event: any, newValue: any) => setDatosConyuge({ categoriaLaboralId: newValue })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <MoneyInput
              title={"Ingresos netos"}
              placeholder=""
              value={datosConyuge.ingresosNetos}
              disabled={false}
              onChange={(event: any) => setDatosConyuge({ ingresosNetos: event.target.value })}
            />
          </div>

          <div className="col-lg-2 pt-4">
            <SwitchInput
              name={"esAnualizado"}
              title={"Anualizado solo 5ta"}
              checked={datosConyuge.esAnualizado}
              disabled={false}
              onChange={(event: any) => setDatosConyuge({ esAnualizado: event.target.checked })}
            />
          </div>

          {datosConyuge?.esAnualizado &&
            (
              <div className="col-lg-2 pt-4">
                <MoneyInput
                  title={"Ingreso Anualizados"}
                  placeholder=""
                  value={datosConyuge.ingresosAnualizados}
                  disabled={true}
                // onChange={(event: any) => setDatosConyuge({ , ingresosAnualizados: event.target.value })}
                />
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default DatosPersonalesDelConyugeCard;
