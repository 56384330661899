import LoadingProgress from "../../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import BaseCard from "../baseCard";
import Steps from "../../../../1.-shared/1.7.-component/1.7.5.-card/steps";
import PreQuotationStepOne from "../../../18.20.-preQuotationStepOne/preQuotationStepOne";
import { PreQuotationStepOneProvider, PreQuotationStepTwoProvider, usePreQuotationSteps } from "../../providers";
import DetailStepOneCard from "../detailStepOneCard";
import DetailStepAdditionalMotorCard from "../detailStepAdditionalMotorCard";
import PreQuotationStepTwo from "../../../18.30.-preQuotationStepTwo/preQuotationStepTwo";
import ButtonStepsCard from "../buttonStepsCard";

const PreQuotationSteps = () => {

  const {
    step,
    loadingStep
  } = usePreQuotationSteps();

  return (
    <>
      <BaseCard>
        {loadingStep && <LoadingProgress />}
        {step < 3 && <Steps step={step} totalSteps={2} />}

        {/* Tarjetas */}
        {(step > 1 && step <= 2) && <DetailStepOneCard />}
        {(step > 1 && step <= 2) && <DetailStepAdditionalMotorCard />}

        {/* Formularios */}
        {step === 1 && (
          <PreQuotationStepOneProvider>
            <PreQuotationStepOne key={1} />
          </PreQuotationStepOneProvider>
        )}

        {step === 2 && (
          <PreQuotationStepTwoProvider>
            <PreQuotationStepTwo key={2} />
          </PreQuotationStepTwoProvider>
        )}

        {/* Botones */}
        <ButtonStepsCard />
      </BaseCard>
    </>
  );
};

export default PreQuotationSteps;