import { CronogramaSection } from "./sections";

const PreQuotationStepTwo = () => {
  return (
    <>
      <CronogramaSection />
    </>
  );
};

export default PreQuotationStepTwo;

