import { Table } from "@mui/joy";
import { SummaryScheduleCardProps } from "./interfaces";
import icoAproved from '../../../../../../assets/media/icons/ico-aproved.svg';
import icoDenided from '../../../../../../assets/media/icons/ico-denided.svg';
import { appConfigKey } from "../../../../../../config/Config";
import "./index.scss";
import GeneralHelper from "../../../../../helpers/GeneralHelper";

const SummaryScheduleCard = ({ data, monedaFinanciamientoId, mem }: SummaryScheduleCardProps) => {
  const currencySymbol = !monedaFinanciamientoId ? '' : monedaFinanciamientoId === appConfigKey.keyIdentificadorDolares ? '$' : 'S/';
  return (
    <div className="horizontal-generico-scrollable common-summary-schedule">
      <div className="horizontal-generico-scrollable__wrapper">
        <Table borderAxis="none" className="summary-table" stickyHeader>
          <thead>
            <tr>
              <th className="align-middle text-start left-rounded" style={{ width: '50px' }}>PLAZOS</th>
              <th className="align-middle text-end" style={{ width: '150px' }}>SIMPLE {currencySymbol}</th>
              <th className="align-middle text-start" style={{ width: '64px' }}>{/*Botón VER*/}</th>
              <th className="align-middle text-end" style={{ width: '150px' }}>DOBLE {currencySymbol}</th>
              <th className="align-middle text-start" style={{ width: '64px' }}>{/*Botón VER*/}</th>
              <th className="align-middle text-end" style={{ width: '150px' }}>JUL-DIC</th>
              <th className="align-middle text-end" style={{ width: '150px' }}>MONTO</th>
              <th className="align-middle text-end right-rounded" style={{ width: '200px' }}>MONTO CUOTA DOBLE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => {
              const isSimpleChecked = (mem === undefined || mem === null || mem >= Number(GeneralHelper.QuitarComasAMiles(row.simple)));
              const isDobleChecked = (mem === undefined || mem === null || mem >= Number(GeneralHelper.QuitarComasAMiles(row.doble)));

              return (
                <tr key={row.plazo}>
                  <td className="text-start left-rounded">{row.plazo}</td>
                  <td className="text-end">{row.simple}</td>
                  <td className="text-start">
                    <button className={isSimpleChecked ? "common-circle-btn" : "common-circle-btn-denegado"}
                      onClick={() => { }}>
                      <img src={isSimpleChecked ? icoAproved : icoDenided} alt="estado" />
                      <span className="common-circle-btn-texto">VER</span>
                    </button>
                  </td>
                  <td className="text-end">{row.doble}</td>
                  <td className="text-start">
                    <button className={isDobleChecked ? "common-circle-btn" : "common-circle-btn-denegado"}
                      onClick={() => { }}>
                      <img src={isDobleChecked ? icoAproved : icoDenided} alt="estado" style={{ float: 'right' }} />
                      <span className="common-circle-btn-texto">VER</span>
                    </button>
                  </td>
                  <td className="text-end">{row.montoRango}</td>
                  <td className="text-end">{row.monto}</td>
                  <td className="text-end right-rounded">{row.montoCuotaDoble}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SummaryScheduleCard;