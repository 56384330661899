import { Table } from "@mui/joy";
import { DetailsScheduleCardProps } from "./interfaces";
import "./index.scss";

const DetailsScheduleCard = ({ data }: DetailsScheduleCardProps) => {
  return (
    <div className="horizontal-generico-scrollable common-details-schedule">
      <div className="horizontal-generico-scrollable__wrapper">
        <Table borderAxis="none" className="details-table" stickyHeader>
          <thead>
            <tr>
              <th className="align-middle text-start left-rounded" style={{ width: '80px' }}>CUOTA</th>
              <th className="align-middle text-start" style={{ width: '100px' }}>F. VENCE</th>
              <th className="align-middle text-end" style={{ width: '40px' }}>DÍAS</th>
              <th className="align-middle text-end" style={{ width: '140px' }}>SALDO CAPITAL</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>AMORT.</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>M. SEGURO</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>AMORT. TOTAL</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>INTERÉS</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>SEGURO DESG.</th>
              <th className="align-middle text-end" style={{ width: '80px' }}>CUOTA</th>
              <th className="align-middle text-end" style={{ width: '100px' }}>PORTES</th>
              <th className="align-middle text-end right-rounded" style={{ width: '100px' }}>PAGO TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => {
              return (
                <tr key={row.cuota}>
                  <td className="text-start left-rounded">{row.cuota}</td>
                  <td className="text-start">{row.fechaVencimiento}</td>
                  <td className="text-end">{row.dias}</td>
                  <td className="text-end">{row.saldoCapital}</td>
                  <td className="text-end">{row.amortizacion}</td>
                  <td className="text-end">{row.montoSeguro}</td>
                  <td className="text-end">{row.amortizacionTotal}</td>
                  <td className="text-end">{row.interes}</td>
                  <td className="text-end">{row.seguroDesgravamen}</td>
                  <td className="text-end">{row.montoCuota}</td>
                  <td className="text-end">{row.montoPortes}</td>
                  <td className="text-end right-rounded">{row.pagoTotal}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DetailsScheduleCard;