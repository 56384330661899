interface TitleCardProps {
  title: string;
  obligatorio?: boolean;
}

const TitleCard = ({ title = '', obligatorio = false }: TitleCardProps) => {
  return (
    <div className="row g-2 mb-2 align-items-center">
      <div className={`${obligatorio ? "col-lg-8 col-md-8 col-sm-12" : "col"} pb-4 `}>
        <span className="fw-bold">{title}</span>
      </div>
      {obligatorio && (
        <div className="col-lg-4 col-md-4 col-sm-12 pb-4 text-lg-end text-md-end text-sm-start">
          <span>Obligatorio</span>
          <span style={{ color: "red" }}> * </span>
        </div>
      )}
    </div>
  );
};

export default TitleCard;
