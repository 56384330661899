import SesionState from "../../../1.-shared/1.2.-layout/1.2.4-sessionState/sessionState";

const InformeComercialCardTitle = (props: any) => {
    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card card-title-start">
            <div className="row g-2 mb-2 align-items-center">
                <div style={{ display: 'flex' }}>
                    <div className="fw-bold cardTextTop" style={{ borderRightStyle: 'solid', borderRightWidth: '1px', paddingRight: '20px', marginRight: '20px' }}>Informe Comercial</div>
                    <div className="cardTextTop">{props.identification}</div>
                    <div style={{ width: '280px', marginLeft: 'auto' }}>
                        <SesionState />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformeComercialCardTitle;
