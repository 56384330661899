import { useEffect, useState } from "react";
import DetailsAdditionalMotorCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/detailsAdditionalMotorCard";
import { DatosDetailStepAdditionalMotorCard } from "./interfaces";


const DetailStepAdditionalMotorCard = () => {

  const [viewModel, setViewModel] = useState<DatosDetailStepAdditionalMotorCard | null>({ datosAdicionales: [{ variable: 'Variable', valor: "VALOR", valorTipo: "text", visible: true }] });

  useEffect(() => {

  }, []);

  if (!viewModel) return null;

  return (
    <>
      <DetailsAdditionalMotorCard
        className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details body-additional-bg mlr-0-pre-quotation"
        datosAdicionales={viewModel?.datosAdicionales}
      />
    </>
  );
};

export default DetailStepAdditionalMotorCard;
