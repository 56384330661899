import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SantanderLogoBlanco from '../../../../../assets/media/icons/SantanderLogoBlanco.svg';
import MasterManager from "../../../../services/origination/wapimaster.service";
import { appConfigKey, appSecurityConfiguration } from "../../../../../config/Config";
import IconHelper from "../../../../helpers/IconHelper";
import { useNavigate } from "react-router-dom";

const SubOptions = ({ subOptions, menuOption }: any) => {

  const navigate = useNavigate();

  const onClick = (subOption: any) => {
    var url = subOption.optioN_PARAM ? `${subOption.optioN_REF}/${subOption.optioN_PARAM}` : `${subOption.optioN_REF}`;
    navigate(url);
  };

  return (
    <ul className="sub-options">
      {subOptions.map((subOption: any, index: any) => (
        <li key={index} className="sub-option">
          <div className="sub-option-link" onClick={() => {
            appConfigKey.TituloBandejaPrincipal = menuOption + " - " + subOption.optioN_MENU;
            onClick(subOption)
          }}>{subOption.optioN_MENU}</div>
        </li>
      ))}
    </ul>
  );
};

const CustomNav = () => {

  let navigate = useNavigate();

  let DataGetMnMenuPerfil: any = null;
  const [window, setWindow] = useState(true);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    FnGetMnMenuPerfil(
      Number(localStorage.getItem('UserProfileMn') ? localStorage.getItem('UserProfileMn') : 0));
  }, [])

  /*Consulta de titular  */
  const FnGetMnMenuPerfil = async (identPerfil: number) => {
    
    if (!identPerfil) {
      navigate(`/`);
      return;
    }

    try {
      const response: any = await MasterManager.GetMnMenuPerfil();
      if (response?.status !== 200) {
        return;
      }
      const { content } = response.data;
      if (!content || content?.length === 0) {
        return;
      }

      DataGetMnMenuPerfil = response.data.content;
      setData(DataGetMnMenuPerfil);
    } catch {
      navigate(`/`);
    }
  };

  let openClose = () => {
    if (window === false) {
      setWindow(true);
    } else {
      setWindow(false);
    }
  };

  return (
    <div className="navbar-menu" style={{ width: !window ? "300px" : "4%" }}>
      <div className="burger" onClick={() => openClose()}>
        <img src={SantanderLogoBlanco} width={!window  ? "52px" : "100%"} alt="burger" />
        <li className="navbar__li TextPositionTituloNavbar" style={{ display: !window ? "inline-block" : "none" }}>Consumer</li>
      </div>
    
      <ul className="navbar__list ulCustomNav">
        {data.map((item: any, i: any) => {
          if (item.suB_MENU.length > 0) {
            return (
              <li className="navbar__li-box" key={i} style={{paddingLeft: !window  ? "27px" : "0px", justifyContent: !window  ? "start" : "center"}}>
                <span className="image-container">
                  <img src={IconHelper.BuscarIcon(item.optioN_ICON)} width={!window  ? "34px" : "100%"} alt={item.optioN_ICON} />
                </span>
                <ul className="navbar__list ulCustomNav">
                  <li key={item.idenT_MENU} className="navbar__li" style={{ display: !window  ? "inline-block" : "none" }}>
                    {item.optioN_MENU}
                    {<SubOptions menuOption={item.optioN_MENU} subOptions={item.suB_MENU} />}
                  </li>
                </ul>
              </li>
            );
          }

          return (
            <li key={i} className="navbar__li-box" style={{paddingLeft: !window  ? "27px" : "0px", justifyContent: !window  ? "start" : "center"}} onClick={() => navigate(item.optioN_REF)}>
              <span className="image-container">
                <img src={IconHelper.BuscarIcon(item.optioN_ICON)} width={!window  ? "34px" : "100%"} alt={item.optioN_ICON}/>
              </span>
              <ul className="navbar__list ulCustomNav">
                <li key={item.idenT_MENU} className="navbar__li" style={{ display: !window  ? "inline-block" : "none" }}>
                  {item.optioN_MENU}
                </li>
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomNav;

