import dayjs from "dayjs";

const TimeHelper = {

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY"
    ObtenerFormatoFechaPeru(fecha: string | Date) {
        const fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;
        let dia: any = fechaObjeto.getDate();
        let mes: any = fechaObjeto.getMonth() + 1;
        let año = fechaObjeto.getFullYear();

        // Asegurarse de que el día y el mes tengan dos dígitos
        dia = dia < 10 ? "0" + dia : dia;
        mes = mes < 10 ? "0" + mes : mes;

        let fechaFormateada = dia + "/" + mes + "/" + año;

        return fechaFormateada;

    },

    ObtenerFormatoFechaAmericana(fecha: string | null) {
        if (!fecha) return fecha;
        let fechaObjeto = new Date(fecha);
        let año = fechaObjeto.getFullYear();
        let mes: any = fechaObjeto.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que sumamos 1
        let dia: any = fechaObjeto.getDate();

        // Asegurarse de que el mes y el día tengan dos dígitos
        mes = mes < 10 ? "0" + mes : mes;
        dia = dia < 10 ? "0" + dia : dia;

        let fechaFormateada = `${año}-${mes}-${dia}`;

        return fechaFormateada;
    },

    ObtenerFormatoFechaAmericanaDayJs(fecha: dayjs.Dayjs) {
        return fecha.format("YYYY-MM-DD");
    },

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY HH:mm tt"
    ObtenerFormatoFechaHoraAMPM(fecha: string | Date) {
        const fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;

        let dia: any = fechaObjeto.getDate();
        let mes: any = fechaObjeto.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que sumamos 1
        let anio = fechaObjeto.getFullYear();
        let horas: any = fechaObjeto.getHours();
        let minutos: any = fechaObjeto.getMinutes();
        let ampm = "am";

        if (horas > 12) {
            ampm = "pm";
            horas = horas - 12;
        }
        else if (horas === 0) {
            horas = 12;
        }

        // Asegurarse de que el día y el mes tengan dos dígitos
        dia = dia < 10 ? "0" + dia : dia;
        mes = mes < 10 ? "0" + mes : mes;
        minutos = minutos < 10 ? "0" + minutos : minutos;

        let fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos} ${ampm}`;

        return fechaFormateada;
    },

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY HH:mm tt" en UTC -5
    ObtenerFormatoFechaHoraAMPMPeru(fecha: string | Date) {
        let fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;
        fechaObjeto.setHours(fechaObjeto.getHours() - 5); // UTC -5 Lima/Perú
        return TimeHelper.ObtenerFormatoFechaHoraAMPM(fechaObjeto);
    },

    /**
     * Convierte un string a Date
     * @param fechaStr formato 'dd/MM/yyyy hh:mm aa'. Ejemplo: '22/10/2024 07:25 PM'
     * @returns Date | null
     */
    ConvertirFecha(fechaStr: string): Date | null {
        if (!fechaStr) return null;

        const partes = fechaStr.split(' ');
        if (partes.length < 3) return null;

        const [diaMesAnio, horaMinuto, amPm] = partes;
        const [dia, mes, anio] = diaMesAnio.split('/');

        if (!dia || !mes || !anio || dia.length !== 2 || mes.length !== 2 || anio.length !== 4) return null;

        const hora = horaMinuto + ' ' + amPm;

        const horaConvertida = TimeHelper.ConvertirHora(hora);
        if (!horaConvertida) return null;

        try {
            return new Date(`${anio}-${mes}-${dia}T${horaConvertida}`);
        } catch {
            return null;
        }
    },

    /**
     * Convierte una hora con formato de 12 horas a formato de 24 horas
     * @param hora12 formato 'hh:mm aa'. Ejemplo '07:25 PM'
     * @returns string | null
     */
    ConvertirHora(hora12: string): string | null {
        const [horaMinuto, periodo] = hora12.split(' ');
        if (!horaMinuto || !periodo) return null;

        let [horas, minutos] = horaMinuto.split(':').map(Number);

        if (isNaN(horas) || isNaN(minutos)) return null;

        if (periodo.trim().toUpperCase() === 'PM' && horas < 12) {
            horas += 12;
        } else if (periodo.trim().toUpperCase() === 'AM' && horas === 12) {
            horas = 0;
        }

        return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:00`;
    },

    ObtenerFechaActual(addYear: number) {
        const fecha = new Date();
        const anio = fecha.getFullYear();
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes es zero-based, por lo que se suma 1
        const dia = String(fecha.getDate()).padStart(2, '0');
        const fechaActual = `${anio + addYear}-${mes}-${dia}`;
        return fechaActual;
    },
      
    ParseFechaUTC(fechaStr: string | null): Date | null {
      if (!fechaStr || fechaStr.trim() === "") {
        return null;
      }
    
      // Corregir el formato del offset si es necesario (de +0000 a +00:00)
      const fechaISO = fechaStr.replace(/([+-]\d{2})(\d{2})$/, "$1:$2");
    
      const fecha = new Date(fechaISO);
    
      return isNaN(fecha.getTime()) ? null : new Date(Date.UTC(
        fecha.getUTCFullYear(),
        fecha.getUTCMonth(),
        fecha.getUTCDate(),
        fecha.getUTCHours(),
        fecha.getUTCMinutes(),
        fecha.getUTCSeconds(),
        fecha.getUTCMilliseconds()
      ));
    }

}

export default TimeHelper;