import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { usePreQuotationSteps } from "../../providers";
import { Button } from "@mui/joy";

const ButtonStepsCard = () => {
  const {
    step,
    setStep,
    loadingStep
  } = usePreQuotationSteps();

  const leftButtons = [
    { label: "Regresar", disabled: loadingStep, condition: step === 2, onClick: () => setStep((prev) => prev - 1) },
  ].filter(btn => btn.condition);

  const rightButtons = [
    { label: "Calcular", disabled: loadingStep, condition: step < 2, onClick: () => setStep((prev) => prev + 1) },
    { label: "Crear Solicitud", disabled: loadingStep, condition: step === 2, onClick: () => { } },
    { label: "Cerrar", disabled: loadingStep, condition: step === 2, onClick: () => { } },
  ].filter(btn => btn.condition);

  const getColumnClass = (buttons: any[]) =>
    buttons.length === 0 ? "col" : buttons.length === 1 ? "col-3" : buttons.length === 2 ? "col-6" : "col-12";

  const leftClassName = getColumnClass(leftButtons);
  const rightClassName = leftClassName === "col-12" ? "col-12" : getColumnClass(rightButtons);

  return (
    <div className="row g-2 mb-2 align-items-center pb-4 justify-content-between">
      {/* Botón Izquierdo */}
      <div className={leftClassName}>
        {leftButtons.map(({ label, disabled, onClick }, index) => (
          <Button key={index} className="button-pre-quotation secondary me-4 mb-2" disabled={disabled} onClick={onClick} size="lg">
            <div className="d-flex align-items-center w-100"><ChevronLeft /> <div style={{ width: 'inherit' }}>{label}</div></div>
          </Button>
        ))}
      </div>

      {/* Botones Derechos */}
      <div className={`${rightClassName} text-end`}>
        {rightButtons.map(({ label, disabled, onClick }, index) => (
          <Button key={index} className="button-pre-quotation primary ms-4 mb-2" disabled={disabled} onClick={onClick} size="lg">
            <div className="d-flex align-items-center w-100"> <div style={{ width: 'inherit' }}>{label}</div><ChevronRight /></div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ButtonStepsCard;