import { createContext, useEffect, useState, ReactNode, useContext } from "react";
import { usePreQuotationSteps } from "./preQuotationStepsProvider";
import WindowHelper from "../../../../helpers/WindowHelper";

interface PreQuotationStepTwoContextType {
  datosFinancierosViewModel: any;
  setDatosFinancierosViewModel: (data: any) => void;
  newDatosFinanciamiento: any;
  setNewDatosFinanciamiento: (data: any) => void;
}

export const PreQuotationStepTwoContext = createContext<PreQuotationStepTwoContextType | null>(null);

interface PreQuotationStepTwoProviderProps {
  children: ReactNode;
}

export const PreQuotationStepTwoProvider = ({ children }: PreQuotationStepTwoProviderProps) => {
  const { step } = usePreQuotationSteps();
  const [datosFinancierosViewModel, setDatosFinancierosViewModel] = useState<any>({ asd: "asd" });
  const [newDatosFinanciamiento, setNewDatosFinanciamiento] = useState<any>(null);

  useEffect(() => {
    // Lógica adicional si es necesaria
  }, []);

  useEffect(() => {
    WindowHelper.ScrollToTop();
  }, []);

  return (
    <PreQuotationStepTwoContext.Provider value={{
      datosFinancierosViewModel,
      setDatosFinancierosViewModel,
      newDatosFinanciamiento,
      setNewDatosFinanciamiento
    }}>
      {children}
    </PreQuotationStepTwoContext.Provider>
  );
};

export const usePreQuotationStepTwo = (): PreQuotationStepTwoContextType => {
  const context = useContext(PreQuotationStepTwoContext);
  if (!context) {
    throw new Error("usePreQuotationStepTwo debe usarse dentro de PreQuotationStepTwoProvider");
  }
  return context;
};