import React from 'react';
import './index.scss';
import Ellipse1 from '../../../../../../assets/media/icons/Ellipse1.svg';
import StepCheck from '../../../../../../assets/media/icons/step-check.svg';
import StepWhite from '../../../../../../assets/media/icons/step-white.svg';
import Line1 from '../../../../../../assets/media/icons/Line1.svg';
import Line2 from '../../../../../../assets/media/icons/Line2.svg';

interface ICardStepsProps {
  step?: number;
  totalSteps?: number;
}

const Steps = ({ step = 1, totalSteps = 5 }: ICardStepsProps) => {

  return (
    <div className="row g-2 mb-2 align-items-center">
      <div className="col-lg-12" style={{ textAlign: 'center' }}>
        {[...Array(totalSteps)].map((_, index) => {
          const stepNumber = index + 1;
          const isCurrent = step === stepNumber;
          const isPending = (step - 1) < stepNumber;

          return (
            <React.Fragment key={stepNumber}>
              <div className="common-steps-img">
                <img src={isCurrent ? Ellipse1 : isPending ? StepWhite : StepCheck} alt={`Paso ${stepNumber}`} />
                <div className="common-steps-img-center">{isCurrent ? stepNumber : ''}</div>
              </div>
              {stepNumber < totalSteps && (
                <img src={isPending ? Line1 : Line2} alt={`Línea después de ${stepNumber}`} />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Steps;
