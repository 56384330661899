import TitleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/titleCard";
import { usePreQuotationStepTwo } from "../../../18.10.-base/providers";
import DetailsScheduleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/detailsScheduleCard";
import GeneralHelper from "../../../../../helpers/GeneralHelper";

const DatosDetalleCronogramaCard = () => {
  const { } = usePreQuotationStepTwo();

  const cronograma = Array.from({ length: 12 }, (_, index) => ({
    cuota: index + 1,
    fechaVencimiento: `2025-${(index % 12) + 1}-10`,
    dias: GeneralHelper.AgregarComasAMiles(Math.floor(Math.random() * 30) + 1),
    saldoCapital: GeneralHelper.AgregarComasAMiles((10000 - index * 500).toFixed(2)),
    amortizacion: GeneralHelper.AgregarComasAMiles((500).toFixed(2)),
    montoSeguro: GeneralHelper.AgregarComasAMiles((50).toFixed(2)),
    amortizacionTotal: GeneralHelper.AgregarComasAMiles((550).toFixed(2)),
    interes: GeneralHelper.AgregarComasAMiles((Math.random() * 100).toFixed(2)),
    seguroDesgravamen: GeneralHelper.AgregarComasAMiles((Math.random() * 20).toFixed(2)),
    montoCuota: GeneralHelper.AgregarComasAMiles((600).toFixed(2)),
    montoPortes: GeneralHelper.AgregarComasAMiles((Math.random() * 10).toFixed(2)),
    pagoTotal: GeneralHelper.AgregarComasAMiles((650).toFixed(2)),
  }));

  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card mlr-0-pre-quotation">
      <TitleCard title={"CRONOGRAMA"} obligatorio={false} />
      <DetailsScheduleCard data={cronograma} />
    </div>
  );
};

export default DatosDetalleCronogramaCard;
