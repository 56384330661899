import { appConfigKey } from "../../../../../../config/Config";
import TitleCard from "../../../../1.-shared/1.7.-component/1.7.5.-card/titleCard";
import { NumberInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/numberInput";
import { usePreQuotationStepOne } from "../../../18.10.-base/providers";
import { SelectInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/selectInput";
import { SwitchInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/switchInput";
import { MoneyInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/moneyInput";
import { SimpleInput } from "../../../../1.-shared/1.7.-component/1.7.3.-input/simpleInput";

const DatosPersonalesDelTitularCard = () => {

  const {
    datosCliente,
    setDatosCliente,
  } = usePreQuotationStepOne();

  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card mlr-0-pre-quotation">
      <TitleCard title={datosCliente.tipoDocumentoId == appConfigKey.keyIdRUC ? "DATOS EMPRESA / NEGOCIO" : "DATOS CLIENTE"} obligatorio={true} />
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-2 pt-4">
          <SelectInput
            title={"Estado del Vehículo"}
            options={[]}
            value={datosCliente.estadoVehiculoId}
            disabled={false}
            onChange={(event: any, newValue: any) => setDatosCliente({ estadoVehiculoId: newValue })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <SelectInput
            title={"Tipo de doc. titular"}
            options={[]}
            value={datosCliente.tipoDocumentoId}
            disabled={false}
            onChange={(event: any, newValue: any) => setDatosCliente({ tipoDocumentoId: newValue })}
          />
        </div>

        <div className="col-lg-4 pt-4">
          <NumberInput
            title={"Número documento"}
            placeholder=""
            value={datosCliente.numeroDocumento?.trim()}
            disabled={false}
            onChange={(event: any) => setDatosCliente({ numeroDocumento: event.target.value })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <SelectInput
            title={"Estado civil"}
            options={[{ id: 1, description: 'Soltero' }, { id: 2, description: 'Casado' }, { id: 3, description: 'Conviviente' }]}
            value={datosCliente.estadoCivilId}
            disabled={false}
            onChange={(event: any, newValue: any) => setDatosCliente({ estadoCivilId: newValue })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <SelectInput
            title={"Categoria laboral"}
            options={[]}
            value={datosCliente.categoriaLaboralId}
            disabled={false}
            onChange={(event: any, newValue: any) => setDatosCliente({ categoriaLaboralId: newValue })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <NumberInput
            name={"telefono"}
            title={"Teléfono"}
            value={datosCliente.telefono}
            longNumber={9}
            onChange={(event: any) => setDatosCliente({ telefono: event.target.value })}
          />
        </div>

        <div className="col-lg-4 pt-4">
          <SimpleInput
            name={"correo"}
            title={"Correo"}
            value={datosCliente.correo}
            onChange={(event: any) => setDatosCliente({ correo: event.target.value })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <MoneyInput
            title={"Ingresos netos"}
            placeholder=""
            value={datosCliente.ingresosNetos}
            disabled={false}
            onChange={(event: any) => setDatosCliente({ ingresosNetos: event.target.value })}
          />
        </div>

        <div className="col-lg-2 pt-4">
          <SwitchInput
            name={"esAnualizado"}
            title={"Anualizado solo 5ta"}
            checked={datosCliente.esAnualizado}
            disabled={false}
            onChange={(event: any) => setDatosCliente({ esAnualizado: event.target.checked })}
          />
        </div>
        {datosCliente?.esAnualizado &&
          (
            <div className="col-lg-2 pt-4">
              <MoneyInput
                title={"Ingreso Anualizados"}
                placeholder=""
                value={datosCliente.ingresosAnualizados}
                disabled={true}
              // onChange={(event: any) => setDatosCliente({ ingresosAnualizados: event.target.value })}
              />
            </div>
          )
        }

      </div>
    </div>
  );
};

export default DatosPersonalesDelTitularCard;
