import "../6.10.-base/analysProfile.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import SesionState from '../../1.-shared/1.2.-layout/1.2.4-sessionState/sessionState';

function CardTitleStart(props:any) {
    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card card-title-start">
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-6">
                    <span className="fw-bold cardTextTop cardTop">Evaluación crediticia&nbsp;- Scoring</span>
                </div>
                <div className="col-lg-4">
            </div>
            <div className="col-lg-2">
                <SesionState />
            </div>
            </div>
        </div>
    );
};

export default CardTitleStart;