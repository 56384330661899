import "./index.scss";
import { useCallback, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextWithTooltip } from "../../../../1.-shared/1.7.-component/1.7.4.-text/textWithTooltip";
import { DatoAdicionalMotor, DetailsAdditionalMotorCardProps } from "./interfaces";
import { obtenerValorDatoAdicional } from "../../../../../helpers/ModelicaMotorHelper";

const MAX_DATO_ADICIONAL = 16;

const DetailsAdditionalMotorCard = ({ datosAdicionales, mostrarOcultos, className }: DetailsAdditionalMotorCardProps) => {
  const [expanded, setExpanded] = useState(true);

  const checkVisible = useCallback((item: DatoAdicionalMotor): boolean => {
    return item.visible && (item.valor !== null && item.valor !== undefined && item.valor.toString().trim() !== '');
  }, [])

  if (!datosAdicionales || datosAdicionales.length === 0) return null;

  const datosFiltrados = mostrarOcultos ? datosAdicionales : datosAdicionales.filter(checkVisible);
  if (datosFiltrados.length === 0) return null;

  const datosCabecera = datosFiltrados.slice(0, MAX_DATO_ADICIONAL);
  const datosDetalle = datosFiltrados.slice(MAX_DATO_ADICIONAL);
  const showDetalle = datosDetalle.length > 0;

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={{
        borderRadius: "10px !important",
        border: "none",
        boxShadow: "unset",
        color: "unset",
        "&::before": { display: "none" }
      }}
      className={className || "border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details body-additional-bg"}>
      <AccordionSummary
        expandIcon={showDetalle ? <ExpandMoreIcon /> : null}
        sx={{
          // cursor: showDetalle ? "pointer" : "text",
          // pointerEvents: showDetalle ? "auto" : "none",
          padding: "0 !important",
          position: "relative",
          "& .MuiAccordionSummary-content": {
            userSelect: "text",
            cursor: showDetalle ? "pointer" : "text",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            right: "0",
            top: "10px",
            transform: "translateY(-50%) rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          },
          "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
            transform: "translateY(-50%) rotate(180deg)",
          },
        }}
      >
        <div className="col">
          <div className="row g-2 align-items-center item-body-details">
            {datosCabecera.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-12">
                <div className="row g-2">
                  <div className="col-lg-6 col-md-6">
                    <span>{item.variable}</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <TextWithTooltip text={obtenerValorDatoAdicional(item)} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails
        sx={{ padding: "0 !important" }}
      >
        <div className="col pb-3 pb-md-2">
          <div className="row g-2 align-items-center item-body-details">
            {datosDetalle.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-12">
                <div className="row g-2">
                  <div className="col-lg-6 col-md-6">
                    <span>{item.variable}</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <TextWithTooltip text={obtenerValorDatoAdicional(item)} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion >
  );
};

export default DetailsAdditionalMotorCard;
