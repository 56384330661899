import { createContext, useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";
import { getPreQuotationStepOneDefault, IPreQuotationStepOne } from "../interfaces/PreQuotationStepOneInterfaces";

interface PreQuotationStepsContextType {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  loadingStep: boolean;
  setLoadingStep: Dispatch<SetStateAction<boolean>>;
  viewModelStepOne: IPreQuotationStepOne;
  viewModelStepTwo: any;
  setViewModelStepOne: Dispatch<SetStateAction<IPreQuotationStepOne>>;
  setViewModelStepTwo: Dispatch<SetStateAction<any>>;
}

export const PreQuotationStepsContext = createContext<PreQuotationStepsContextType | null>(null);

interface PreQuotationStepsProviderProps {
  children: ReactNode;
}

export const PreQuotationStepsProvider = ({ children }: PreQuotationStepsProviderProps) => {
  const [step, setStep] = useState<number>(1);
  const [loadingStep, setLoadingStep] = useState<boolean>(false);

  // PASO 1
  const [viewModelStepOne, setViewModelStepOne] = useState<IPreQuotationStepOne>(getPreQuotationStepOneDefault);

  // PASO 2
  const [viewModelStepTwo, setViewModelStepTwo] = useState<any>({});

  const contextValue = {
    step,
    setStep,
    loadingStep,
    setLoadingStep,
    viewModelStepOne,
    viewModelStepTwo,
    setViewModelStepOne,
    setViewModelStepTwo
  }

  return (
    <PreQuotationStepsContext.Provider value={contextValue}>
      {children}
    </PreQuotationStepsContext.Provider>
  );
};

export const usePreQuotationSteps = (): PreQuotationStepsContextType => {
  const context = useContext(PreQuotationStepsContext);
  if (!context) {
    throw new Error("usePreQuotationSteps debe usarse dentro de PreQuotationStepsProvider");
  }
  return context;
};
