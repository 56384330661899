import DatosPersonalesDelTitularCard from "../components/DatosPersonalesDelTitularCard";
import DatosPersonalesDelConyugeCard from "../components/DatosPersonalesDelConyugeCard";
import { usePreQuotationStepOne } from "../../18.10.-base/providers";
import { appConfigKey } from "../../../../../config/Config";

export const TitularSection = () => {

  const {
    datosCliente,
  } = usePreQuotationStepOne();

  const showConyuge = [appConfigKey.keyIdEstadoCivilCasado, appConfigKey.keyIdEstadoCivilConviviente].includes(datosCliente.estadoCivilId);

  return (
    <>
      <DatosPersonalesDelTitularCard />
      {showConyuge && (<DatosPersonalesDelConyugeCard />)}
    </>
  );
};