import { FormControl, FormLabel, Input } from "@mui/joy";

const obligatorioStyle = {
  color: "red",
  marginLeft: '5px'
};

export const NumberDecimalInput = (props: any) => {
  return (
    <FormControl>
      {props.title !== undefined && <FormLabel>{props.title}<span style={obligatorioStyle}>*</span></FormLabel>}
      <Input
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(event: any) => {
          const value = event.target.value;
          let numberOfDecimalsDefault = 2;
          if (props.numberOfDecimals) {
            numberOfDecimalsDefault = props.numberOfDecimals
          }
          const regex = new RegExp(`^\\d*\\.?\\d{0,${numberOfDecimalsDefault}}$`);
          const validation = regex.test(value);

          if (props.longNumber !== undefined) {
            if (props.longNumber < value.length) {
              event.preventDefault();
              return;
            }
          }

          if (!validation) {
            event.preventDefault();
            return;
          }

          props.onChange(event);
        }}
        disabled={props.disabled}
        startDecorator={props.startDecorator}
      />
    </FormControl>
  );
};