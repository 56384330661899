import { ReactNode } from "react";

interface ICardBaseProps {
  children: ReactNode;
}

const BaseCard = ({ children }: ICardBaseProps) => {
  return (
    <div className="content-pre-quotation">
      {children}
    </div>
  );
};

export default BaseCard;
