import dayjs from "dayjs";
import { appAplicacionMotorConfig, appCanalMotorConfig, appDefaultValues } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import ParameterHelper from "../../../../helpers/ParameterHelper";

export interface ParametrosGlobalesInterface {
    TipoLlamada: number,
}

export interface DatosClienteInterface {
    TipoDocumento: string,
    NumeroDocumento: string,
    Genero: number,
    EstadoCivil: number,
    FechaNacimiento: string,
    ActividadEconomica: number,
    Magnitud: number,
}

export interface DatosConyugeInterface {
    TipoDocumento: string,
    NumeroDocumento: string,
    Genero: number,
    FechaNacimiento: string
}

export interface IngresoInterface {
    CategoriaLaboral: number,
    Ruc: string,
    FechaInicioActividad: string,
    Moneda: number,
    IngresoMensual: number,
    IngresoAnualizado: number,
    OtrosIngresos: any[]
}

export interface IngresoDeclaradoInterface {
    IngresoTitular: IngresoInterface,
    IngresoConyuge: IngresoInterface | null
}

export interface ModelicaStepOneDataInteface {
    DatosCliente: DatosClienteInterface,
    DatosConyuge: DatosConyugeInterface | null
    IngresoDeclarado: IngresoDeclaradoInterface,
}


export interface CuotaInicialInterface {
    MonedaId: number,
    Monto: number,
    Porcentaje: number
}

export interface ModelicaStepTwoDataInteface {
    MonedaFinanciamiento: number,
    TipoCambio: number,
    ValorVehiculoUSD: number,
    Marca: string,
    Modelo: string,
    Version: string,
    TipoVehiculo: number,
    FabricacionYear: string,
    CuotaInicial: CuotaInicialInterface,
    MontoFinanciar: number,
    Gastos: any,
    AplicaBono: number
}
export interface ModelicaStepThreeDataInteface {
    Producto: number,
    SeguroVehicular: any,
    SeguroDesgravamen: any,
    Segmento: number,
    Gastos: any,
    MontoFinanciar: number,
    ValorVehiculoDolares: number,
    MontoInicial: number,
    TipoGPS: number,
    Campania: any | null | undefined
}

export const ModelicaBodyDefault = {
    "request": {
        "Parametros_Globales": {
            "canal": appCanalMotorConfig.Santander,
            "aplicacion": appAplicacionMotorConfig.Santander,
            "tipo_Identificador": appDefaultValues.NumberDefault,
            "identificador": appDefaultValues.StringEmpty,
            "tipo_Evaluacion": appDefaultValues.NumberDefault,
            "tipo_llamada": appDefaultValues.NumberDefault
        },
        "datos_Evaluacion": {
            "datos_cliente": {
                "tipo_documento": appDefaultValues.StringEmpty,
                "numero_documento": appDefaultValues.StringEmpty,
                "genero": appDefaultValues.NumberDefault,
                "estado_civil": appDefaultValues.NumberDefault,
                "fecha_nacimiento": appDefaultValues.StringEmpty,
                "actividad_Economica": appDefaultValues.NullDefault,
                "magnitud": appDefaultValues.NullDefault,
                "domicilio": {
                    "departamento": appDefaultValues.StringEmpty,
                    "provincia": appDefaultValues.StringEmpty,
                    "distrito": appDefaultValues.StringEmpty,
                    "ubigeo": appDefaultValues.StringEmpty,
                    "dirección": appDefaultValues.StringEmpty
                }
            },
            "datos_conyugue": {
                "tipo_documento": appDefaultValues.NumberDefault,
                "numero_documento": appDefaultValues.StringEmpty,
                "genero": appDefaultValues.NumberDefault,
                "fecha_nacimiento": appDefaultValues.StringEmpty
            },
            "ingreso_declarado": {
                "titular": {
                    "categoria_Laboral": appDefaultValues.NumberDefault,
                    "ruc": appDefaultValues.StringEmpty,
                    "fecha_Inicio_Actividad": appDefaultValues.StringEmpty,
                    "moneda": appDefaultValues.NumberDefault,
                    "ingreso_Mensual": appDefaultValues.NumberDefault,
                    "ingreso_Anualizado": appDefaultValues.NumberDefault,
                    "otros_Ingresos": appDefaultValues.ArrayDefault
                },
                "conyugue": {
                    "categoria_Laboral": appDefaultValues.NumberDefault,
                    "ruc": appDefaultValues.StringEmpty,
                    "fecha_Inicio_Actividad": appDefaultValues.StringEmpty,
                    "moneda": appDefaultValues.NumberDefault,
                    "ingreso_Mensual": appDefaultValues.NumberDefault,
                    "ingreso_Anualizado": appDefaultValues.NumberDefault,
                    "otros_Ingresos": appDefaultValues.ArrayDefault
                }
            },
            "datos_canal": {
                "concesionario": appDefaultValues.NullDefault,
                "sucursal": appDefaultValues.NullDefault,
                "vendedor": appDefaultValues.NullDefault,
                "ejecutivo": appDefaultValues.NullDefault
            },
            "datos_financiamiento": {
                "moneda_financiamiento": appDefaultValues.NumberDefault,
                "tipo_cambio": appDefaultValues.NumberDefault,
                "aliado": appDefaultValues.NumberDefault,
                "valor_vehiculo_USD": appDefaultValues.NumberDefault,
                "marca": appDefaultValues.StringEmpty,
                "modelo": appDefaultValues.StringEmpty,
                "version": appDefaultValues.StringEmpty,
                "tipo_GPS": appDefaultValues.NumberDefault,
                "segmento": appDefaultValues.NumberDefault,
                "tipo_vehiculo": appDefaultValues.NumberDefault,
                "ano_fabricacion": appDefaultValues.StringEmpty,
                "cuota_inicial": {
                    "moneda": appDefaultValues.NumberDefault,
                    "monto": appDefaultValues.NumberDefault,
                    "porcentaje": appDefaultValues.NumberDefault
                },
                "bono": {
                    "flag_Bono": 1,
                    "moneda": appDefaultValues.NumberDefault,
                    "monto": appDefaultValues.NumberDefault,
                    "tipo_Bono": appDefaultValues.NumberDefault,
                    "campana_comercial": appDefaultValues.StringEmpty,
                },
                "gastos": {
                    "total_gastos": appDefaultValues.NumberDefault,
                    "detalle_gastos": appDefaultValues.ArrayDefault
                },
                "monto_a_financiar": appDefaultValues.NumberDefault,
                "seguros": {
                    "seguro_vehicular": {
                        "compañia": appDefaultValues.StringEmpty,
                        "tasa": appDefaultValues.StringEmpty
                    },
                    "seguro_desgravamen": {
                        "compañia": appDefaultValues.StringEmpty,
                        "tasa": appDefaultValues.StringEmpty
                    }
                }
            },
            "modificacion_Deudas_Externas": {
                "flag_Modificacion_Deudas_Externas": 0,
                "deudas_Titular": {
                    "lineas": {
                        "linea_original": {
                            "monto": 0
                        },
                        "linea_modificada": {
                            "monto": 0
                        }
                    },
                    "deudas": {
                        "deuda_original": [],
                        "deuda_modificada": []
                    }
                },
                "deudas_Conyugue": {
                    "lineas": {
                        "linea_original": {
                            "monto": 0
                        },
                        "linea_modificada": {
                            "monto": 0
                        }
                    },
                    "deudas": {
                        "deuda_original": [],
                        "deuda_modificada": []
                    }
                }
            },
            "producto": appDefaultValues.NumberDefault
        }
    }
};



export const ModelicaLoadStepOneData = (modelicaBody: any, modelicaStepOneData: ModelicaStepOneDataInteface, solicitudData: any) => {

    const modelicaLoadBody = { ...modelicaBody };

    const { request } = modelicaLoadBody;

    const { Parametros_Globales } = request;

    Parametros_Globales.tipo_Identificador = 2;
    Parametros_Globales.identificador = ParameterHelper.GetSolicitudeIdentifier(solicitudData?.idenT_SOLICITUD);
    Parametros_Globales.tipo_Evaluacion = 1;
    Parametros_Globales.tipo_llamada = 1;

    const { datos_Evaluacion } = request;

    const { datos_cliente } = datos_Evaluacion;
    const { DatosCliente } = modelicaStepOneData;

    datos_cliente.tipo_documento = DatosCliente.TipoDocumento;
    datos_cliente.numero_documento = DatosCliente.NumeroDocumento;
    datos_cliente.genero = DatosCliente.Genero;
    datos_cliente.estado_civil = DatosCliente.EstadoCivil;
    datos_cliente.fecha_nacimiento = DatosCliente.FechaNacimiento;
    datos_cliente.actividad_Economica = DatosCliente.ActividadEconomica;
    datos_cliente.magnitud = DatosCliente.Magnitud;

    const { DatosConyuge } = modelicaStepOneData;

    if (DatosConyuge === null) {
        datos_Evaluacion.datos_conyugue = null;
    } else {
        datos_Evaluacion.datos_conyugue = {};
        datos_Evaluacion.datos_conyugue.tipo_documento = DatosConyuge.TipoDocumento;
        datos_Evaluacion.datos_conyugue.numero_documento = DatosConyuge.NumeroDocumento;
        datos_Evaluacion.datos_conyugue.genero = DatosConyuge.Genero;
        datos_Evaluacion.datos_conyugue.fecha_nacimiento = DatosConyuge.FechaNacimiento;
    }

    const { ingreso_declarado } = datos_Evaluacion;
    const { IngresoDeclarado } = modelicaStepOneData;

    const { titular } = ingreso_declarado;
    const { IngresoTitular } = IngresoDeclarado;

    titular.categoria_Laboral = IngresoTitular.CategoriaLaboral;
    titular.ruc = IngresoTitular.Ruc;
    titular.fecha_Inicio_Actividad = IngresoTitular.FechaInicioActividad;
    titular.moneda = IngresoTitular.Moneda;
    titular.ingreso_Mensual = IngresoTitular.IngresoMensual;
    titular.ingreso_Anualizado = IngresoTitular.IngresoAnualizado;
    titular.otros_Ingresos = IngresoTitular.OtrosIngresos;

    const { IngresoConyuge } = IngresoDeclarado;

    if (IngresoConyuge === null) {
        ingreso_declarado.conyugue = null;
    } else {
        ingreso_declarado.conyugue = {};
        ingreso_declarado.conyugue.categoria_Laboral = IngresoConyuge.CategoriaLaboral;
        ingreso_declarado.conyugue.ruc = IngresoConyuge.Ruc;
        ingreso_declarado.conyugue.fecha_Inicio_Actividad = IngresoConyuge.FechaInicioActividad;
        ingreso_declarado.conyugue.moneda = IngresoConyuge.Moneda;
        ingreso_declarado.conyugue.ingreso_Mensual = IngresoConyuge.IngresoMensual;
        ingreso_declarado.conyugue.ingreso_Anualizado = IngresoConyuge.IngresoAnualizado;
        ingreso_declarado.conyugue.otros_Ingresos = IngresoConyuge.OtrosIngresos;
    }

    return modelicaLoadBody;
};

export const ModelicaLoadStepTwoData = (modelicaBody: any, modelicaStepTwoData: ModelicaStepTwoDataInteface) => {

    const modelicaLoadBody = { ...modelicaBody };

    const { request } = modelicaLoadBody;

    const { Parametros_Globales } = request;

    Parametros_Globales.tipo_llamada = 2;

    const { datos_Evaluacion } = request;
    const { datos_financiamiento } = datos_Evaluacion;

    datos_financiamiento.moneda_financiamiento = modelicaStepTwoData.MonedaFinanciamiento;
    datos_financiamiento.tipo_cambio = modelicaStepTwoData.TipoCambio;
    datos_financiamiento.valor_vehiculo_USD = modelicaStepTwoData.ValorVehiculoUSD;
    datos_financiamiento.marca = modelicaStepTwoData.Marca;
    datos_financiamiento.modelo = modelicaStepTwoData.Modelo;
    datos_financiamiento.version = modelicaStepTwoData.Version;
    datos_financiamiento.tipo_vehiculo = modelicaStepTwoData.TipoVehiculo;
    datos_financiamiento.ano_fabricacion = modelicaStepTwoData.FabricacionYear;
    datos_financiamiento.monto_a_financiar = modelicaStepTwoData.MontoFinanciar;
    datos_financiamiento.cuota_inicial = {
        moneda: modelicaStepTwoData.CuotaInicial.MonedaId,
        monto: modelicaStepTwoData.CuotaInicial.Monto,
        porcentaje: modelicaStepTwoData.CuotaInicial.Porcentaje
    };
    datos_financiamiento.gastos = modelicaStepTwoData.Gastos
    datos_financiamiento.bono = {
        "flag_Bono": modelicaStepTwoData.AplicaBono,
        "moneda": 0,
        "monto": 0,
        "tipo_Bono": 0,
        "campana_comercial": ""
    };

    return modelicaLoadBody;
};

export const ModelicaLoadStepThreeData = (modelicaBody: any, modelicaStepThreeData: ModelicaStepThreeDataInteface) => {
    const modelicaLoadBody = { ...modelicaBody };

    const { request } = modelicaLoadBody;
    const { Parametros_Globales } = request;

    Parametros_Globales.tipo_llamada = 3;

    const { datos_Evaluacion } = request;

    datos_Evaluacion.producto = modelicaStepThreeData.Producto;

    const { datos_financiamiento } = datos_Evaluacion;

    datos_financiamiento.seguros = {
        "seguro_vehicular": {
            "compañia": modelicaStepThreeData.SeguroVehicular.company,
            "tasa": GeneralHelper.ParseString(modelicaStepThreeData.SeguroVehicular.rate),
        },
        "seguro_desgravamen": {
            "compañia": modelicaStepThreeData.SeguroDesgravamen.company,
            "tasa": GeneralHelper.ParseString(modelicaStepThreeData.SeguroDesgravamen.rate),
        },
    };

    datos_financiamiento.gastos = {
        "detalle_gastos": modelicaStepThreeData.Gastos,
        "total_gastos": 0.00
    };

    datos_financiamiento.segmento = modelicaStepThreeData.Segmento;
    datos_financiamiento.monto_a_financiar = modelicaStepThreeData.MontoFinanciar;
    datos_financiamiento.valor_vehiculo_USD = modelicaStepThreeData.ValorVehiculoDolares;
    datos_financiamiento.tipo_GPS = modelicaStepThreeData.TipoGPS;

    const { cuota_inicial } = datos_financiamiento;

    cuota_inicial.monto = modelicaStepThreeData.MontoInicial;

    const { bono } = datos_financiamiento;
    
    bono.campana_comercial = modelicaStepThreeData.Campania?.description ?? appDefaultValues.StringEmpty;

    return modelicaLoadBody;
};