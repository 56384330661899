import React, { useEffect } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/joy";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs from "dayjs";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { appConfigKey } from "../../../../../config/Config";

const CardOtrosIngresos = ({ otrosIngresos, setOtrosIngresos, handleReevaluar, esModoLectura, esTitularJuridico, titular }: any) => {

  const handleChangeComentario = (indexItem: number, valor: any) => {
    const newOtrosIngresos = otrosIngresos.map((item: any, index: number) => {
      if (index === indexItem) {
        item.comentario = valor;
      }
      return item;
    });
    setOtrosIngresos(newOtrosIngresos);
  }


  const handleChangeIngreso = (indexItem: number, valor: any) => {

    const ingresoNeto = GeneralHelper.QuitarComasAMiles(valor)
    if (!(/^\d*\.?\d{0,2}$/.test(ingresoNeto))) {
      return;
    }

    const newOtrosIngresos = otrosIngresos.map((item: any, index: number) => {
      const newIngresoAnulizado = (item.ingresoAnualizado && (item.montoMinimoAnualizado <= Number(ingresoNeto))) ? Number(((Number(ingresoNeto) * 14) / 12).toFixed(2)) : 0;
      if (index === indexItem) {
        item.ingresoNeto = ingresoNeto;
        item.totalAnualizado = newIngresoAnulizado;
      }
      return item;
    });
    setOtrosIngresos(newOtrosIngresos);

  }

  const handleChangeAnualizado = (indexItem: number, estado: boolean) => {
    const newOtrosIngresos = otrosIngresos.map((item: any, index: number) => {
      if (index === indexItem) {
        item.ingresoAnualizado = estado;
        if (estado) {
          item.totalAnualizado = Number(Number(item.ingresoNeto) ? ((Number(item.ingresoNeto) * 14) / 12).toFixed(2) : 0);
        } else {
          item.totalAnualizado = 0;
        }
      }
      return item;
    });
    setOtrosIngresos(newOtrosIngresos);
  }

  const [esModoLecturaOtrosIngresos, setEsModoLecturaOtrosIngresos] = React.useState(false);

  useEffect(() => {
    setEsModoLecturaOtrosIngresos(!esModoLectura ? false : esModoLectura)
  }, [otrosIngresos])

  return (
    <>
      {otrosIngresos.length > 0 && otrosIngresos.map((item: any, index: number) => {
        return (
          <>
            {index === 0 &&
              <div className="row g-2 mb-2 align-items-center borderOtherIncome">
                <div className="col-lg-4 textLabelHeadboard">
                  <span className="fw-bold">OTROS INGRESOS</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth">
                </div>
              </div>
            }

            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Moneda</FormLabel>
                  <Input
                    name="moneda"
                    placeholder="Soles"
                    value={item.moneda}
                    disabled={true}
                  />
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>{item.categoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres ? 'Utilidad neta S/' : 'Ingreso neto S/'}<span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="ingresoNeto"
                    placeholder="Ej: 00000000"
                    value={GeneralHelper.NumeroConCommas(item.ingresoNeto)}
                    onChange={(event) => handleChangeIngreso(index, event.target.value)}
                    disabled={esModoLecturaOtrosIngresos || (esTitularJuridico || (!esTitularJuridico && titular?.ingresos?.idcategoriaLaboral  === appConfigKey.keyId3raCategoríaPerRUC))}
                  //data-idIngresoPersona={item.idIngresoPersona}
                  />
                </FormControl>
              </div>
              <div className="col-lg-5 cardObjets">
                <FormControl>
                  <FormLabel>Comentario</FormLabel>
                  <Input
                    name="comentario"
                    placeholder=""
                    value={item.comentario}
                    onChange={(event) => handleChangeComentario(index, event.target.value)}
                    disabled={esModoLecturaOtrosIngresos || (esTitularJuridico || (!esTitularJuridico && titular?.ingresos?.idcategoriaLaboral  === appConfigKey.keyId3raCategoríaPerRUC))}
                  />
                </FormControl>
              </div>
            </div>

            <div className="row g-2 mb-2 align-items-center">
              {item.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && <div className="col-lg-3 cardObjets">
                <FormLabel>Anualizado solo 5ta</FormLabel>
                <Switch
                  color={item.ingresoAnualizado ? "success" : "neutral"}
                  checked={item.ingresoAnualizado}
                  disabled={esModoLecturaOtrosIngresos}
                  onChange={(event) => handleChangeAnualizado(index, event.target.checked)}
                  slotProps={{
                    track: {
                      children: (
                        <React.Fragment>
                          <Typography
                            component="span"
                            level="inherit"
                            sx={{ ml: "10px" }}
                          >
                            Si
                          </Typography>
                          <Typography
                            component="span"
                            level="inherit"
                            sx={{ mr: "8px" }}
                          >
                            No
                          </Typography>
                        </React.Fragment>
                      ),
                    },
                  }}
                  sx={{
                    "--Switch-thumbSize": "27px",
                    "--Switch-trackWidth": "64px",
                    "--Switch-trackHeight": "31px",
                  }}
                />
              </div>}
              {/*<div className="col-lg-3 cardObjets">
                <Button
                  size="md"
                  color="danger"
                  variant="outlined"
                  sx={{
                    borderRadius: 24,
                    borderColor: "red",
                    fontWeight: 650,
                    width: 1 / 2,
                  }}
                  disabled={esModoLecturaOtrosIngresos}
                  onClick={handleReevaluar}
                >
                  Reevaluar
                </Button>
              </div>*/}
            </div>

            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Categoría laboral</FormLabel>
                  <FormLabel>{item.categoriaLaboral}</FormLabel>
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>RUC</FormLabel>
                  <FormLabel>{item.ruc}</FormLabel>
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Razón Social</FormLabel>
                  <FormLabel>{item.razonSocial}</FormLabel>
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>{item.categoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres ? 'Fecha inicio actividad' : 'Fecha ingreso laboral'}</FormLabel>
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabled={esModoLecturaOtrosIngresos}
                      value={dayjs(GeneralHelper.ParseDateString('DD/MM/YYYY', item.fechaIngresoLaboral))}
                      onChange={(date: any | null) => {
                        const newOtrosIngresos = otrosIngresos.map((item: any, indexItem: number) => {
                          if (index === indexItem) {
                            item.fechaIngresoLaboral = !date ? '' : date.format("DD/MM/YYYY");;
                          }
                          return item;
                        });
                        setOtrosIngresos(newOtrosIngresos);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </div>

            {item.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco &&
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Ingreso anualizado</FormLabel>
                    <FormLabel>{item.totalAnualizado}</FormLabel>
                  </FormControl>
                </div>
              </div>
            }


            {index !== otrosIngresos.length - 1 &&
              <div className="borderOtherIncomeBottom">
              </div>
            }

          </>
        )

      })
      }
    </>
  )
}

export default CardOtrosIngresos;