import { appDefaultValues } from "../../../../../config/Config";

export interface IDatosCliente {
  estadoVehiculoId: number;
  tipoDocumentoId: number;
  numeroDocumento: string;
  estadoCivilId: number;
  categoriaLaboralId: number;
  categoriaLaboral: string;
  telefono: string;
  correo: string;
  ingresosNetos: string;
  esAnualizado: boolean;
  ingresosAnualizados: string;
}

export const getDatosClienteDefault = (): IDatosCliente => ({
  estadoVehiculoId: appDefaultValues.NumberDefault,
  tipoDocumentoId: appDefaultValues.NumberDefault,
  numeroDocumento: appDefaultValues.StringEmpty,
  estadoCivilId: appDefaultValues.NumberDefault,
  categoriaLaboralId: appDefaultValues.NumberDefault,
  categoriaLaboral: appDefaultValues.StringEmpty,
  telefono: appDefaultValues.StringEmpty,
  correo: appDefaultValues.StringEmpty,
  ingresosNetos: appDefaultValues.StringEmpty,
  esAnualizado: appDefaultValues.BooleanDefault,
  ingresosAnualizados: appDefaultValues.StringEmpty,
});

export interface IDatosConyuge {
  tipoDocumentoId: number;
  numeroDocumento: string;
  categoriaLaboralId: number;
  categoriaLaboral: string;
  ingresosNetos: string;
  esAnualizado: boolean;
  ingresosAnualizados: string;
}

export const getDatosConyugeDefault = (): IDatosConyuge => ({
  tipoDocumentoId: appDefaultValues.NumberDefault,
  numeroDocumento: appDefaultValues.StringEmpty,
  categoriaLaboralId: appDefaultValues.NumberDefault,
  categoriaLaboral: appDefaultValues.StringEmpty,
  ingresosNetos: appDefaultValues.StringEmpty,
  esAnualizado: appDefaultValues.BooleanDefault,
  ingresosAnualizados: appDefaultValues.StringEmpty,
});

export interface IDatosCondicionesFinanciamiento {
  concesionarioId: number;
  sucursalId: number;
  vendedorId: number;
  marcaId: number;
  modeloId: number;
  versionId: number;
  monedaId: number;
  tipoCambio: string;
  montoVehiculo: string;
  porcentajeInicial: string;
  montoInicial: string;
  montoFinanciar: string;
  productoId: number;
  cuotasId: number;
  proveedorSeguroVehicularId: number;
  esGastoGps: boolean;
  campaniaId: number;
  campaniaCIPrecioId: number;
}

export const getDatosCondicionesFinanciamientoDefault = (): IDatosCondicionesFinanciamiento => ({
  concesionarioId: appDefaultValues.NumberDefault,
  sucursalId: appDefaultValues.NumberDefault,
  vendedorId: appDefaultValues.NumberDefault,
  marcaId: appDefaultValues.NumberDefault,
  modeloId: appDefaultValues.NumberDefault,
  versionId: appDefaultValues.NumberDefault,
  monedaId: appDefaultValues.NumberDefault,
  tipoCambio: appDefaultValues.StringEmpty,
  montoVehiculo: appDefaultValues.StringEmpty,
  porcentajeInicial: appDefaultValues.StringEmpty,
  montoInicial: appDefaultValues.StringEmpty,
  montoFinanciar: appDefaultValues.StringEmpty,
  productoId: appDefaultValues.NumberDefault,
  cuotasId: appDefaultValues.NumberDefault,
  proveedorSeguroVehicularId: appDefaultValues.NumberDefault,
  esGastoGps: appDefaultValues.BooleanDefault,
  campaniaId: appDefaultValues.NumberDefault,
  campaniaCIPrecioId: appDefaultValues.NumberDefault,
});

export interface IPreQuotationStepOne {
  datosCliente: IDatosCliente;
  datosConyuge: IDatosConyuge | null;
  datosCondicionesFinanciamiento: IDatosCondicionesFinanciamiento;
}

export const getPreQuotationStepOneDefault = (): IPreQuotationStepOne => ({
  datosCliente: getDatosClienteDefault(),
  datosConyuge: appDefaultValues.NullDefault,
  datosCondicionesFinanciamiento: getDatosCondicionesFinanciamientoDefault(),
});
